// Types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationType = 'default' | 'success' | 'error' | 'warning';

export interface Notification {
  id: string;
  text: string;
  visible: boolean;
  type: NotificationType;
}

interface NotificationState {
  currentNotification: Notification | null;
}

// Initial state (similar to RTK slice)
const initialState: NotificationState = {
  currentNotification: null,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (
      state,
      action: PayloadAction<{
        text: string;
        type: NotificationType;
        id: string;
      }>
    ) => {
      state.currentNotification = {
        ...action.payload,
        visible: true,
      };
    },
    hideNotification: (state) => {
      if (state.currentNotification) {
        state.currentNotification.visible = false;
      }
    },
    clearNotification: (state) => {
      state.currentNotification = null;
    },
  },
});

export const { showNotification, hideNotification, clearNotification } = notificationSlice.actions;
