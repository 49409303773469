export interface IClientTag {
  id: string;
  providerId?: string;
  color: string;
  isArchived?: boolean;
  name: string;
}
export interface IRawTag {
  id?: string;
  provider_id?: string;
  color: string;
  is_archived?: boolean;
  name: string;
}

export const tagRawToClient = (raw: IRawTag): IClientTag => {
  return {
    id: raw.id,
    providerId: raw.provider_id,
    color: raw.color,
    isArchived: raw.is_archived,
    name: raw.name,
  };
};
export const tagClientToRaw = (client: IClientTag): IRawTag => {
  return {
    provider_id: client.providerId,
    color: client.color,
    name: client.name,
  };
};
