import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useKeyPress } from '../../../../shared/helpers/helpers';
import { ActiveEntityPair, DocumentEntity, DocumentEntityGroup } from '../../../../shared/models/document';
import { UrlParams } from '../../../../shared/models/generic';
import { editDocumentEntity, labelerSlice } from '../../../../shared/store/labelerSlice';
import { useDispatch, useSelector } from '../../../../shared/store/store';
import s from '../../../../shared/styles/component/document/document-labeler-sidebar.module.scss';
import DocumentLabelerSidebarCategory from './DocumentLabelerSidebarCategory';
import DocumentLabelerSidebarDelete from './DocumentLabelerSidebarDelete';
import DocumentLabelerSidebarRow from './DocumentLabelerSidebarRow';

interface Props {
  handleFieldDelete: (entityPair: ActiveEntityPair, event?: any) => void;
}

const DocumentLabelerSidebar: React.FC<Props> = ({ handleFieldDelete }) => {
  const { inboxId, docId }: UrlParams = useParams();

  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const activeEntityPair = useSelector((state) => state.labeler.activeEntityPair);
  const allowedEntityTypes = useSelector((state) => state.document.allowedEntityTypes);
  const docTypeCategories = useSelector((state) => state.document.docTypeCategories);

  const [entityGroups, setEntityGroups] = useState<DocumentEntityGroup[]>();
  const [categoriesChecked, setCategoriesChecked] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setEntityGroups(null);
      setCategoriesChecked(false);
    };
  }, [docId]);

  const dispatch = useDispatch();
  const handleTab = (e: KeyboardEvent) => {
    e.preventDefault();
    let searchList = [...documentEntities];
    if (entityGroups && entityGroups.length > 0) {
      searchList = entityGroups.reduce((groups, item) => {
        return [...groups, ...item.entities];
      }, []);
    }

    const entityIndex = searchList.findIndex((e) => e.uuid === activeEntityPair?.entityId);
    if (entityIndex !== -1) {
      let nextItem;
      const currentEntity = searchList[entityIndex];
      const isMultiLocation = currentEntity.valueLocations.length > 1;
      const complexItem = currentEntity.value['complex'];
      const mappedChildItems = complexItem
        ? Object.entries(complexItem).map(([key, value]) => ({ ...(value as any), id: key }))
        : [];
      const childIndex = activeEntityPair.childId
        ? mappedChildItems.findIndex((item) => item.id === activeEntityPair.childId)
        : -1;

      if (e.shiftKey) {
        // Handle navigation backwards
        if (isMultiLocation && activeEntityPair.locationIndex > 0) {
          nextItem = { entityId: currentEntity.uuid, locationIndex: activeEntityPair.locationIndex - 1 };
        } else if (complexItem && childIndex > 0) {
          nextItem = {
            entityId: currentEntity.uuid,
            locationIndex: 0,
            childId: mappedChildItems[childIndex - 1].id,
          };
        } else {
          // Wrap around to the last entity if necessary
          const lastIndex = entityIndex > 0 ? entityIndex - 1 : searchList.length - 1;
          nextItem = getLastComplexOrMultiLocation(searchList, lastIndex);
        }
      } else {
        // Handle navigation forwards
        if (isMultiLocation && activeEntityPair.locationIndex < currentEntity.valueLocations.length - 1) {
          nextItem = { entityId: currentEntity.uuid, locationIndex: activeEntityPair.locationIndex + 1 };
        } else if (complexItem && childIndex < mappedChildItems.length - 1) {
          nextItem = {
            entityId: currentEntity.uuid,
            locationIndex: 0,
            childId: mappedChildItems[childIndex + 1].id,
          };
        } else {
          // Move to the next entity or wrap around to the start of the list
          const nextIndex = entityIndex < searchList.length - 1 ? entityIndex + 1 : 0;
          nextItem = getFirstComplexOrMultiLocation(searchList, nextIndex);
        }
      }
      dispatch(labelerSlice.actions.setActiveEntityPair(nextItem));
    } else {
      e.preventDefault();
      let nextItem: ActiveEntityPair =
        searchList.length > 0 ? { entityId: searchList[0].uuid, locationIndex: 0 } : null;
      if (searchList[0].value['complex']) {
        nextItem = { entityId: searchList[0].uuid, locationIndex: 0, childId: '0' };
      }
      dispatch(labelerSlice.actions.setActiveEntityPair(nextItem));
    }
  };
  function getFirstComplexOrMultiLocation(searchList: any[], index: number): ActiveEntityPair {
    const entity = searchList[index];
    if (entity.value['complex'] && Object.keys(entity.value['complex']).length > 0) {
      const firstKey = Object.keys(entity.value['complex'])[0];
      return { entityId: entity.uuid, locationIndex: 0, childId: firstKey };
    } else if (entity.valueLocations.length > 1) {
      return { entityId: entity.uuid, locationIndex: 0 };
    } else {
      return { entityId: entity.uuid, locationIndex: 0 };
    }
  }

  function getLastComplexOrMultiLocation(searchList: any[], index: number): ActiveEntityPair {
    const entity = searchList[index];
    if (entity.value['complex'] && Object.keys(entity.value['complex']).length > 0) {
      const lastKey = Object.keys(entity.value['complex']).slice(-1)[0];
      return { entityId: entity.uuid, locationIndex: 0, childId: lastKey };
    } else if (entity.valueLocations.length > 1) {
      return { entityId: entity.uuid, locationIndex: entity.valueLocations.length - 1 };
    } else {
      return { entityId: entity.uuid, locationIndex: 0 };
    }
  }

  useEffect(() => {
    if (!documentEntities || !allowedEntityTypes || !docTypeCategories) return;

    if (docTypeCategories.length === 0) {
      setEntityGroups([]);
      setCategoriesChecked(true);
      return;
    }

    const groups = documentEntities.reduce((acc, item) => {
      const entityType = allowedEntityTypes.find((e) => e?.id === item.type);
      if (!entityType) return acc;

      const catId = item.categoryId || 'Other';
      acc[catId] = acc[catId] || [];
      acc[catId].push(item);
      return acc;
    }, {});

    let list: DocumentEntityGroup[] = Object.entries(groups).map(([k, v]) => ({
      categoryId: k,
      entities: v as any,
    }));

    if (list.length === 1 && list[0].categoryId === 'Other') {
      setEntityGroups([]);
    } else {
      const catIdMap = docTypeCategories.reduce((map, cat, idx) => ({ ...map, [cat.id]: idx }), {});

      console.log(catIdMap, 'catIdMap');

      list = list.map((category) => {
        const cat = docTypeCategories.find((e) => e.id === category.categoryId);
        const types = cat ? cat.entityTypes.map((et) => et.id) : [];
        return {
          ...category,
          entities: category.entities.sort((a, b) => types.indexOf(a.type) - types.indexOf(b.type)),
        };
      });
      console.log(list);
      list.sort((a, b) => {
        // Make "Other" always appear first
        if (a.categoryId === 'Other') return -1;
        if (b.categoryId === 'Other') return 1;

        // Sort the remaining categories by `catIdMap` order
        return catIdMap[a.categoryId] - catIdMap[b.categoryId] || a.categoryId.localeCompare(b.categoryId);
      });
      console.log(list);
      setEntityGroups(list);
    }

    setCategoriesChecked(true);
  }, [allowedEntityTypes, documentEntities, docTypeCategories]);

  const handleSelect = (entityPair: ActiveEntityPair) => {
    dispatch(labelerSlice.actions.setActiveEntityPair(entityPair));
  };

  const handleEdit = (entity: DocumentEntity, changes: any) => {
    dispatch(editDocumentEntity(inboxId, entity.uuid, { value: changes }));
  };

  const handleDelete = (entityPair: ActiveEntityPair, e) => {
    handleFieldDelete(entityPair, e);
  };
  const handleApprove = (entity: DocumentEntity, parentId?: string) => {
    if (entity.isSuggestion) {
      dispatch(editDocumentEntity(inboxId, entity.uuid, { isChecked: true, isSuggestion: false }, parentId));
    } else {
      dispatch(editDocumentEntity(inboxId, entity.uuid, { isChecked: true }, parentId));
    }
  };

  useKeyPress('Tab', handleTab);
  useKeyPress(
    'Delete',
    (e) => {
      if (activeEntityPair) handleDelete(activeEntityPair, e);
    },
    activeEntityPair !== null
  );
  useKeyPress(
    'Enter',
    (e) => {
      if (e.target.tagName !== 'INPUT') {
        const activeEntity = documentEntities.find((e) => e.uuid === activeEntityPair?.entityId);
        if (activeEntity && activeEntity.isSuggestion) handleApprove(activeEntity);
      }
    },
    activeEntityPair !== null
  );

  if (activeDocument?.docTypeId === '@PB_DELETE') {
    return <DocumentLabelerSidebarDelete />;
  }

  return (
    <>
      <div className={s.fields} data-tour="fields">
        {activeDocument && documentEntities.length === 0 && (
          <div data-testid={'no-entities'} className={s.placeholder_text}>
            {t('document:noFields')}
          </div>
        )}

        {categoriesChecked && entityGroups && (
          <>
            {entityGroups.length > 0
              ? [...entityGroups]?.map((eg) => {
                  return (
                    <DocumentLabelerSidebarCategory
                      key={eg.categoryId}
                      entityGroup={eg}
                      handleFieldDelete={handleFieldDelete}
                      handleFieldEdit={handleEdit}
                      handleFieldSelect={handleSelect}
                      handleFieldApprove={handleApprove}
                    />
                  );
                })
              : documentEntities.map((entity) => {
                  return (
                    <DocumentLabelerSidebarRow
                      isSuggestion={entity.isSuggestion}
                      key={entity.uuid}
                      handleFieldApprove={handleApprove}
                      handleFieldDelete={handleDelete}
                      handleFieldEdit={handleEdit}
                      entity={entity}
                      documentDetails={activeDocument}
                      handleFieldSelect={handleSelect}
                    />
                  );
                })}
          </>
        )}
      </div>
    </>
  );
};

export default DocumentLabelerSidebar;
