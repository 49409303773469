import camelcaseKeys from 'camelcase-keys';
import clsx from 'clsx';
import { doc, getDoc } from 'firebase/firestore';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MS365InflowUserMappingItem } from '../../../shared/helpers/converters/connector.ts';
import { docTypeRawToClient } from '../../../shared/helpers/converters/doctype.ts';
import { compareObjects, getUserToken, uuid4hex } from '../../../shared/helpers/helpers.ts';
import { useModal } from '../../../shared/hooks/useModal.tsx';
import { TagType } from '../../../shared/models/document.ts';
import { api, db } from '../../../shared/store/setup/firebase-setup.ts';
import { useDispatch, useSelector } from '../../../shared/store/store.ts';
import s from '../../../shared/styles/component/admin/admin-ms365.module.scss';
import si from '../../../shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as ChevronDownIcon } from '../../../shared/assets/svg/chevron-down.svg';
import { ReactComponent as FolderIcon } from '../../../shared/assets/svg/folder.svg';
import { ReactComponent as OutboundIcon } from '../../../shared/assets/svg/outbound.svg';
import { ReactComponent as EditIcon } from '../../../shared/assets/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../shared/assets/svg/trash-icon-alt.svg';
import { ReactComponent as DividerVertical } from '../../../shared/assets/svg/divider-vertical.svg';
import { ReactComponent as ArrowBackIcon } from '../../../shared/assets/svg/arrow-back-icon.svg';
import StyledSelect, { DropdownOption } from '../../shared/dropdown/StyledSelect.tsx';
import { Ring } from '@uiball/loaders';
import AdminMS365UserPicker from './AdminMS365UserPicker.tsx';

interface Props {
  microsoftUsers: any[];
  user: MS365InflowUserMappingItem;
  setUser: (updatedUser: MS365InflowUserMappingItem) => void;
}
export interface MS365MailFolder {
  childFolderCount: number;
  displayName: string;
  id: string;
  isHidden: boolean;
  parentFolderId: string;
  children?: MS365MailFolder[];
  isUsed?: boolean;
}

const AdminMS365UserRow: React.FC<Props> = ({ user, microsoftUsers, setUser }) => {
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [mailFolders, setMailFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { connectorId: activeId } = useParams();

  const currentMSUser = useMemo(() => {
    if (microsoftUsers) {
      return microsoftUsers.find((msUser) => msUser.id === user.id);
    }
  }, [microsoftUsers, user]);

  useEffect(() => {
    async function fetchChildMailFolders(parentFolderId: string): Promise<MS365MailFolder[]> {
      const b = await getUserToken();
      const test = await api.get(
        `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/connectors/${activeId}/ms365/users/${
          user.id
        }/mailFolders/${parentFolderId}/childFolders`,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + b,
          },
        }
      );
      return test.data['value'] as MS365MailFolder[];
    }

    async function buildFolderHierarchy(
      folder: MS365MailFolder,
      folderMap: Record<string, MS365MailFolder>
    ): Promise<void> {
      // Only fetch child folders if childFolderCount indicates there are children
      if (folder.childFolderCount > 0) {
        const childFolders = await fetchChildMailFolders(folder.id);
        for (const childFolder of childFolders) {
          // Add the child folder to the map
          folderMap[childFolder.id] = childFolder;
          // Recursively build the hierarchy for each child
          await buildFolderHierarchy(childFolder, folderMap);
        }
        if (childFolders.length > 0) folder.children = childFolders;

        // After fetching children, attach them to the current folder
      }
    }

    async function constructHierarchyFromRootFolders(
      rootFolders: MS365MailFolder[]
    ): Promise<MS365MailFolder[]> {
      const folderMap: Record<string, MS365MailFolder> = {};

      // Start with the known root folders and build hierarchy
      for (const rootFolder of rootFolders) {
        const mapped = rootFolder;
        delete mapped.parentFolderId;
        folderMap[rootFolder.id] = mapped;
        await buildFolderHierarchy(mapped, folderMap);
      }

      setFolderMap(folderMap);
      return rootFolders;
    }
    const fetchFolders = async () => {
      setIsLoading(true);
      const b = await getUserToken();
      const test = await api.get(
        `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/connectors/${activeId}/ms365/users/${
          user.id
        }/mailFolders`,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + b,
          },
        }
      );
      const list = test.data['value'] as MS365MailFolder[];
      const items = await constructHierarchyFromRootFolders(list);
      setIsLoading(false);
      setMailFolders(items);
    };
    if (activeId && user.id) {
      fetchFolders();
    }
  }, [activeId, user.id]);

  const [folderMap, setFolderMap] = useState({});
  const getFolderChainById = useCallback(
    (folderId: string, accumulator: string[] = []): string[] => {
      if (!folderId) {
        return [];
      }
      if (Object.entries(folderMap).length === 0) {
        throw new Error('No folders are available in the folderMap.');
      }
      const folder = folderMap[folderId];
      if (!folder) {
        throw new Error(`Folder with ID ${folderId} not found.`);
      }
      accumulator.unshift(folderId); // Prepend the current folder's ID

      if (folder.parentFolderId) {
        return getFolderChainById(folder.parentFolderId, accumulator);
      }
      return accumulator; // Return the array of IDs when the root is reached
    },
    [folderMap]
  );
  const markedMailFolders = useMemo(() => {
    if (mailFolders.length > 0) {
      const chain = user.folderMapping.map((mapping) => getFolderChainById(mapping.pathId));

      // Function to recursively mark folders as 'isUsed'
      const markFoldersAsUsed = (folders) => {
        return folders.map((folder) => {
          // Check if the folder itself is in the chain
          const isInChain = chain.some((mapped) => mapped.includes(folder.id));

          // Recursively update children and determine if all children are in the chain
          let allChildrenUsed = true;
          let updatedChildren = [];
          if (folder.childFolderCount > 0 && folder.children) {
            updatedChildren = markFoldersAsUsed(folder.children);
            allChildrenUsed = updatedChildren.every((child) => child.isUsed);
          }

          // Mark folder as 'isUsed' only if it's in the chain and all its children (if any) are marked as 'isUsed'
          return {
            ...folder,
            isUsed: isInChain && allChildrenUsed,
            children: updatedChildren.length > 0 ? updatedChildren : undefined,
          };
        });
      };

      // Apply the marking function to the root mail folders
      return markFoldersAsUsed(mailFolders);
    }
    return [];
  }, [getFolderChainById, mailFolders, user.folderMapping]);

  const getNameForPath = (folderId: string, accumulator: string[] = []) => {
    if (!folderId) {
      return 'Select a Mailbox / Folder';
    }
    if (Object.entries(folderMap).length === 0) {
      return '??';
    }
    const folder = folderMap[folderId];
    if (!folder) {
      throw new Error(`Folder with ID ${folderId} not found.`);
    }
    accumulator.unshift(folder.displayName);

    if (folder.parentFolderId) {
      return getNameForPath(folder.parentFolderId, accumulator);
    }
    return accumulator.join('/');
  };

  return (
    <div className={s.wrapper}>
      <div onClick={() => setIsOpen(!isOpen)} className={s.header}>
        <div className={s.headerIcon}>
          <FolderIcon />
        </div>
        <div className={s.headerText}>
          {currentMSUser ? (
            <>
              <h3>
                {t('admin:connectors.ms365.mailbox')} : {currentMSUser?.displayName}
              </h3>
              <span>{currentMSUser?.userPrincipalName}</span>
            </>
          ) : (
            <Ring color={'#0085FF'} size={20} />
          )}
        </div>
        <div className={s.headerArrow}>
          <ChevronDownIcon style={isOpen ? { transform: 'rotate(180deg)' } : {}} />
        </div>
      </div>
      <div className={clsx(s.content, { [s.content__visible]: isOpen })}>
        <div className={s.section}>
          <div className={s.sectionHeader}>
            <div className={s.headerIcon}>
              <OutboundIcon />
            </div>
            <div className={s.headerText}>
              <h3>{t('admin:connectors.ms365.outbound')}</h3>
              <span>{t('admin:connectors.ms365.outboundDescription')}</span>
            </div>
          </div>
          <div className={s.outbound}>
            <div className={clsx(s.row, s.outboundRow)}>
              <span className={s.name}>{t('admin:connectors.ms365.bounce')}</span>
              <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} />
              <button
                type="button"
                disabled={isLoading}
                onClick={() =>
                  showDialog(
                    <AdminMS365UserPicker
                      currentPath={getNameForPath(user.onBounceFolder)}
                      mailFolders={mailFolders}
                      onChange={(newPathId: string) => {
                        setUser({
                          ...user,
                          onBounceFolder: newPathId,
                        });
                      }}
                    />
                  )
                }
                className={s.selector}
              >
                {user.onBounceFolder == undefined && (
                  <input
                    name={'Bounce Folder'}
                    type="text"
                    required={true}
                    aria-hidden
                    onInvalid={(e) => {
                      setIsOpen(true);
                      const input = e.target as HTMLInputElement;
                      input.setCustomValidity('Please select a mailbox / folder');
                    }}
                  />
                )}

                <span>
                  {isLoading ? <Ring size={16} color={'#0085FF'} /> : getNameForPath(user.onBounceFolder)}
                </span>
                <EditIcon />
              </button>
            </div>
          </div>
          <div className={clsx(s.row, s.outboundRow)}>
            <span className={s.name}>{t('admin:connectors.ms365.delete')}</span>
            <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} />
            <button
              type="button"
              disabled={isLoading}
              onClick={() =>
                showDialog(
                  <AdminMS365UserPicker
                    currentPath={getNameForPath(user.onDeleteFolder)}
                    mailFolders={mailFolders}
                    onChange={(newPathId: string) => {
                      setUser({
                        ...user,
                        onDeleteFolder: newPathId,
                      });
                    }}
                  />
                )
              }
              className={s.selector}
            >
              {user.onDeleteFolder == undefined && (
                <input
                  name={'Delete Folder'}
                  type="text"
                  required={true}
                  aria-hidden
                  onInvalid={(e) => {
                    setIsOpen(true);
                    const input = e.target as HTMLInputElement;
                    input.setCustomValidity('Please select a mailbox / folder');
                  }}
                />
              )}
              <span>
                {isLoading ? <Ring size={16} color={'#0085FF'} /> : getNameForPath(user.onDeleteFolder)}
              </span>
              <EditIcon />
            </button>
          </div>
          <div className={clsx(s.row, s.outboundRow)}>
            <span className={s.name}>{t('admin:connectors.ms365.approve')}</span>
            <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} />
            <button
              type="button"
              disabled={isLoading}
              onClick={() =>
                showDialog(
                  <AdminMS365UserPicker
                    currentPath={getNameForPath(user.onApproveFolder)}
                    mailFolders={mailFolders}
                    onChange={(newPathId: string) => {
                      setUser({
                        ...user,
                        onApproveFolder: newPathId,
                      });
                    }}
                  />
                )
              }
              className={s.selector}
            >
              {user.onApproveFolder == undefined && (
                <input
                  name={'Approve Folder'}
                  type="text"
                  required={true}
                  aria-hidden
                  onInvalid={(e) => {
                    setIsOpen(true);
                    const input = e.target as HTMLInputElement;
                    input.setCustomValidity('Please select a mailbox / folder');
                  }}
                />
              )}

              <span>
                {isLoading ? <Ring size={16} color={'#0085FF'} /> : getNameForPath(user.onApproveFolder)}
              </span>
              <EditIcon />
            </button>
          </div>
        </div>
        <div className={s.section}>
          <div className={s.sectionHeader}>
            <div className={s.headerIcon}>
              <OutboundIcon style={{ transform: 'rotate(90deg)' }} />
            </div>
            <div className={s.headerText}>
              <h3>{t('admin:connectors.ms365.inbound')}</h3>
              <span>{t('admin:connectors.ms365.inboundDescription')}</span>
            </div>
            {!isLoading && (
              <button
                type={'button'}
                onClick={() => {
                  showDialog(
                    <AdminMS365UserPicker
                      currentPath={null}
                      mailFolders={markedMailFolders}
                      onChange={(newPathId: string) => {
                        setUser({
                          ...user,
                          folderMapping: [
                            ...user.folderMapping,
                            {
                              id: uuid4hex(),
                              pathId: newPathId,
                              docTypeId: undefined,
                              inboxId: undefined,
                              tagTypeId: undefined,
                              creationDate: new Date(),
                            },
                          ],
                        });
                      }}
                    />
                  );
                }}
              >
                + {t('admin:connectors.ms365.addRule')}
              </button>
            )}
          </div>
          <div className={s.inbound}>
            {user.folderMapping
              .sort((a, b) => a.creationDate.getTime() - b.creationDate.getTime())
              .map((mapping, i) => (
                <InboundItem
                  handleDelete={(e) => {
                    e.stopPropagation();
                    const clone = cloneDeep(user.folderMapping);
                    clone.splice(i, 1);
                    setUser({
                      ...user,
                      folderMapping: clone,
                    });
                  }}
                  isLoading={isLoading}
                  mailFolders={markedMailFolders}
                  getNameForPath={getNameForPath}
                  setRule={(newRule) => {
                    const compared = compareObjects(newRule, mapping);
                    if (Object.entries(compared).length === 0) return;
                    const cloneMapping = cloneDeep(user.folderMapping);
                    cloneMapping[i] = newRule;
                    setUser({
                      ...user,
                      folderMapping: cloneMapping,
                    });
                  }}
                  rule={mapping}
                  key={mapping.id}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMS365UserRow;
interface InboundItemProps {
  handleDelete?: (e: React.MouseEvent) => void;
  isLoading: boolean;
  mailFolders: MS365MailFolder[];
  rule: any;
  setRule: any;
  getNameForPath: (folderId: string, accumulator: string[]) => string;
}

const InboundItem: React.FC<InboundItemProps> = ({
  handleDelete,
  mailFolders,
  isLoading,
  rule,
  setRule,
  getNameForPath,
}) => {
  const [selectedInboxId, setSelectedInboxId] = useState(rule.inboxId ?? undefined);
  const [selectedDocTypeId, setSelectedDocTypeId] = useState(rule.docTypeId ?? undefined);
  const [selectedTagTypeId, setSelectedTagTypeId] = useState(rule.tagTypeId ?? undefined);
  const inboxes = useSelector((state) => state.admin.inboxes);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const mappedInboxes = inboxes?.map((type) => {
    return {
      label: type.settings.name,
      value: type.id,
    };
  });
  useEffect(() => {
    if (selectedInboxId) {
      fetchDocTypesForInbox(selectedInboxId);
      fetchTagTypesForInbox(selectedInboxId);
    }
  }, [dispatch, selectedInboxId]);

  const [tagTypes, setTagTypes] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const fetchDocTypesForInbox = (inboxId: string) => {
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/doc_types`);
    getDoc(ref).then((res) => {
      const mappedData = Object.entries(res.data())
        .map(([key, value]) => {
          return docTypeRawToClient({
            id: key,
            ...value,
          });
        })
        .sort((a, b) => {
          if (b.id === '@PB_NOTYPE') {
            return -1;
          } else {
            return a.name.localeCompare(b.name);
          }
        })
        .filter((e) => !e.isPrivate && !e.isArchived);
      setDocTypes(mappedData);
    });
  };
  const fetchTagTypesForInbox = (inboxId: string) => {
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/tag_types`);
    getDoc(ref).then((res) => {
      const mappedData: TagType[] = Object.entries(res.data())
        .map(([key, value]) => {
          return {
            id: key,
            ...camelcaseKeys(value),
          } as TagType;
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((st) => !st.isArchived);
      setTagTypes(mappedData);
    });
  };

  const mappedDocTypes = useMemo(() => {
    if (selectedInboxId && docTypes) {
      const list = [{ label: '/', value: 'none' }];
      list.push(
        ...docTypes.map((type) => {
          return {
            label: type.name,
            value: type.id,
          };
        })
      );
      return list;
    }
    return null;
  }, [docTypes, selectedInboxId]);

  const mappedTagTypes = useMemo(() => {
    if (selectedInboxId && tagTypes) {
      const list = [{ label: '/', value: 'none' }];
      list.push(
        ...tagTypes.map((type) => {
          return {
            label: type.name,
            value: type.id,
            tag: {
              isMinimal: true,
            },
            color: type.color,
          } as DropdownOption;
        })
      );
      return list;
    }
    return null;
  }, [tagTypes, selectedInboxId]);

  useEffect(() => {
    const newRule = { ...rule };
    if (selectedInboxId) {
      newRule.inboxId = selectedInboxId;
    }
    if (selectedDocTypeId) {
      newRule.docTypeId = selectedDocTypeId;
    }
    if (selectedTagTypeId) {
      newRule.tagTypeId = selectedTagTypeId;
    }
    setRule(newRule);
  });

  return (
    <div className={clsx(s.row, s.inboundRow)}>
      <button
        type="button"
        disabled={isLoading}
        onClick={(e) => {
          e.stopPropagation();
          showDialog(
            <AdminMS365UserPicker
              currentPath={getNameForPath(rule.pathId, [])}
              mailFolders={mailFolders}
              onChange={(newPathId: string) => {
                setRule({
                  ...rule,
                  pathId: newPathId,
                });
              }}
            />
          );
        }}
        className={s.selector}
      >
        <input
          name={'Import Folder'}
          type="text"
          required={rule.pathId == undefined}
          aria-hidden
          onInvalid={(e) => {
            const input = e.target as HTMLInputElement;
            input.setCustomValidity('Please select a mailbox / folder');
          }}
        />
        <span>
          {isLoading ? (
            <>
              <Ring size={16} color={'#0085FF'} />
            </>
          ) : (
            getNameForPath(rule.pathId, [])
          )}
        </span>
        <div className={s.icons}>
          <EditIcon />
          <DividerVertical />
          <DeleteIcon onClick={handleDelete} className={s.delete} />
        </div>
      </button>

      <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} />
      <div className={s.mapping}>
        <div className={s.mappingItem}>
          <span>Inbox * </span>
          <div className={si.input_wrapper}>
            <StyledSelect
              value={mappedInboxes?.find((inbox) => inbox['value'] === selectedInboxId)}
              onChange={(inbox) => {
                setSelectedInboxId(inbox['value']);
              }}
              options={mappedInboxes}
            />
          </div>
        </div>
        <div className={s.mappingItem}>
          <span>Document type</span>
          <div className={si.input_wrapper}>
            {mappedDocTypes && (
              <StyledSelect
                value={
                  mappedDocTypes?.find((docType) => docType['value'] === selectedDocTypeId) ??
                  mappedDocTypes[0]
                }
                onChange={(docType) => {
                  if (docType['value'] === 'none') {
                    setSelectedDocTypeId(undefined);
                  } else {
                    setSelectedDocTypeId(docType['value']);
                  }
                }}
                options={mappedDocTypes}
              />
            )}
          </div>
        </div>
        <div className={s.mappingItem}>
          <span>Tag</span>
          <div className={si.input_wrapper}>
            {mappedTagTypes && (
              <StyledSelect
                value={
                  mappedTagTypes?.find((tagType) => tagType['value'] === selectedTagTypeId) ??
                  mappedTagTypes[0]
                }
                onChange={(tagType) => {
                  if (tagType['value'] === 'none') {
                    setSelectedTagTypeId(undefined);
                  } else {
                    setSelectedTagTypeId(tagType['value']);
                  }
                }}
                options={mappedTagTypes}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
