// Hook for managing notifications
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearNotification,
  hideNotification,
  NotificationType,
  showNotification,
} from '../store/notificationSlice';

export const useNotification = () => {
  const dispatch = useDispatch();

  const showNotificationWithTimeout = useCallback(
    (text: string, type: NotificationType = 'default', duration = 3000) => {
      const id = Math.random().toString(36).slice(2, 9);
      dispatch(showNotification({ text, type, id }));

      setTimeout(() => {
        dispatch(hideNotification());
        setTimeout(() => {
          dispatch(clearNotification());
        }, 300);
      }, duration);
    },
    [dispatch]
  );

  return { showNotification: showNotificationWithTimeout };
};
