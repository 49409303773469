import { useMemo } from 'react';

export const useQuery = () => {
  const { search } = document.location;

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    return queryParams as Record<string, any>;
  }, [search]);
};
