import { EntityTypeType } from '../../models/inbox.ts';
import { convertToId } from '../helpers.ts';

export interface IRawFieldType {
  id?: string;
  provider_id?: string;
  name: string;
  is_archived?: boolean;
  is_sensitive?: boolean;
  type: EntityTypeType;
  formats?: string[];
  table_definition?: IRawFieldDefinition | IRawFieldDefinitionOld;
  complex_definition?: IRawFieldDefinition;
  options: { value: string; id?: string }[];
  prompt_config?: {
    description?: string;
  };
}
export interface IRawFieldDefinitionOld {
  entity_types: string[];
}
export interface IRawFieldDefinition {
  entity_types: {
    id: string;
    mandatory?: boolean;
  }[];
}
export interface IClientFieldDefinition {
  entityTypes: {
    id: string;
    mandatory?: boolean;
  }[];
}

export interface IClientFieldType {
  id?: string;
  providerId?: string;
  name: string;
  isArchived?: boolean;
  isSensitive?: boolean;
  type: EntityTypeType;
  formats?: string[];
  tableDefinition?: IClientFieldDefinition;
  complexDefinition?: IClientFieldDefinition;
  options: { value: string; id: string }[];
  promptConfig?: {
    description?: string;
  };
}
function isOldTableDefinition(
  definition: IRawFieldDefinition | IRawFieldDefinitionOld
): definition is IRawFieldDefinitionOld {
  return 'entity_types' in definition && typeof definition.entity_types[0] === 'string';
}

export const fieldTypeRawToClient = (rawDocType: IRawFieldType): IClientFieldType => {
  const obj: IClientFieldType = {
    id: rawDocType.id,
    providerId: rawDocType?.provider_id ?? '',
    name: rawDocType.name,
    isArchived: rawDocType.is_archived,
    isSensitive: rawDocType.is_sensitive,
    type: rawDocType.type,
    options: rawDocType.options
      ? rawDocType.options.map((e) => ({ value: e.value, id: e.id ?? convertToId(e.value) }))
      : [],
  };

  if (rawDocType.prompt_config) {
    obj.promptConfig = {
      description: rawDocType.prompt_config.description,
    };
  }
  if (rawDocType.formats) {
    obj.formats = rawDocType.formats;
  }

  if (rawDocType.complex_definition) {
    const rawList: IRawFieldDefinition = rawDocType.complex_definition;
    obj.complexDefinition = {
      entityTypes: rawList.entity_types,
    };
  }
  if (rawDocType.table_definition) {
    if (isOldTableDefinition(rawDocType.table_definition)) {
      const rawList: IRawFieldDefinitionOld = rawDocType.table_definition;
      obj.tableDefinition = {
        entityTypes: rawList.entity_types.map((e) => ({
          id: e,
          mandatory: false,
        })),
      };
    } else {
      const rawList: IRawFieldDefinition = rawDocType.table_definition;
      obj.tableDefinition = {
        entityTypes: rawList.entity_types,
      };
    }
  }
  return obj;
};

export const fieldTypeClientToRaw = (fieldType: Partial<IClientFieldType>): Partial<IRawFieldType> => {
  const obj: Partial<IRawFieldType> = {};
  if (fieldType.name !== undefined) obj.name = fieldType.name;
  if (fieldType.type !== undefined) obj.type = fieldType.type;
  if (fieldType.isArchived !== undefined) obj.is_archived = fieldType.isArchived;
  if (fieldType.isSensitive !== undefined) obj.is_sensitive = fieldType.isSensitive;
  if (fieldType.providerId !== undefined) obj.provider_id = fieldType.providerId;
  if (fieldType.formats !== undefined) obj.formats = fieldType.formats;
  obj.options = null;
  if (fieldType.options && fieldType.options.length > 0) obj.options = fieldType.options;
  if (fieldType.promptConfig !== undefined) {
    obj.prompt_config = {
      description: fieldType.promptConfig.description,
    };
  }
  if (fieldType.tableDefinition !== undefined) {
    obj.table_definition = {
      entity_types: fieldType.tableDefinition.entityTypes,
    };
  }
  if (fieldType.complexDefinition !== undefined) {
    obj.complex_definition = {
      entity_types: fieldType.complexDefinition.entityTypes,
    };
  }
  return obj;
};
