import React from 'react';
import s from './radio-button.module.scss';

const RadioButton: React.FC<React.HTMLProps<HTMLInputElement>> = ({
  checked,
  onClick,
  ...rest
}) => {
  return (
    <div className={s.container}>
      <input
        data-testid="radio-input"
        type="radio"
        className={s.hidden}
        checked={checked}
        onChange={() => null}
        {...rest}
      />
      <div data-testid="checkbox" onClick={onClick} className={s.radio} />
      <div className={s.dot} />
    </div>
  );
};

export default RadioButton;
