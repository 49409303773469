import React from 'react';
import s from './loading-bar.module.scss';

interface Props {
  progress: number;
  hasError?: boolean;
}

const LoadingBar: React.FC<Props> = ({ progress, hasError }) => {
  return (
    <div className={s.wrapper}>
      <div
        className={s.bar}
        style={{
          width: `${progress}%`,
          backgroundColor: hasError ? '#FF5555' : progress === 100 ? '#91C500' : '',
        }}
      />
    </div>
  );
};

export default LoadingBar;
