import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultClientInbox,
  IClientInbox,
  inboxClientToRaw,
} from '../../../../../shared/helpers/converters/inbox.ts';
import { isPaperboxEmail } from '../../../../../shared/helpers/helpers.ts';
import useChangeTracker, { ChangeSaveCallback } from '../../../../../shared/hooks/useChangeTracker.tsx';
import { useModal } from '../../../../../shared/hooks/useModal.tsx';
import {
  exportSliceToJson,
  getInboxes,
  patchInbox,
  postInbox,
} from '../../../../../shared/store/adminSlice.ts';
import s from '../../../../../shared/styles/component/admin/admin-section.module.scss';
import AdminImportModal from '../../../components/AdminImportModal.tsx';
import FormInputField from '../../../components/form/FormInputField.tsx';
import FormSection from '../../../components/form/FormSection.tsx';
import { useDispatch, useSelector } from '../../../../../shared/store/store.ts';
import { useNavigate, useParams } from 'react-router-dom';
import Checkbox from '../../../../shared/checkbox/Checkbox.tsx';
import { DropdownOption } from '../../../../shared/dropdown/StyledSelect.tsx';
import SuspenseLoader from '../../../../shared/suspense-loader/SuspenseLoader.tsx';
import FormBodyHeader from '../../../components/form/FormBodyHeader.tsx';

interface Props {}

const AdminInboxesSettings: React.FC<Props> = () => {
  const inboxes = useSelector((state) => state.admin.inboxes);
  const { email } = useSelector((state) => state.user.userAccount);
  const tenantDetails = useSelector((state) => state.admin.tenantDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const activeInbox = useMemo(() => {
    if (inboxes) return inboxes.find((inbox) => inbox.id === inboxId);
  }, [inboxId, inboxes]);

  const inboxOptions = useMemo(() => {
    if (inboxes) {
      return inboxes.map((inbox) => {
        return {
          name: inbox.settings.name,
          id: inbox.id,
        };
      });
    }
  }, [inboxes]);

  const initialState = useMemo(() => {
    if (activeInbox) {
      return activeInbox;
    } else {
      return defaultClientInbox;
    }
  }, [activeInbox]);

  const handleSave: ChangeSaveCallback<IClientInbox> = async () => {
    const mapped = inboxClientToRaw(state);
    if (activeInbox) {
      return patchInbox(mapped).then(() => {
        return dispatch(getInboxes());
      });
    } else {
      return postInbox(mapped).then((res) => {
        return dispatch(getInboxes()).then(() => {
          if (res.data['id']) {
            navigate(`/admin/inboxes/${res.data['id']}/settings`);
          }
        });
      });
    }
  };

  const { save, hasChanges, saving, state, setState, error, handleInput } = useChangeTracker<IClientInbox>(
    initialState,
    handleSave
  );

  const tableColumnsMapping = {
    approver: t('home:table.approved'),
    confidence: t('home:table.confidence'),
    digitizedDate: t('home:table.dateAdded'),
    docTypeId: t('home:table.docType'),
    lastUserUpdate: t('home:table.lastEdited'),
    name: t('home:table.docName'),
    tagTypeId: t('home:table.state'),
  };

  const actionTypeOptions = [
    { value: 'none', label: 'No Action' },
    { value: 'approve', label: 'Approve' },
    { value: 'delete', label: 'Delete' },
    { value: 'bounce', label: 'Bounce' },
  ] as DropdownOption[];

  if (inboxId !== 'new' && !activeInbox) {
    return <SuspenseLoader name={'admin-inbox'} />;
  }

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={t(`admin:errors.${error}`, { defaultValue: '' })}
        title={activeInbox?.settings.name || t('admin:inboxes.createInbox')}
      />
      <div className={s.sections}>
        <FormSection title={'Data'}>
          <FormInputField
            testId="inbox-name-input"
            required
            value={state.settings.name}
            type={'text'}
            label={t('admin:inboxes.name')}
            description={t('admin:inboxes.nameDescription')}
            onChange={(val) => {
              handleInput(val, 'settings.name');
            }}
          />
          <FormInputField
            testId={'inbox-id-input'}
            hidden={!activeInbox}
            isCopyField
            value={state.id}
            type={'text'}
            label={t('admin:masterdata.id')}
            description={t('admin:masterdata.idDescription')}
          />

          <FormInputField
            hidden={!activeInbox || !tenantDetails?.config?.features?.emailIngestRoute}
            type={'text'}
            value={state.postEmail}
            label={t('admin:inboxes.uploadMail')}
            description={t('admin:inboxes.uploadMailDescription')}
            isCopyField
          />
          <FormInputField
            testId={'inbox-retention-input'}
            value={state.settings.documentRetentionTime ?? 7}
            type={'number'}
            numberInputOptions={{
              label: t('admin:inboxes.days'),
              min: 2,
              max: 365,
              step: 1,
            }}
            label={t('admin:inboxes.documentRetentionTime')}
            description={t('admin:inboxes.documentRetentionTimeDescription')}
            onChange={(val) => {
              handleInput(parseInt(val), 'settings.documentRetentionTime');
            }}
          />
          <FormInputField
            hidden={!activeInbox}
            value={state.config.workflowVersion}
            type={'text'}
            label={t('admin:inboxes.workflow')}
            description={t('admin:inboxes.workflowDescription')}
            isPaperboxOnly
            isCopyField
          />
        </FormSection>
        <FormSection title={'Features'}>
          <FormInputField
            testId="mailroom-toggle"
            value={state.settings.mailroom}
            type={'toggle'}
            label={t('admin:inboxes.settings.mailroom')}
            description={t('admin:inboxes.settings.mailroomDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.mailroom');
            }}
            isPaperboxOnly
          />
          <FormInputField
            value={state.settings.autoAdvance}
            type={'toggle'}
            label={t('admin:inboxes.settings.autoAdvance')}
            description={t('admin:inboxes.settings.autoAdvanceDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.autoAdvance');
            }}
          />
          <FormInputField
            value={state.settings.documentCopy}
            type={'toggle'}
            label={t('admin:inboxes.settings.documentCopy')}
            description={t('admin:inboxes.settings.documentCopyDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.documentCopy');
            }}
          />
          <FormInputField
            disabled={!isPaperboxEmail(email)}
            value={state.settings.documentTransform}
            type={'toggle'}
            label={t('admin:inboxes.settings.documentTransform')}
            description={t('admin:inboxes.settings.documentTransformDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.documentTransform');
            }}
          />
          <FormInputField
            value={state.settings.documentDownload}
            type={'toggle'}
            label={t('admin:inboxes.settings.documentDownload')}
            description={t('admin:inboxes.settings.documentDownloadDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.documentDownload');
            }}
          />
          <FormInputField
            testId="file-upload-toggle"
            value={state.settings.fileUpload}
            type={'toggle'}
            label={t('admin:inboxes.settings.fileUpload')}
            description={t('admin:inboxes.settings.fileUploadDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.fileUpload');
            }}
          />
          <FormInputField
            value={state.settings.bounce}
            type={'toggle'}
            label={t('admin:inboxes.settings.bounce')}
            description={t('admin:inboxes.settings.bounceDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.bounce');
            }}
          />
          <FormInputField
            value={state.settings.labelingMode}
            type={'toggle'}
            label={t('admin:inboxes.settings.labelingMode')}
            description={t('admin:inboxes.settings.labelingModeDesc')}
            onChange={(val) => {
              handleInput(val, 'settings.labelingMode');
            }}
          />
        </FormSection>
        <FormSection title={t('admin:inboxes.inboxMove.title')}>
          <FormInputField
            type={'toggle'}
            value={state?.settings.inboxMoveWhitelistActive}
            onChange={(val) => {
              handleInput(val, 'settings.inboxMoveWhitelistActive');
            }}
            label={t('admin:inboxes.inboxMove.enabled')}
            description={t('admin:inboxes.inboxMove.enabledDescription')}
          />
          <FormInputField
            hidden={!state?.settings.inboxMoveWhitelistActive}
            type={'list'}
            value={state?.settings.inboxMoveWhitelist ?? []}
            listInputOptions={{
              options: inboxOptions ?? [],
              selectedOptions: state?.settings.inboxMoveWhitelist ?? [],
              onChangeList: (newList) => {
                handleInput(newList, 'settings.inboxMoveWhitelist');
              },
            }}
            label={t('admin:inboxes.inboxMove.list')}
            description={t('admin:inboxes.inboxMove.listDescription')}
          />
        </FormSection>

        <FormSection title={'Inbox Columns'}>
          <div className={clsx(s.item, s.item__vertical)}>
            <div className={s.item_text}>
              <h4>{t('admin:inboxes.tableCols')}</h4>
              <p>{t('admin:inboxes.tableColsDescription')}</p>
            </div>
            {state?.settings.tableColumns && (
              <div className={clsx(s.item_action, s.tables_grid)}>
                {Object.entries(state.settings.tableColumns)
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([k, v]) => {
                    const name = tableColumnsMapping[k];
                    if (!name) return null;
                    return (
                      <div
                        onClick={() => {
                          setState((state) => {
                            const clone = cloneDeep(state);
                            clone.settings.tableColumns[k] = !v;
                            return clone;
                          });
                        }}
                        key={k}
                        className={s.tables_grid_item}
                      >
                        <span>{name}</span>
                        <Checkbox
                          checked={v}
                          onClick={() => {
                            setState((state) => {
                              const clone = cloneDeep(state);
                              clone.settings.tableColumns[k] = !v;
                              return clone;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </FormSection>
        <FormSection title={'Inbox Cleanup'}>
          <FormInputField
            type={'toggle'}
            value={state?.settings.autoDelete.active}
            onChange={(val) => {
              handleInput(val, 'settings.autoDelete.active');
            }}
            label={t('admin:inboxes.autoDelete.enabled')}
            description={t('admin:inboxes.autoDelete.enabledDescription')}
          />
          <FormInputField
            type={'dropdown'}
            value={actionTypeOptions.find((ett) => ett.value === state?.settings.autoDelete.actionType)}
            dropdownOptions={actionTypeOptions}
            onChange={(val: DropdownOption) => {
              handleInput(val.value, 'settings.autoDelete.actionType');
            }}
            label={t('admin:inboxes.autoDelete.actionType')}
            description={t('admin:inboxes.autoDelete.actionTypeDescription')}
          />
          <FormInputField
            type={'number'}
            value={state?.settings.autoDelete.timeField}
            onChange={(val) => {
              handleInput(parseInt(val), 'settings.autoDelete.timeField');
            }}
            numberInputOptions={{
              label: t('admin:inboxes.days'),
              min: 1,
              max: 29,
              step: 1,
            }}
            label={t('admin:inboxes.autoDelete.time')}
            description={t('admin:inboxes.autoDelete.timeDescription')}
          />
        </FormSection>
        <FormSection title={'Inbox Import/Export'} hidden={!isPaperboxEmail(email)}>
          <FormInputField
            label={'Export'}
            description={
              'Exports inbox settings and configurations (Document types, field types, metadata, action types and tags) to a JSON file.'
            }
            type={'button'}
            buttonOptions={{
              type: 'normal',
              onClick: () => dispatch(exportSliceToJson()),
              text: 'Export Inbox Configuration',
            }}
          />
          <FormInputField
            label={'Import'}
            description={'Import Inbox Configuration from a JSON file.'}
            type={'button'}
            buttonOptions={{
              type: 'normal',
              onClick: () => showModal(<AdminImportModal inboxId={inboxId} />),
              text: 'Import Inbox Configuration',
            }}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminInboxesSettings;
