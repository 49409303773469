import { useEffect } from 'react';

export default function useConsole(message: any, prefix?: string) {
  useEffect(() => {
    if (prefix) {
      console.log(prefix, message);
    } else {
      console.log(message);
    }
  }, [prefix, message]);

  return null;
}
