import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TriangleDownIcon } from '../../../../shared/assets/svg/triangle-down-icon.svg';
import {
  ActiveEntityPair,
  DocumentEntity,
  DocumentEntityGroup,
  EntityComplex,
  EntityTable,
} from '../../../../shared/models/document';
import { useSelector } from '../../../../shared/store/store';
import s from '../../../../shared/styles/component/document/document-labeler-sidebar.module.scss';
import DocumentLabelerSidebarRow from './DocumentLabelerSidebarRow';

interface Props {
  entityGroup: DocumentEntityGroup;
  handleFieldDelete: (entityPair: ActiveEntityPair, event?: any) => void;
  handleFieldEdit: (entity: DocumentEntity, changes: string | boolean | EntityTable | EntityComplex) => void;
  handleFieldApprove?: (entity: DocumentEntity, event?: any) => void;
  handleFieldSelect: (entityPair: ActiveEntityPair, event?: any) => void;
}

const DocumentLabelerSidebarCategory: React.FC<Props> = ({
  entityGroup,
  handleFieldDelete,
  handleFieldSelect,
  handleFieldEdit,
  handleFieldApprove,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const docTypeCategories = useSelector((state) => state.document.docTypeCategories);
  const activeEntityPair = useSelector((state) => state.labeler.activeEntityPair);
  const { t } = useTranslation();

  const categoryName = useMemo(() => {
    if (docTypeCategories) {
      const category = docTypeCategories.find((e) => e.id === entityGroup.categoryId);
      if (category) {
        return category.name;
      } else return t('document:noCategory');
    }
  }, [t, docTypeCategories, entityGroup.categoryId]);

  const categoryColor = useMemo(() => {
    if (docTypeCategories) {
      const category = docTypeCategories.find((e) => e.id === entityGroup.categoryId);
      if (category) {
        return category.color;
      } else return null;
    }
  }, [docTypeCategories, entityGroup.categoryId]);

  useEffect(() => {
    if (entityGroup.entities) {
      const activeEntity = entityGroup.entities.find((e) => e.uuid === activeEntityPair?.entityId);
      if (activeEntity) {
        setIsOpen(true);
      }
    }
  }, [activeEntityPair, docTypeCategories, entityGroup.entities]);

  return (
    <div className={s.category}>
      <div
        className={clsx(s.category_header, { [s.category_header__open]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={s.category_header_title}>
          {categoryName}
          {categoryColor && (
            <div
              className={s.category_header_dot}
              style={categoryColor ? { background: categoryColor } : {}}
            />
          )}
        </div>
        <TriangleDownIcon
          style={categoryColor ? { color: categoryColor } : {}}
          className={clsx(s.category_header_arrow, {
            [s.category_header_arrow__active]: isOpen,
          })}
        />
      </div>

      <div
        className={clsx(s.category_rows, { [s.category_rows__open]: isOpen })}
        style={
          isOpen
            ? {
                maxHeight: 'unset',
              }
            : { maxHeight: 0 }
        }
      >
        {entityGroup.entities.map((entity) => {
          return (
            <DocumentLabelerSidebarRow
              key={entity.uuid}
              handleFieldDelete={handleFieldDelete}
              handleFieldEdit={handleFieldEdit}
              entity={entity}
              documentDetails={activeDocument}
              handleFieldSelect={handleFieldSelect}
              handleFieldApprove={handleFieldApprove}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DocumentLabelerSidebarCategory;
