import React, { useMemo } from 'react';
import { Ring } from '@uiball/loaders';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDeepCompareMemo } from 'use-deep-compare';
import { ReactComponent as CheckmarkIcon } from '../../../shared/assets/svg/checkmark-icon.svg';
import { ReactComponent as ErrorIcon } from '../../../shared/assets/svg/error-icon.svg';
import { ReactComponent as TrashIcon } from '../../../shared/assets/svg/trash-icon.svg';
import { TagType } from '../../../shared/models/document';
import { FileState } from '../../../shared/models/files';
import s from '../../../shared/styles/component/inbox/inbox-modal.module.scss';
import DocumentTagDropdown from '../../document/header/DocumentTagDropdown';
import StyledSelect, { DropdownOption } from '../../shared/dropdown/StyledSelect';
import LoadingBar from '../../shared/loading-bar/LoadingBar';
import Tooltip from '../../shared/tooltip/Tooltip';

interface Props {
  isGlobal?: boolean;
  docTypeOptions: DropdownOption[];
  tagOptions: TagType[];
  fileState: FileState;
  handleDelete: (file: File) => void;
  handleChange: (fileState: FileState) => void;
  getSubTypeOptions: (docTypeId: string) => DropdownOption[];
}

const FileRow: React.FC<Props> = ({
  isGlobal = false,
  docTypeOptions,
  tagOptions,
  handleChange,
  fileState,
  handleDelete,
  getSubTypeOptions,
}) => {
  const { t } = useTranslation();
  const docTypeMapped = useDeepCompareMemo(() => {
    return {
      label: docTypeOptions?.find((e) => e.value === fileState.docTypeId).label,
      value: fileState.docTypeId,
    };
  }, [isGlobal, docTypeOptions, fileState]);

  const subTypeOptions = useMemo(() => {
    return [
      {
        label: t('home:fileUpload.autoDetect'),
        value: 'auto-detect',
      },
      ...getSubTypeOptions(docTypeMapped.value),
    ];
  }, [docTypeMapped.value, getSubTypeOptions, t]);

  const subTypeMapped = useMemo(() => {
    if (fileState?.subTypeId) {
      const subType = subTypeOptions.find((e) => e.value === fileState.subTypeId);
      if (!subType) return null;
      return {
        label: subType.label,
        value: fileState.subTypeId,
      };
    }
    return null;
  }, [subTypeOptions, fileState]);

  return (
    <>
      <div style={{ gridColumn: 1 }} className={clsx(s.cell, s.file_name)}>
        {fileState.file.name}
      </div>
      {fileState.progress > 0 ? (
        <div style={{ gridColumn: '2/6' }} className={s.loader}>
          <div className={s.bar}>
            <LoadingBar hasError={fileState.error != null} progress={fileState.progress} />
          </div>
          {fileState.error ? (
            <Tooltip content={fileState.error} position="left">
              <ErrorIcon data-testid="file-row-error" className={s.file_error} />
            </Tooltip>
          ) : fileState.progress !== 100 ? (
            <div className={s.file_success}>
              <Ring color={'#0085ff'} size={16} lineWeight={5} />
            </div>
          ) : (
            <CheckmarkIcon data-testid="file-row-error" className={s.file_success} />
          )}
        </div>
      ) : (
        <>
          <div style={{ gridColumn: 2 }} className={clsx(s.cell, s.file_actions)}>
            <div className={s.dropdown}>
              <StyledSelect
                isLoading={!docTypeOptions}
                defaultValue={docTypeMapped}
                value={docTypeMapped}
                onChange={(selectedOption: any) => {
                  const options = getSubTypeOptions(selectedOption.value);
                  const subType = options.length > 0 ? 'auto-detect' : null;
                  handleChange({
                    ...fileState,
                    docTypeId: selectedOption.value,
                    subTypeId: subType,
                  });
                }}
                options={docTypeOptions}
              />
            </div>
          </div>
          {subTypeMapped && (
            <div style={{ gridColumn: 3 }} className={clsx(s.cell, s.file_actions)}>
              <div className={s.dropdown}>
                <StyledSelect
                  isLoading={!subTypeOptions}
                  defaultValue={subTypeMapped}
                  value={subTypeMapped}
                  onChange={(selectedOption: any) => {
                    handleChange({ ...fileState, subTypeId: selectedOption.value });
                  }}
                  options={subTypeOptions}
                />
              </div>
            </div>
          )}
          {tagOptions && (
            <div style={{ gridColumn: 4 }} className={clsx(s.cell, s.file_actions)}>
              <div className={s.tags}>
                <DocumentTagDropdown
                  activeTagId={fileState.tagId}
                  handleSelectTag={(tagId) => {
                    handleChange({ ...fileState, tagId: tagId });
                  }}
                  tagTypes={tagOptions}
                />
              </div>
            </div>
          )}

          <button className={s.cell} style={{ gridColumn: 5, width: 70 }}>
            <TrashIcon
              data-testid="file-row-delete"
              className={s.file_trash}
              onClick={() => handleDelete(fileState.file)}
            />
          </button>
        </>
      )}
    </>
  );
};

export default FileRow;
