import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ErrorIllustration } from '../shared/assets/svg/error-illust.svg';
import { ReactComponent as PaperboxLogo } from '../shared/assets/svg/paperbox-logo.svg';
import s from '../shared/styles/component/error/error.module.scss';
interface Props {
  message?: string;
  stack?: string;
}

const ErrorContainer: React.FC<Props> = ({ message, stack }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <main className={s.container}>
      <div className={s.wrapper}>
        <PaperboxLogo />
        <ErrorIllustration />
        <h2>
          <b>Oops, something went wrong!</b> <br />
          Our staff has been notified and will fix this as soon as possible.
          <br /> You may also try to refresh the page or try again later.
          {message && (
            <div
              onClick={() => setIsExpanded(!isExpanded)}
              className={clsx(s.error_container, {
                [s.error_container__expanded]: isExpanded,
              })}
            >
              <p>{stack}</p>
              <p>{message}</p>
            </div>
          )}
        </h2>
      </div>
    </main>
  );
};

export default ErrorContainer;
