import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Row } from 'react-table';
import { DocumentDetails } from '../../../../shared/models/document.ts';
import { useSelector } from '../../../../shared/store/store.ts';
import s from '../../../../shared/styles/component/inbox/inbox-table.module.scss';

interface Props {}

interface Props {
  goToDocument: (docId: string) => void;
  rowData: DocumentDetails;
  row: Row;
  setIsRowChecked: (rowData: DocumentDetails) => void;
  loading: boolean;
  isRowChecked?: boolean;
}

const InboxLockableTableRow: React.FC<Props> = ({ goToDocument, rowData, row, isRowChecked }) => {
  const lockedList = useSelector((state) => state.document.lockedList);
  const makeStyle = (columnId: string, index: number) => {
    let style;
    if (columnId === 'locker') {
      style = {
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        color: 'rgba(0, 0, 0, 0.54)',
      };
    } else if (columnId === 'name') {
      style = { width: 'auto' };
    } else {
      style = { width: '12.5%' };
    }

    if (!rowData.lastOpenedDate && rowData.active === true) {
      style = { ...style, fontWeight: 800 };
    }
    if (index === 0 && columnId !== 'locker') {
      style = { ...style, paddingLeft: 20 };
    }

    return style;
  };

  const locker = useMemo(() => {
    if (lockedList) {
      const element = lockedList[rowData.id];
      if (element) {
        return element;
      } else {
        return null;
      }
    }
  }, [lockedList, rowData.id]);

  return (
    <tr
      id={`table-row-${row.index}`}
      data-testid={`table-row`}
      data-hj-suppress
      tabIndex={0}
      className={clsx(s.row, {
        [s.row__active]: isRowChecked,
        [s.row__clickable]: rowData.processed === true && locker == null,
        [s.row__locked]: locker != null,
        [s.row__pending]: rowData.processed !== true,
      })}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && rowData.processed === true && locker == null) {
          goToDocument(rowData.id);
        }
      }}
      {...row.getRowProps()}
    >
      {row.cells.map((cell, index) => {
        let alignment: any = 'center';
        if (cell.column.id === 'name') alignment = 'left';
        // if (row.cells.length - 1 === index) alignment = 'right';
        return (
          <td
            data-testid="table-cell"
            className={s.cell}
            key={cell.value}
            style={makeStyle(cell.column.id, index)}
            align={alignment}
            onClick={() => {
              if (locker == null && cell.column.id !== 'locker') {
                goToDocument(rowData.id);
              }
            }}
            {...cell.getCellProps()}
          >
            {cell.render('Cell') as any}
          </td>
        );
      })}
    </tr>
  );
};

export default InboxLockableTableRow;
