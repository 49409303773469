import React from 'react';
import { ModalContext } from './modalContext';

function useModal(closeFunc?: () => void) {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a modalContext provider');
  }
  const enhancedCloseModal = () => {
    if (closeFunc) {
      closeFunc();
    }
    context.closeModal();
  };
  return { ...context, closeModal: enhancedCloseModal };
}

export { useModal };
