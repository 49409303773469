import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as ArrowBackIcon } from '../../../shared/assets/svg/arrow-back-icon.svg';
import { ReactComponent as CrossIcon } from '../../../shared/assets/svg/cross-icon.svg';
import { ReactComponent as HelpIcon } from '../../../shared/assets/svg/help-icon.svg';
import useOutsideClick from '../../../shared/hooks/useOutsideClick';
import s from '../../../shared/styles/component/document/document-labeler-info-panel.module.scss';

const DocumentLabelerInfoPanel: React.FC = () => {
  const ref = useRef();
  useOutsideClick(ref, () => setIsOpen(false));
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={s.wrapper} ref={ref}>
      <button className={clsx(s.button, { [s.button__active]: isOpen })} onClick={() => setIsOpen(!isOpen)}>
        <span className={s.text}>{t('document:toolbar.help')}</span>
        <CrossIcon className={clsx(s.icon, { [s.icon__hidden]: !isOpen })} />
        <HelpIcon className={clsx(s.icon, { [s.icon__hidden]: isOpen })} />
      </button>
      <CSSTransition in={isOpen} timeout={250} classNames={'help-anim'} unmountOnExit mountOnEnter>
        <div className={s.container}>
          <div className={s.header}>
            <h3 className={s.title}>{t('document:help.labelingHelp')}</h3>
          </div>
          <div className={s.content}>
            <div className={s.segment}>
              <h4 className={s.title}>Shortcuts</h4>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>Shift</div> + Scroll
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutHorizontal')}</span>
              </div>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>Ctrl</div> + Scroll
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutZoom')}</span>
              </div>

              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>Enter</div>
                  <span style={{ marginRight: 3 }}>/</span>
                  <div className={s.key}>Delete</div>
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutCreate')}</span>
              </div>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>E</div>
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutEdit')}</span>
              </div>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>Space</div> + Mouse
                </span>

                <span className={s.keybind_description}>{t('document:help.shortcutDrag')}</span>
              </div>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>
                    <ArrowBackIcon />
                  </div>
                  <div className={s.key}>
                    <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} />
                  </div>
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutPage')}</span>
              </div>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>Ctrl</div>
                  <span style={{ marginRight: 3 }}>+</span>
                  <div className={s.key}>Shift</div>
                  <span style={{ marginRight: 3 }}>+</span>
                  <div className={s.key}>
                    <ArrowBackIcon />
                  </div>
                  <div className={s.key}>
                    <ArrowBackIcon style={{ transform: 'rotate(180deg)' }} />
                  </div>
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutDoc')}</span>
              </div>
              <div className={s.keybind}>
                <span className={s.keybind_name}>
                  <div className={s.key}>Ctrl</div>
                  <span style={{ marginRight: 3 }}>+</span>
                  <div className={s.key}>F</div>
                </span>
                <span className={s.keybind_description}>{t('document:help.shortcutSearch')}</span>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default DocumentLabelerInfoPanel;
