import React, { lazy, Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import { Route, BrowserRouter, useLocation, Routes, Navigate } from 'react-router-dom';
import AdminConnectors from '../../components/admin/routes/connectors/AdminConnectors.tsx';
import AdminConnectorEdit from '../../components/admin/routes/connectors/details/AdminConnectorEdit.tsx';
import AdminInboxes from '../../components/admin/routes/inboxes/AdminInboxes.tsx';
import AdminInboxesBounceOptionEdit from '../../components/admin/routes/inboxes/details/AdminInboxesBounceOptionEdit.tsx';
import AdminInboxesDoctypeEdit from '../../components/admin/routes/inboxes/details/AdminInboxesDoctypeEdit.tsx';
import AdminInboxesFieldEdit from '../../components/admin/routes/inboxes/details/AdminInboxesFieldEdit.tsx';
import AdminInboxesMasterdataEdit from '../../components/admin/routes/inboxes/details/AdminInboxesMasterdataEdit.tsx';
import AdminInboxesMetadataEdit from '../../components/admin/routes/inboxes/details/AdminInboxesMetadataEdit.tsx';
import AdminInboxesSubtypeEdit from '../../components/admin/routes/inboxes/details/AdminInboxesSubtypeEdit.tsx';
import AdminInboxesBounceFieldEdit from '../../components/admin/routes/inboxes/details/AdminInboxesBounceFieldEdit.tsx';
import AdminInboxesTagEdit from '../../components/admin/routes/inboxes/details/AdminInboxesTagEdit.tsx';
import AdminInboxesBounceFields from '../../components/admin/routes/inboxes/paths/AdminInboxesBounceFields.tsx';
import AdminInboxesDoctypes from '../../components/admin/routes/inboxes/paths/AdminInboxesDoctypes.tsx';
import AdminInboxesFields from '../../components/admin/routes/inboxes/paths/AdminInboxesFields.tsx';
import AdminInboxesMasterdata from '../../components/admin/routes/inboxes/paths/AdminInboxesMasterdata.tsx';
import AdminInboxesMetadata from '../../components/admin/routes/inboxes/paths/AdminInboxesMetadata.tsx';
import AdminInboxesTags from '../../components/admin/routes/inboxes/paths/AdminInboxesTags.tsx';
import AdminSettings from '../../components/admin/routes/settings/AdminSettings.tsx';
import AdminUsers from '../../components/admin/routes/users/AdminUsers.tsx';
import AdminWebhooks from '../../components/admin/routes/webhooks/AdminWebhooks.tsx';
import AuthM365Admin from '../../components/auth/steps/AuthM365Admin.tsx';
import InboxDocuments from '../../components/inbox/documents/InboxDocuments.tsx';
import InboxHistoricalDocuments from '../../components/inbox/documents/InboxHistoricalDocuments.tsx';
import SuspenseLoader from '../../components/shared/suspense-loader/SuspenseLoader';
import DocumentContainer from '../../containers/DocumentContainer';
import HomeContainer from '../../containers/HomeContainer';
import { getCurrentEnvCode, retryLoad } from '../helpers/helpers';
import { ModalContextProvider } from '../hooks/modalContext.tsx';
import { auth } from '../store/setup/firebase-setup';
import PrivateRoute from './PrivateRoute';
import AdminContainer from '../../containers/AdminContainer.tsx';
import AdminInboxesSettings from '../../components/admin/routes/inboxes/paths/AdminInboxesSettings.tsx';
import AdminWebhookEdit from '../../components/admin/routes/webhooks/details/AdminWebhookEdit.tsx';

const AuthLogin = lazy(() => retryLoad(() => import('../../components/auth/steps/AuthLogin')));

const AuthOnboarding = lazy(() => retryLoad(() => import('../../components/auth/steps/AuthOnboarding')));

const PrivateLoginContainer = lazy(() => retryLoad(() => import('../../containers/PrivateLoginContainer')));

const AuthActionContainer = lazy(() =>
  retryLoad(
    () => import(/* webpackChunkName: "AuthActionContainer" */ '../../containers/AuthActionContainer')
  )
);

const InboxDashboard = lazy(() =>
  retryLoad(
    () => import(/* webpackChunkName: "InboxContent" */ '../../components/inbox/dashboard/InboxDashboard')
  )
);

const TwoFactor = lazy(() =>
  retryLoad(() => import(/* webpackChunkName: "TwoFactor" */ '../../components/two-factor/TwoFactor'))
);

interface RouterProps {
  redirectMode?: boolean;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter: React.FC<RouterProps> = ({ redirectMode }) => {
  return (
    <BrowserRouter>
      <ModalContextProvider>
        <SentryRoutes>
          <>
            <Route path={'/m365-admin'} element={<AuthM365Admin />} />
            {redirectMode && <Route element={<RedirectRouter />} path="*" />}
            {!redirectMode && (
              <>
                <Route element={<AuthLogin />} path="login" />
                <Route element={<AuthOnboarding />} path="onboard" />
                <Route element={<PrivateLoginContainer />} path="_ppln" />
                <Route
                  element={<PrivateRoute element={<DocumentContainer />} />}
                  path="/inbox/:inboxId/documents/:docId"
                />
                <Route
                  element={<PrivateRoute element={<DocumentContainer historical />} />}
                  path="/inbox/:inboxId/historical/:docId"
                />
                <Route element={<PrivateRoute element={<AdminContainer />} isAdmin />} path={'admin'}>
                  <Route path={'settings'} element={<AdminSettings />} />
                  <Route path={'inboxes'} element={<AdminInboxes />}>
                    <Route path=":inboxId">
                      <Route index element={<Navigate to={'settings'} />} />
                      <Route path="settings" element={<AdminInboxesSettings />} />
                      <Route path="doctypes" element={<AdminInboxesDoctypes />} />
                      <Route path="doctypes/:doctypeId" element={<AdminInboxesDoctypeEdit />} />
                      <Route
                        path="doctypes/:doctypeId/subtypes/:subtypeId"
                        element={<AdminInboxesSubtypeEdit />}
                      />
                      <Route path="fields" element={<AdminInboxesFields />} />
                      <Route path="fields/:fieldId" element={<AdminInboxesFieldEdit />} />

                      <Route path="metadata" element={<AdminInboxesMetadata />} />
                      <Route path="metadata/:metadataId" element={<AdminInboxesMetadataEdit />} />

                      <Route path="bounce-fields" element={<AdminInboxesBounceFields />} />
                      <Route path="bounce-fields/:bounceFieldId" element={<AdminInboxesBounceFieldEdit />} />
                      <Route
                        path="bounce-fields/:bounceFieldId/options/:optionId"
                        element={<AdminInboxesBounceOptionEdit />}
                      />

                      <Route path="tags" element={<AdminInboxesTags />} />
                      <Route path="tags/:tagId" element={<AdminInboxesTagEdit />} />

                      <Route path="masterdata" element={<AdminInboxesMasterdata />} />
                      <Route path="masterdata/:masterdataId" element={<AdminInboxesMasterdataEdit />} />

                      <Route path="*" element={<Navigate replace to="/admin/:inboxId/settings" />} />
                    </Route>
                  </Route>
                  <Route path={'users'} element={<AdminUsers />} />
                  <Route path={'connectors'} element={<AdminConnectors />}>
                    <Route path=":connectorId" element={<AdminConnectorEdit />} />
                  </Route>
                  <Route path={'webhooks'} element={<AdminWebhooks />}>
                    <Route path=":webhookId" element={<AdminWebhookEdit />} />
                  </Route>
                  <Route path="*" element={<Navigate replace to="/admin/inboxes" />} />
                </Route>
                <Route element={<PrivateRoute element={<HomeContainer />} />} path="/inbox">
                  <Route
                    index
                    element={
                      <Suspense
                        children={<InboxDocuments />}
                        fallback={<SuspenseLoader name={'InboxDocuments'} />}
                      />
                    }
                  />
                  <Route
                    path={`:inboxId/dashboard`}
                    element={
                      <Suspense
                        children={<InboxDashboard />}
                        fallback={<SuspenseLoader name={'InboxDashboard'} />}
                      />
                    }
                  />

                  <Route
                    path={`:inboxId/historical`}
                    element={
                      <PrivateRoute
                        element={
                          <Suspense
                            children={<InboxHistoricalDocuments />}
                            fallback={<SuspenseLoader name={'InboxHistoricalDocuments'} />}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path=":inboxId"
                    element={
                      <Suspense
                        children={<InboxDocuments />}
                        fallback={<SuspenseLoader name={'InboxDocuments'} />}
                      />
                    }
                  />
                </Route>
                <Route element={<PrivateRoute element={<TwoFactor />} />} path="2fa" />

                <Route path="/auth" element={<AuthActionContainer />} />
                <Route path="*" element={<Navigate to="/inbox" replace />} />
              </>
            )}
          </>
        </SentryRoutes>
      </ModalContextProvider>
    </BrowserRouter>
  );
};
export default AppRouter;

export const RedirectRouter = () => {
  const location = useLocation();

  useEffect(() => {
    const tenantId = queryString.parse(location.search).tenantId as string;
    const continueUrl = queryString.parse(location.search).continueUrl as string;
    if (!tenantId) return;
    const currentLocation = window.location.href;
    const tenantParts = tenantId.lastIndexOf('-');
    const tenantName = tenantId.substring(0, tenantParts);
    let env = getCurrentEnvCode();
    if (env === 'prd') env = null;

    const replaceValue = continueUrl ?? `https://${tenantName}${env ? `.${env}` : ''}.paperbox.ai`;
    const redirect = currentLocation.replace(/(?:http:\/\/)?(?:([^.]+)\.)?paperbox\.ai/, replaceValue);
    window.location.replace(redirect);
    auth.tenantId = tenantId;
  }, [location]);

  return null;
};
