import { inboxSlice } from '../../../../shared/store/inboxSlice.ts';
import { useDispatch, useSelector } from '../../../../shared/store/store.ts';

const usePagination = () => {
  const dispatch = useDispatch();
  // const documentListOptions = useSelector((state) => state.inbox.documentListOptions);
  const currentPageIndex = useSelector((state) => state.inbox.currentPageIndex);

  const handleNextPage = (e) => {
    e.preventDefault();
    dispatch(inboxSlice.actions.setCurrentPageIndex(currentPageIndex + 1));
    dispatch(
      inboxSlice.actions.patchDocumentListOptions({
        navDirection: 'forward',
      })
    );
  };

  const handlePrevPage = (e) => {
    e.preventDefault();

    dispatch(inboxSlice.actions.setCurrentPageIndex(currentPageIndex - 1));
    dispatch(
      inboxSlice.actions.patchDocumentListOptions({
        navDirection: 'back',
      })
    );
  };
  return {
    handleNextPage,
    handlePrevPage,
    currentPageIndex,
  };
};

export default usePagination;
