import React, { ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import s from './button.module.scss';

interface Props {
  text: ReactElement | string;
  iconElement?: ReactElement;
  disabled?: boolean;
  iconLeft?: boolean;
  type?: 'button' | 'submit';
}

const Button: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  text,
  iconElement,
  className,
  onClick,
  disabled,
  iconLeft = false,
  type,
  ...rest
}) => {
  const style: React.CSSProperties = useMemo(() => {
    let st: any = {
      flexDirection: iconLeft ? 'row-reverse' : 'row',
    };
    if (!onClick) {
      st = {
        ...st,
        cursor: 'default',
      };
    }
    return st;
  }, [iconLeft, onClick]);
  return (
    <button
      className={clsx(s.button, { [s.disabled]: disabled }, className)}
      style={style}
      onClick={!disabled ? onClick : null}
      type={type}
      {...rest}
    >
      {text}
      {iconElement &&
        React.cloneElement(iconElement, {
          className: clsx(s.icon, { [s.icon_left]: iconLeft }, { [s.icon_right]: !iconLeft }),
        })}
    </button>
  );
};

export default Button;
