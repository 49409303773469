import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientFieldType } from '../helpers/converters/fieldtype.ts';
import { IClientMetadata } from '../helpers/converters/metadata.ts';
import { AdminFlatDocTypeCategory } from '../models/admin';
import { ActionType, TagType } from '../models/document';
import { ApprovalCheckType, DocTypeSettings } from '../models/inbox';
import { AppThunk } from './store';

export interface SettingsState {
  docTypeSettings: {
    docTypeId: string;
    categories?: AdminFlatDocTypeCategory[];
    settings: DocTypeSettings;
  }[];
  metadataTypes: IClientMetadata[];
  approvalCheckTypes: ApprovalCheckType[];
  tagTypes?: TagType[];
  actionTypes?: ActionType[];
  entityTypes: IClientFieldType[];
  dashboardDayCount: number;
  sidebarWidth: number;
}

const initialState: SettingsState = {
  docTypeSettings: [],
  metadataTypes: [],
  entityTypes: [],
  approvalCheckTypes: [],
  dashboardDayCount: 31,
  sidebarWidth: 271,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setDocTypeSettings: (
      state,
      action: PayloadAction<{ docTypeId: string; settings: DocTypeSettings }[]>
    ) => {
      state.docTypeSettings = action.payload;
    },
    setEntityTypes: (state, action: PayloadAction<IClientFieldType[]>) => {
      state.entityTypes = action.payload;
    },
    setTagTypes: (state, action: PayloadAction<TagType[]>) => {
      state.tagTypes = action.payload;
    },
    setMetadataTypes: (state, action: PayloadAction<IClientMetadata[]>) => {
      state.metadataTypes = action.payload;
    },
    setApprovalCheckTypes: (state, action: PayloadAction<ApprovalCheckType[]>) => {
      state.approvalCheckTypes = action.payload;
    },
    setActionTypes: (state, action: PayloadAction<ActionType[]>) => {
      state.actionTypes = action.payload;
    },

    setDashboardDayCount: (state, action: PayloadAction<number>) => {
      state.dashboardDayCount = action.payload;
    },
    setSidebarWidth: (state, action: PayloadAction<number>) => {
      localStorage.setItem('sbw', action.payload.toString());
      state.sidebarWidth = action.payload;
    },
  },
});

export const getSidebarWidth = (): AppThunk => (dispatch) => {
  const localWidth = localStorage.getItem('sbw');
  dispatch(settingsSlice.actions.setSidebarWidth(localWidth ? parseInt(localWidth) : 271));
};
