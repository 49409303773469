import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import * as RR from 'react-redux';
import { TypedUseSelectorHook, useSelector as useStoreSelector } from 'react-redux';
import { adminSlice } from './adminSlice';
import { dashboardSlice } from './dashboardSlice';
import { documentSlice } from './documentSlice';
import { inboxSlice } from './inboxSlice';
import { labelerSlice } from './labelerSlice';
import { notificationSlice } from './notificationSlice.ts';
import { settingsSlice } from './settingsSlice';
import { subsSlice } from './subsSlice';
import tenantSlice from './tenantSlice';
import { userSlice } from './userSlice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const appReducer = combineReducers({
  inbox: inboxSlice.reducer,
  user: userSlice.reducer,
  tenant: tenantSlice.reducer,
  document: documentSlice.reducer,
  settings: settingsSlice.reducer,
  admin: adminSlice.reducer,
  labeler: labelerSlice.reducer,
  subs: subsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  notification: notificationSlice.reducer,
  sentryReduxEnhancer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action);
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),

  devTools: true,
});

export type RootState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
type AppDispatch = ThunkDispatch<RootState, any, AnyAction> | any;

export const useSelector: TypedUseSelectorHook<RootState> = useStoreSelector;
export const useDispatch = () => RR.useDispatch<AppDispatch>();
