import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useModal } from '../../../shared/hooks/useModal';
import s from './toggle.module.scss';

interface Props {
  setState: (state: boolean, e?: React.MouseEvent) => void;
  state: boolean;
  testId?: string;
  disabled?: boolean;
}

const Toggle: React.FC<Props> = ({ setState, state, disabled, testId }) => {
  const [isActive, setIsActive] = useState(state);
  const { isModalOpen } = useModal();
  const handleChange = (e, value: boolean) => {
    if (disabled) return;
    setState(value, e);
  };

  useEffect(() => {
    setIsActive(state);
  }, [state]);

  return (
    <div
      role={'checkbox'}
      aria-checked={isActive}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === 'Space') {
          if (!isModalOpen) setIsActive(!isActive);
        }
      }}
      data-testid={testId}
      onClick={(e) => handleChange(e, !isActive)}
      className={clsx(
        s.wrapper,
        { [s.wrapper__active]: isActive },
        { [s.wrapper__disabled]: disabled }
      )}
    >
      <div className={clsx(s.slider, { [s.slider__active]: isActive })} />
    </div>
  );
};

export default Toggle;
