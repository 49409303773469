import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../shared/hooks/useModal.tsx';
import Modal from '../../shared/modal/Modal.tsx';
import s from '../../../shared/styles/component/admin/admin-ms365.module.scss';
import si from '../../../shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as ChevronLeft } from '../../../shared/assets/svg/chevron-left.svg';

interface Props {
  setUserMappings: (newMappings: any[]) => void;
  mappedUsers: any[];
  microsoftUsers: any[];
}

const AdminMS365EmailPicker: React.FC<Props> = ({ setUserMappings, mappedUsers, microsoftUsers }) => {
  const { t } = useTranslation();
  const [activeUsers, setActiveUsers] = useState(mappedUsers ?? []);
  const { closeDialog } = useModal();
  const disabledUsers = useMemo(() => {
    return microsoftUsers.filter((e) => activeUsers.findIndex((mu) => mu.id === e.id) === -1);
  }, [activeUsers, microsoftUsers]);

  const handleEnable = (item: any) => {
    setActiveUsers((users) => {
      const clone = cloneDeep(users);
      clone.push({
        id: item.id,
        folderMapping: [],
      });
      return clone;
    });
  };
  const handleDisable = (item: any) => {
    setActiveUsers((users) => {
      let clone = cloneDeep(users);
      clone = clone.filter((u) => u.id !== item.id);
      return clone;
    });
  };
  const handleSave = () => {
    setUserMappings(activeUsers);
    closeDialog();
  };

  return (
    <Modal isDialog>
      <div className={s.emailPicker}>
        <div className={s.emailPickerHeader}>
          <h3>{t('admin:connectors.ms365.emailPicker.selectEmails')}</h3>
        </div>
        <div className={s.emailPickerBody}>
          <div className={s.column}>
            <div className={s.columnHeader}>
              <b>{t('admin:connectors.ms365.emailPicker.disabled')}</b>
              <button
                type={'button'}
                onClick={() => {
                  microsoftUsers.forEach((e) => {
                    if (!activeUsers.find((au) => au.id === e.id)) handleEnable(e);
                  });
                }}
              >
                {t('admin:connectors.ms365.emailPicker.enableAll')}
              </button>
            </div>
            <div className={s.columnItems}>
              {disabledUsers.length > 0 &&
                disabledUsers.map((e) => {
                  return (
                    <div key={e.id} onClick={() => handleEnable(e)} className={clsx(s.columnItem)}>
                      <span>{e.userPrincipalName}</span>
                      <ChevronLeft style={{ transform: 'rotate(180deg)' }} />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={s.column}>
            <div className={s.columnHeader}>
              <b>{t('admin:connectors.ms365.emailPicker.enabled')}</b>
              <button
                type={'button'}
                onClick={() => {
                  setActiveUsers([]);
                }}
              >
                {t('admin:connectors.ms365.emailPicker.disableAll')}
              </button>
            </div>
            <div className={s.columnItems}>
              {activeUsers.map((e) => {
                const mapped = microsoftUsers.find((mu) => mu.id === e.id);
                return (
                  <div
                    key={e.id}
                    onClick={() => handleDisable(e)}
                    className={clsx(s.columnItem, s.columnItem__flipped)}
                  >
                    <span>{mapped.userPrincipalName}</span>
                    <ChevronLeft />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={s.userPickerFooter}>
          <button className={clsx(si.save_button, si.save_button__alt)} onClick={closeDialog}>
            {t('document:dialog.cancel')}
          </button>
          <button className={clsx(si.save_button)} type={'button'} onClick={handleSave}>
            {t('document:dialog.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdminMS365EmailPicker;
