import React, { useEffect, useState } from 'react';
import s from './color-picker.module.scss';
interface Props {
  activeColor: string;
  onChange: (color: string) => void;
}

const colorPickerColors = [
  '#0085FF',
  '#FF5555',
  '#ff8000',
  '#7f19fc',
  '#0BC9CD',
  '#554971',
  '#FFC107',
  '#4CAF50',
  '#9C27B0',
  '#E91E63',
  '#03A9F4',
];
const ColorPicker: React.FC<Props> = ({ activeColor, onChange }) => {
  useEffect(() => {
    if (!activeColor) {
      onChange(colorPickerColors[0]);
    }
  }, [activeColor, onChange]);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  return (
    <div
      style={{ background: activeColor }}
      onClick={(e) => {
        if (e.target === e.currentTarget) setIsPickerOpen((state) => !state);
      }}
      data-testid="color-picker"
      className={s.container}
    >
      {isPickerOpen && (
        <div className={s.picker}>
          {colorPickerColors.map((color) => {
            return (
              <div
                key={color}
                onClick={() => {
                  setIsPickerOpen(false);
                  onChange(color);
                }}
                className={s.color}
                style={{ background: color }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
