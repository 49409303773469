import { createContext, useContext, useState, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from '../store/store.ts';

// Define the type for the context
interface IntercomBootContextType {
  isIntercomBooted: boolean;
}

// Create the context with a default value
const IntercomBootContext = createContext<IntercomBootContextType>({
  isIntercomBooted: false,
});

export const useIntercomBoot = () => useContext(IntercomBootContext);

export const IntercomBootProvider = ({ children }) => {
  const { boot, shutdown } = useIntercom();
  const [isIntercomBooted, setIsIntercomBooted] = useState(false);
  const userAccount = useSelector((state) => state.user.userAccount);
  const tenantLanguage = useSelector((state) => state.tenant.details.settings.language);
  const intercomHash = useSelector((state) => state.user.intercomHash);

  useEffect(() => {
    console.log(tenantLanguage);
    if (userAccount?.email && intercomHash && tenantLanguage && !isIntercomBooted) {
      boot({
        languageOverride: userAccount.preferences.language ?? tenantLanguage ?? 'nl',
        email: userAccount.email,
        actionColor: '#0085FF',
        backgroundColor: '#0085FF',
        userHash: intercomHash,
      });
      setIsIntercomBooted(true);
    }
  }, [tenantLanguage, isIntercomBooted, userAccount, intercomHash, boot, shutdown]);

  return <IntercomBootContext.Provider value={{ isIntercomBooted }}>{children}</IntercomBootContext.Provider>;
};
