import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DotPulse } from '@uiball/loaders';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SearchResult } from '../../../../../shared/models/document';
import { UrlParams } from '../../../../../shared/models/generic';
import { documentSlice, importMasterDataResult } from '../../../../../shared/store/documentSlice';
import { useDispatch, useSelector } from '../../../../../shared/store/store';
import s from '../../../../../shared/styles/component/document/sidebar/search.module.scss';

interface Props {
  result: SearchResult;
}

const SearchResultCard: React.FC<Props> = ({ result }) => {
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const metadataTypes = useSelector((state) => state.settings.metadataTypes);
  const copyStructure = useSelector((state) => state.document.copyStructure);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const masterDataImportStatus = useSelector((state) => state.document.masterDataImportStatus);

  const { inboxId }: UrlParams = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rowsRef = useRef();

  const [isExpanded, setIsExpanded] = useState(false);

  const fieldTypeName = useCallback(
    (field) => {
      if (field.mapping?.referenceType) {
        if (field.mapping.referenceType.includes('metadata')) {
          const mdResult = metadataTypes.find((e) => e.id === field.mapping.reference);
          if (mdResult) return mdResult.name;
        } else {
          const etResult = entityTypes.find((e) => e.id === field.mapping.reference);
          if (etResult) return etResult.name;
        }
      } else {
        if (field.mapping?.displayName) return field.mapping?.displayName;
      }
      return field.name;
    },
    [entityTypes, metadataTypes]
  );

  const sortedResults = useMemo(() => {
    const original = result.fields;
    const filtered = original.filter((e) => e.value);

    let sortedMapped = filtered.sort((a, b) => {
      return (
        Number(b.match) - Number(a.match) ||
        Number(b?.mapping?.isPinned || false) - Number(a?.mapping?.isPinned || false) ||
        fieldTypeName(a).localeCompare(fieldTypeName(b))
      );
    });
    sortedMapped = sortedMapped.filter((e) => {
      const mapped = metadataTypes.find((m) => m.id === e.mapping?.reference);
      return !mapped?.isHidden;
    });

    return [...sortedMapped].filter(
      (field) => field.mapping.displayName != null || field.mapping.reference != null
    );
  }, [fieldTypeName, metadataTypes, result]);

  const handleImportResult = () => {
    if (!copyStructure) return;
    const docId = copyStructure.originalDoc?.id;
    let mutationId;
    if (activeDocument?.id !== docId) mutationId = activeDocument.id;
    dispatch(importMasterDataResult(inboxId, result, docId, mutationId)).then(() => {
      dispatch(documentSlice.actions.setIsMasterDataSearchActive(false));
      dispatch(documentSlice.actions.setMasterDataResults(null));
    });
  };

  const style = useMemo(() => {
    const el = rowsRef.current as HTMLDivElement;
    if (el && isExpanded) {
      let totalHeight = 0;
      const childArr = Array.from(el.children);
      childArr.forEach((child) => {
        totalHeight += child.clientHeight + 2;
      });
      return { maxHeight: totalHeight };
    }
    return {};
  }, [isExpanded]);

  return (
    <div className={s.card} data-testid={'masterdata-result-card'}>
      {Object.keys(masterDataMappings).length > 1 && result.index?.tableName && (
        <div className={s.header}>{result.index.tableName}</div>
      )}
      <div ref={rowsRef} style={style} className={clsx(s.rows)}>
        {sortedResults.map((field) => {
          return (
            <div
              key={field.value + field.name}
              className={clsx(
                s.row,
                { [s.row__matched]: field.match },
                { [s.row__field]: field.mapping?.referenceType }
              )}
            >
              <div className={s.info}>
                <span className={s.value}>
                  {field.highlight !== null ? (
                    <div dangerouslySetInnerHTML={{ __html: field.highlight }}></div>
                  ) : (
                    field.value
                  )}
                </span>
                <span className={s.type}>{fieldTypeName(field)}</span>
              </div>
              {field.mapping?.referenceType && <div className={s.tag}></div>}
            </div>
          );
        })}
      </div>
      <div className={s.card_overlay}>
        <div data-testid={'masterdata-import'} onClick={handleImportResult} className={s.card_overlay_item}>
          {masterDataImportStatus === 'importing' ? (
            <>
              <div className={s.card_overlay_icon}>
                <DotPulse size={18} color={'#0085FF'} />
              </div>
            </>
          ) : (
            <>
              <span>{t('document:masterdata.import')}</span>
            </>
          )}
        </div>
        {sortedResults.length > 6 && (
          <>
            <div onClick={() => setIsExpanded(!isExpanded)} className={s.card_overlay_item}>
              {!isExpanded ? (
                <span>{t('document:masterdata.showMore', { value: sortedResults.length - 6 })}</span>
              ) : (
                <span>{t('document:masterdata.showLess', { value: sortedResults.length - 6 })}</span>
              )}
            </div>
          </>
        )}
      </div>
      {!isExpanded && sortedResults.length > 6 && <div className={s.overflow} />}
    </div>
  );
};

export default SearchResultCard;
