import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { cloneDeep, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { ReactComponent as ChevronDown } from '../../../shared/assets/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../shared/assets/svg/chevron-up.svg';
import { ReactComponent as InfoIcon } from '../../../shared/assets/svg/error-icon.svg';
import { ReactComponent as LockIcon } from '../../../shared/assets/svg/lock-icon.svg';
import { hexToRgb, usePrevious } from '../../../shared/helpers/helpers';
import { DocumentDetails } from '../../../shared/models/document';
import { inboxSlice } from '../../../shared/store/inboxSlice';
import { useDispatch, useSelector } from '../../../shared/store/store';
import s from '../../../shared/styles/component/inbox/inbox-table.module.scss';
import ApprovalChecks from '../../shared/approval-checks/ApprovalChecks';
import Checkbox from '../../shared/checkbox/Checkbox';
import Tooltip from '../../shared/tooltip/Tooltip';
import InboxTableLockableRow from './components/InboxTableLockableRow.tsx';
import InboxTableToolbar from './InboxTableToolbar';

interface Props {
  enabledColumns: Record<TableColumns, boolean>;
  pageCount: number;
  documentList: DocumentDetails[];
  goToDocument: (docId: string) => void;
}

export type TableColumns =
  | 'actor'
  | 'actionDate'
  | 'actionType'
  | 'name'
  | 'digitizedDate'
  | 'docTypeId'
  | 'tagTypeId'
  | 'confidence'
  | 'lastUserUpdate'
  | 'locker'
  | 'approvalChecks'
  | 'initialApprovalChecks';

const InboxTable: React.FC<Props> = ({ pageCount, documentList, goToDocument, enabledColumns }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const loading = useSelector((state) => state.inbox.documentsLoading);
  const activeDocType = useSelector((state) => state.inbox.activeDocumentType);
  const currentPageIndex = useSelector((state) => state.inbox.currentPageIndex);
  const documentListOptions = useSelector((state) => state.inbox.documentListOptions);
  const typeMap = useSelector((state) => state.inbox.typeMap);
  const lockedList = useSelector((state) => state.document.lockedList);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const tagTypes = useSelector((state) => state.settings.tagTypes);
  const users = useSelector((state) => state.admin.users);
  const user = useSelector((state) => state.user.userAccount);

  const prevDoctype = usePrevious(activeDocType);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [lastCheckedRowIndex, setLastCheckedRowIndex] = useState(null);

  const { t } = useTranslation();

  const getThreshold = useCallback(
    (docTypeId: string) => {
      if (docTypeSettings) {
        const options = docTypeSettings.find((e) => e.docTypeId === docTypeId);

        return options ? options.settings.approvalThreshold : 0;
      } else {
        return 0;
      }
    },
    [docTypeSettings]
  );

  useEffect(() => {
    if (!isEqual(prevDoctype, activeDocType) && !state?.back) {
      const patchContent = {
        docTypeId: activeDocType.docTypeId,
        subTypeId: activeDocType.subTypeId,
        navDirection: null,
      };
      if (prevDoctype !== undefined) patchContent['activeTagId'] = null;
      dispatch(inboxSlice.actions.patchDocumentListOptions(patchContent));
    }
  }, [activeDocType, dispatch, prevDoctype, state]);

  const handleShift = (e, isDown: boolean) => {
    if (e.key === 'Shift') {
      setIsShiftPressed(isDown);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', (e) => handleShift(e, true));
    return () => {
      document.removeEventListener('keydown', (e) => handleShift(e, true));
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', (e) => handleShift(e, false));
    return () => {
      document.removeEventListener('keyup', (e) => handleShift(e, false));
    };
  }, []);

  useEffect(() => {
    if (documentList) {
      setCheckedRows(documentList.filter((item) => item.isChecked));
    }
  }, [documentList]);

  const handleClearAllChecks = () => {
    const clearedData: DocumentDetails[] = JSON.parse(JSON.stringify(documentList));
    clearedData.forEach((item) => (item.isChecked = false));
    dispatch(inboxSlice.actions.setCurrentDocumentList(clearedData));
  };
  const handleCheckAll = () => {
    if (checkedRows.length === documentList.length) {
      handleClearAllChecks();
    } else {
      const filledData: DocumentDetails[] = JSON.parse(JSON.stringify(documentList));
      filledData.forEach((item) => (item.isChecked = true));
      dispatch(inboxSlice.actions.setCurrentDocumentList(filledData));
    }
  };

  const checkLocker = useCallback(
    (docId) => {
      if (lockedList) {
        const element = lockedList[docId];
        if (element) {
          return element;
        } else {
          return null;
        }
      }
    },
    [lockedList]
  );
  const getUserName = useCallback(
    (userId: string) => {
      return users.find((e) => e.id === userId)?.email ?? '';
    },
    [users]
  );

  const activeTagId = useMemo(() => {
    return documentListOptions?.activeTagId;
  }, [documentListOptions]);

  const handleRowCheck = useCallback(
    (row: DocumentDetails) => {
      const editedData = cloneDeep(documentList) as DocumentDetails[];
      const rowFromDataIndex = editedData.findIndex((td) => td.id === row.id);
      if (isShiftPressed) {
        editedData[rowFromDataIndex].isChecked = !editedData[rowFromDataIndex]?.isChecked;
        const min = Math.min(rowFromDataIndex, lastCheckedRowIndex);
        const max = Math.max(rowFromDataIndex, lastCheckedRowIndex);
        const isChecked = editedData[lastCheckedRowIndex]?.isChecked;
        editedData.forEach((row, index) => {
          const locker = checkLocker(row.id);
          if (locker == null) {
            if (index >= min && index <= max) {
              row.isChecked = isChecked;
            }
          }
        });
      } else {
        setLastCheckedRowIndex(rowFromDataIndex);
        editedData[rowFromDataIndex].isChecked = !editedData[rowFromDataIndex].isChecked;
      }
      dispatch(inboxSlice.actions.setCurrentDocumentList(editedData));
    },
    [checkLocker, dispatch, documentList, isShiftPressed, lastCheckedRowIndex]
  );

  const columns = React.useMemo(() => {
    const columns: Column<any>[] = [];
    if (enabledColumns['locker'])
      columns.push({
        id: 'locker',
        Header: '',
        accessor: 'locker',
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ height: 18, width: 18 }} />;
          }
          const locker = checkLocker(val.row.original.id);
          if (locker != null) {
            return (
              <Tooltip content={locker.email} position={'right'}>
                <div>
                  <LockIcon className={s.lock} />
                </div>
              </Tooltip>
            );
          } else {
            return (
              <>
                <div>
                  {val.row.original.processed ? (
                    <Checkbox
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleRowCheck(val.row.original);
                      }}
                      checked={val.row.original.isChecked}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              </>
            );
          }
        },
        disableSortBy: true,
      });
    if (enabledColumns['name'])
      columns.push({
        id: 'name',
        Header: t('home:table.docName'),
        accessor: 'name',
        sortDescFirst: true,
        disableSortBy: false,

        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ maxWidth: '60%' }} />;
          }

          const locker = checkLocker(val.row.original.id);
          const references = val.row.original.nMutations;
          const notes = val.row.original.notes;

          return (
            <div data-testid={'table-row-name'} className={s.name_wrapper}>
              <span className={s.name} style={locker ? { opacity: 0.5 } : {}}>
                {val.value}
              </span>

              {references > 0 && <div className={s.copy_counter}>{references + 1}</div>}
              {notes?.length > 0 && (
                <Tooltip
                  lightTheme
                  position={'right'}
                  delay={0}
                  content={`${notes.length} ${notes.length > 1 ? 'Notes' : 'Note'}`}
                >
                  <div className={s.notes_wrapper}>
                    <div className={s.notes_pip} />
                  </div>
                </Tooltip>
              )}
            </div>
          );
        },
      });
    if (enabledColumns['digitizedDate'])
      columns.push({
        id: 'uploadTime',
        Header: t('home:table.dateAdded'),
        accessor: 'uploadTime',
        sortDescFirst: true,
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ maxWidth: '50%' }} />;
          }
          const locker = checkLocker(val.row.original.id);
          return (
            <div style={locker ? { opacity: 0.5 } : {}}>
              {new Date(val.value).toLocaleString('nl-BE', {
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          );
        },
      });
    if (enabledColumns['docTypeId'])
      columns.push({
        id: 'docTypeId',
        Header: t('home:table.docType'),
        accessor: 'docTypeId',
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ maxWidth: '50%' }} />;
          }
          let subType = null;
          const locker = checkLocker(val.row.original.id);
          const docTypeId = val.value;
          if (val.row.original.docSubtypeId) {
            const subTypeName = typeMap.normal.get(`${val.value}-${val.row.original.docSubtypeId}`);
            if (subTypeName !== '<no subtype>') subType = subTypeName;
          }
          return docTypeId ? (
            <div
              style={
                locker
                  ? { opacity: 0.5, maxHeight: 43, overflow: 'hidden' }
                  : {
                      maxHeight: 43,
                      overflow: 'hidden',
                    }
              }
            >
              {typeMap.normal.get(docTypeId)}
              {subType && `/${subType}`}
            </div>
          ) : (
            <div />
          );
        },
        disableSortBy: true,
      });

    if (enabledColumns['actor']) {
      columns.push({
        id: 'actor',
        Header: t('home:table.approved'),
        accessor: 'action',
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ maxWidth: '50%' }} />;
          }
          const locker = checkLocker(val.row.original.id);
          const editor = val.value?.actorEmail;
          return editor ? (
            <div className={s.label_wrapper} style={locker ? { opacity: 0.5 } : {}}>
              {editor}
            </div>
          ) : (
            <div />
          );
        },
      });
    }
    if (enabledColumns['actionDate']) {
      columns.push({
        id: 'actionDate',
        Header: t('home:table.approvalDate'),
        accessor: 'action.timestamp',
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ maxWidth: '50%' }} />;
          }
          const locker = checkLocker(val.row.original.id);
          const editor = val.value?.toLocaleString();
          return editor ? (
            <div className={s.label_wrapper} style={locker ? { opacity: 0.5 } : {}}>
              {editor}
            </div>
          ) : (
            <div />
          );
        },
      });
    }
    if (enabledColumns['lastUserUpdate']) {
      columns.push({
        id: 'lastUserUpdate',
        Header: t('home:table.lastEdited'),
        accessor: 'lastUserUpdateTime',
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ maxWidth: '50%' }} />;
          }
          const locker = checkLocker(val.row.original.id);
          const editor = getUserName(val.row.original.lastUserUpdateBy);
          return val.value && editor ? (
            <div className={s.label_wrapper} style={locker ? { opacity: 0.5 } : {}}>
              <>
                {new Date(val.value).toLocaleString('nl-BE', {
                  month: 'short',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                <Tooltip lightTheme content={editor}>
                  <div>
                    <InfoIcon className={s.info_icon} />
                  </div>
                </Tooltip>
              </>
            </div>
          ) : (
            <div />
          );
        },
      });
    }
    if (tagTypes && enabledColumns['tagTypeId']) {
      columns.push({
        id: 'tagTypeId',
        Header: t('home:table.state'),
        accessor: 'tagTypeId',
        disableSortBy: activeTagId != null,
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ width: 75 }} />;
          }

          if (val.value) {
            const mapped = tagTypes.find((st) => st.id === val.value);
            const color = mapped?.color ?? '#EEEEEE';
            const bgColor = hexToRgb(color);
            return (
              <div className={s.label_wrapper}>
                <div
                  style={{
                    backgroundColor: `rgba(${bgColor},0.1)`,
                    color: color,
                  }}
                  className={s.label}
                >
                  {mapped?.name}
                </div>
              </div>
            );
          } else {
            return null;
          }
        },
      });
    }

    if (enabledColumns['actionType']) {
      columns.push({
        id: 'actionType',
        Header: t('home:table.actionType'),
        accessor: 'action.type',
        disableSortBy: true,
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ width: 75 }} />;
          }

          if (val.value) {
            let color = '#EEEEEE';

            if (val.value === 'approve') color = '#0085FF';
            if (val.value === 'delete') color = '#FF5555';
            if (val.value === 'bounce') color = '#FCBF19';
            const bgColor = hexToRgb(color);
            return (
              <div className={s.label_wrapper}>
                <div
                  style={{
                    backgroundColor: `rgba(${bgColor},0.1)`,
                    color: color,
                  }}
                  className={s.label}
                >
                  {t('document:actions.' + val.value)}
                </div>
              </div>
            );
          } else {
            return null;
          }
        },
      });
    }

    if (enabledColumns['confidence'])
      columns.push({
        id: 'confidence',
        Header: t('home:table.confidence') + ' (%)',
        accessor: 'confidence',
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ width: 25 }} />;
          }
          const locker = checkLocker(val.row.original.id);
          const docTypeId = val.row.original?.docTypeId;

          return val.value ? (
            <div
              style={locker ? { opacity: 0.5 } : {}}
              className={`${val.value >= getThreshold(docTypeId) ? s.confidence__high : s.confidence__low}`}
            >
              {(Math.floor(val.value * 1000.0) / 10).toFixed(1)}
            </div>
          ) : (
            <div />
          );
        },
      });

    if (enabledColumns['approvalChecks'])
      columns.push({
        Header: '',
        accessor: 'approval_checks',
        disableSortBy: true,
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ width: 25 }} />;
          }

          return (
            <>
              {val.row.original.approvalChecks && (
                <div className={s.checks}>
                  <ApprovalChecks document={val.row.original} position={'left'} />
                </div>
              )}
            </>
          );
        },
      });
    if (enabledColumns['initialApprovalChecks'])
      columns.push({
        Header: '',
        accessor: 'initial_approval_checks',
        disableSortBy: true,
        Cell: (val) => {
          if (loading) {
            return <div className={s.loading} style={{ width: 25 }} />;
          }

          return (
            <>
              {val.row.original.initialApprovalChecks && (
                <div className={s.checks}>
                  <ApprovalChecks isInitialChecks document={val.row.original} position={'left'} />
                </div>
              )}
            </>
          );
        },
      });

    return columns;
  }, [
    user,
    typeMap,
    enabledColumns,
    t,
    tagTypes,
    loading,
    checkLocker,
    handleRowCheck,
    getUserName,
    getThreshold,
    activeTagId,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: documentList || [],
      initialState: {
        pageIndex: currentPageIndex,
        pageSize: documentListOptions.pageSize,
        sortBy: documentListOptions.sortBy
          ? [
              {
                id: documentListOptions.sortBy,
                desc: documentListOptions.isSortDescending,
              },
            ]
          : [],
      },
      manualPagination: true,
      pageCount: pageCount,
      autoResetSortBy: false,
      autoResetPage: false,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // if (state?.back) return;
    if (sortBy && sortBy.length > 0) {
      dispatch(inboxSlice.actions.setCurrentPageIndex(0));
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          sortBy: sortBy[0].id === 'processed' ? 'confidence' : sortBy[0].id,
          isSortDescending: sortBy[0].desc,
          navDirection: null,
        })
      );
    } else {
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          sortBy: 'upload_time',
          isSortDescending: false,
          navDirection: null,
        })
      );
    }
  }, [dispatch, sortBy, state]);
  const makeStyle = (columnId: string, index) => {
    let style;
    if (columnId === 'locker') {
      style = {
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        color: 'rgba(0, 0, 0, 0.54)',
      };
    } else if (columnId === 'name') {
      style = { width: 'auto' };
    } else {
      style = { width: '12.5%' };
    }
    if (index === 0 && columnId !== 'locker') {
      style = { ...style, paddingLeft: 20 };
    }

    return style;
  };

  return (
    <>
      <InboxTableToolbar
        checkedRows={checkedRows}
        clearRows={() => {
          handleClearAllChecks();
        }}
      />
      <div className={s.table_wrapper}>
        <table className={s.table} {...getTableProps()} data-testid="inbox-table">
          <thead className={s.thead}>
            {headerGroups.map((headerGroup) => (
              <tr className={s.head} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const hasLockerCol = headerGroup.headers.find((e) => e.id === 'locker');
                  const isFirstRealCol = index == (hasLockerCol != null ? 1 : 0);
                  return (
                    <th
                      data-testid={'inbox-table-th'}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        ...makeStyle(column.id, index),
                        ...column.getHeaderProps(column.getSortByToggleProps()).style,
                      }}
                      className={s.cell}
                      align={isFirstRealCol ? 'left' : 'center'}
                    >
                      <div
                        className={clsx(s.sort_head, {
                          [s.sort_head__start]: isFirstRealCol,
                        })}
                      >
                        {column.id === 'locker' ? (
                          <Checkbox
                            indeterminate={
                              checkedRows?.length !== documentList?.length && checkedRows.length > 0
                            }
                            onClick={handleCheckAll}
                            checked={checkedRows?.length === documentList?.length}
                          />
                        ) : (
                          column.render('Header')
                        )}
                        <span>
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <ChevronUp className={s.sort_icon} />
                            ) : (
                              <ChevronDown className={s.sort_icon} />
                            ))}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const rowData = row.original as DocumentDetails;
              return (
                <InboxTableLockableRow
                  loading={loading}
                  setIsRowChecked={(rowData) => handleRowCheck(rowData)}
                  key={row.id}
                  rowData={rowData}
                  isRowChecked={checkedRows.findIndex((r) => r === row.original) !== -1}
                  row={row}
                  goToDocument={goToDocument}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {/*{(!documentList || documentList.length === 0) && !loading && (*/}
      {/*  <div className={s.empty} data-testid="inbox-table-empty">*/}
      {/*    <DocumentLargeIcon />*/}
      {/*    <p className={s.empty_head}>Oops!</p>*/}
      {/*    <p className={s.empty_text}>*/}
      {/*      Looks like you don't have any <br />*/}
      {/*      documents {activeDocType.docTypeId !== '' && 'of this type'} yet!*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};

export default InboxTable;
