import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as CheckmarkIcon } from '../../../shared/assets/svg/checkmark-icon.svg';
import { ReactComponent as CopyIcon } from '../../../shared/assets/svg/copy-icon.svg';
import { sleep } from '../../../shared/helpers/helpers.ts';
import s from '../../../shared/styles/component/admin/admin-section.module.scss';

interface Props {
  valueToCopy: string;
}

const AdminCopyButton: React.FC<Props> = ({ valueToCopy }) => {
  const ref = useRef();
  const [isClicked, setIsClicked] = useState(false);

  const handleCopyToClipboard = (value: string) => {
    setIsClicked(true);
    navigator.clipboard.writeText(value);
    sleep(2000).then(() => {
      setIsClicked(false);
    });
  };

  return (
    <button
      type={'button'}
      ref={ref}
      onClick={() => handleCopyToClipboard(valueToCopy)}
      className={clsx(s.copy_button, { [s.copy_button__clicked]: isClicked })}
    >
      <CopyIcon className={s.copy_button_icon} />
      <CheckmarkIcon className={s.copy_button_check} />
    </button>
  );
};

export default AdminCopyButton;
