import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useKeyPress } from '../../../../shared/helpers/helpers.ts';
import { useModal } from '../../../../shared/hooks/useModal.tsx';
import { UrlParams } from '../../../../shared/models/generic.ts';
import { useSelector } from '../../../../shared/store/store.ts';
import s from '../../../../shared/styles/component/header/header-pending.module.scss';
import FileUploadModal from '../../file-upload/FileUploadModal.tsx';
import { ReactComponent as UploadIcon } from '../../../../shared/assets/svg/upload-icon.svg';

const InboxUpload: React.FC = () => {
  const inboxSettings = useSelector((state) => state.inbox.currentInbox.settings);
  const loading = useSelector((state) => state.inbox.documentsLoading);
  const documentList = useSelector((state) => state.inbox.currentDocumentList);
  const pendingInboxDocuments = useSelector((state) => state.inbox.pendingInboxDocuments);
  const pendingRouterDocuments = useSelector((state) => state.inbox.pendingRouterDocuments);

  const { t } = useTranslation();
  const { inboxId }: UrlParams = useParams();
  const { showModal, closeModal } = useModal();

  const [buttonMouseDown, setButtonMouseDown] = useState(false);
  useKeyPress('Escape', closeModal, true, true);

  const total = useMemo(() => {
    const nr = pendingInboxDocuments?.length + pendingRouterDocuments?.length;
    if (nr > 99) return '99+';
    return nr;
  }, [pendingInboxDocuments, pendingRouterDocuments]);
  return (
    <div className={s.wrapper}>
      <div className={s.pending}>
        <span className={s.counter}>{total}</span>
        <span> {t('home:processing')}</span>
      </div>
      {inboxSettings.fileUpload && (
        <button
          onMouseDown={() => setButtonMouseDown(true)}
          onMouseUp={() => setButtonMouseDown(false)}
          onMouseLeave={() => setButtonMouseDown(false)}
          onClick={() => showModal(<FileUploadModal inboxId={inboxId} />)}
          disabled={!loading && !documentList}
          className={clsx(s.button, { [s.button__down]: buttonMouseDown })}
          data-testid="inbox-add-document-btn"
        >
          <UploadIcon /> {t('home:add')}
        </button>
      )}
    </div>
  );
};

export default InboxUpload;
