import { useMsal } from '@azure/msal-react';
import { RaceBy } from '@uiball/loaders';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  connectorClientToRaw,
  connectorRawToClient,
  IClientConnector,
  IClientConnectorBrio,
  IClientConnectorHTTP,
  IClientConnectorMS365,
  IClientConnectorSFTP,
} from '../../../../../shared/helpers/converters/connector.ts';
import { WebhookRow } from '../../../../../shared/helpers/converters/endpoint.ts';
import { compareObjects, getUserToken } from '../../../../../shared/helpers/helpers.ts';
import useChangeTracker, { ChangeSaveCallback } from '../../../../../shared/hooks/useChangeTracker.tsx';
import useConsole from '../../../../../shared/hooks/useConsole.tsx';
import { useModal } from '../../../../../shared/hooks/useModal.tsx';
import { deleteConnector, postConnector, putConnector } from '../../../../../shared/store/adminSlice.ts';
import { api } from '../../../../../shared/store/setup/firebase-setup.ts';
import { useSelector } from '../../../../../shared/store/store.ts';
import se from '../../../../../shared/styles/component/admin/admin-section.module.scss';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/ConfirmationDialog.tsx';
import { ReactComponent as CheckmarkIcon } from '../../../../../shared/assets/svg/checkmark-icon.svg';
import { ReactComponent as MicrosoftIcon } from '../../../../../shared/assets/svg/microsoft.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/svg/edit-icon.svg';
import FormInputField from '../../../components/form/FormInputField.tsx';
import FormSection from '../../../components/form/FormSection.tsx';
import AdminMS365EmailPicker from '../../../components/AdminMS365EmailPicker.tsx';
import FormBodyHeader from '../../../components/form/FormBodyHeader.tsx';
import FormRequestField from '../../../components/form/FormRequestField.tsx';
import AdminMS365UserRow from '../../../components/AdminMS365UserRow.tsx';

const AdminConnectorEdit: React.FC = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { showDialog } = useModal();
  const { connectorId } = useParams();
  const webhookServerSideValues = useSelector((state) => state.admin.webhookServerSideValues);
  const connectors = useSelector((state) => state.admin.connectors);
  const sortedConnectors = [...connectors].sort((a, b) => a.type.localeCompare(b.type));

  const navigate = useNavigate();

  const handleSave: ChangeSaveCallback<IClientConnector> = async (state: IClientConnector) => {
    const data = connectorClientToRaw(state);
    if (connectorId !== 'new') {
      return await putConnector(connectorId, data).then(() => {
        navigate(`/admin/connectors/${connectorId}`);
      });
    } else {
      return await postConnector(data).then(() => {
        navigate('/admin/connectors');
      });
    }
  };

  const [microsoftUsers, setMicrosoftUsers] = useState([]);

  const openAdminConsentPopup = async () => {
    if (import.meta.env.VITE_TEST) {
      return;
    } else {
      instance
        .loginPopup({
          popupWindowAttributes: {
            popupSize: { width: 600, height: 800 },
          },

          prompt: 'consent',
          scopes: ['https://graph.microsoft.com/.default'],
          state: window.location.origin,
        })
        .then((response) => {
          setState({ ...state, accessToken: response.accessToken } as IClientConnectorMS365);
          const data = connectorClientToRaw({
            ...state,
            accessToken: response.accessToken,
          } as IClientConnectorMS365);
          if (data.name === '') {
            data.name = 'New Connector';
          }

          postConnector(data).then((res) => {
            navigate(`/admin/connectors/${res['data']['connector_id']}`);
          });
        })
        .catch((error) => {
          console.error('Login error:', error);
        });
    }
  };

  const addRow = (type: 'headers' | 'payload' | 'params', isAuth = false) => {
    setState((prevState) => {
      const copy = cloneDeep(prevState);
      const target = isAuth ? copy['auth'] : copy;
      const item = target[type] ?? [];

      target[type] = [
        ...item,
        {
          key: '',
          tempLocked: false,
          locked: false,
          value: { content: '', type: 'string' },
        },
      ];
      return copy;
    });
  };

  const deleteRow = (index: number, type: 'headers' | 'payload' | 'params', isAuth = false) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      if (isAuth) {
        copy['auth'][type][index] = { ...copy['auth'][type][index], markedForDelete: true };
      } else {
        copy[type][index] = { ...copy[type][index], markedForDelete: true };
      }
      return copy;
    });
  };

  const editRow = (
    index: number,
    target: 'key' | 'value' | 'valueType' | 'lock' | 'all',
    value: any,
    type: 'headers' | 'payload' | 'params',
    isAuth = false
  ) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      const items = (isAuth ? copy['auth'] : copy)[type] ?? [];
      const item = items[index];
      switch (target) {
        case 'value':
          item.value.content = value;
          break;
        case 'key':
          value = value.replaceAll(' ', '-');
          if (item.error && item.error.key === target && value.length >= item.key.length) {
            return copy;
          }
          item.error = {};
          // Nested objects validation only when the type is 'payload'.
          if (type === 'payload' && value.includes('__')) {
            validateNestedObjects(items, value, item);
          }
          item.key = value;
          break;
        case 'valueType':
          item.value.type = value;
          item.tempLocked = value === '@PB_SECRET';
          if (value !== '@PB_SECRET') {
            item.locked = false;
          }
          break;
        case 'all':
          items[index] = value;
          break;
        default:
          item.tempLocked = value;
      }

      return copy;
    });
  };

  const validateNestedObjects = (items: any[], value: string, item: any) => {
    const activeItems = items.filter((e) => !e.markedForDelete);
    const currentKeyGroups: string[] = value.split(/__/g);
    const sameParentKeyItems = activeItems.filter((e) => e?.key.includes(currentKeyGroups[0]));

    sameParentKeyItems.forEach((sameParentItem) => {
      const parentItemGroups = sameParentItem.key.split(/__/g);
      if (parentItemGroups.length === currentKeyGroups.length - 1) {
        const hasDirectParent = isEqual(
          parentItemGroups,
          currentKeyGroups.slice(0, currentKeyGroups.length - 1)
        );
        if (hasDirectParent) {
          item.error = {
            key: 'key',
            msg: 'Cannot create nested object while parent is already defined',
          };
        }
      }
    });
  };

  const initialState: IClientConnector = useMemo(() => {
    const emptyRow: WebhookRow = {
      key: '',
      tempLocked: false,
      locked: false,
      value: { content: '', type: 'string' },
    };

    const empty: any = {
      id: '',
      baseUrl: '',
      name: '',
      type: 'http',
      payload: cloneDeep([emptyRow]),
      params: cloneDeep([emptyRow]),
      headers: cloneDeep([emptyRow]),
      auth: {
        active: false,
        url: '',
        tokenKey: '',
        params: [],
        payload: [],
        headers: [],
      },
    };

    if (connectorId === 'new' || !connectors || !webhookServerSideValues) {
      return empty;
    }

    const connector = connectors.find((e) => e.id === connectorId);
    if (!connector) return empty;

    return connectorRawToClient(connector, webhookServerSideValues);
  }, [connectorId, connectors, webhookServerSideValues]);

  useEffect(() => {
    if (initialState.type === 'ms365') {
      const func = async () => {
        const b = await getUserToken();
        const test = await api.get(
          `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/connectors/${connectorId}/ms365/users`,
          {
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              authorization: 'Bearer ' + b,
            },
            params: {
              select: 'displayName,mail,userPrincipalName,id',
            },
          }
        );
        const users = test.data['value']?.filter((e) => e.mail != null);
        setMicrosoftUsers(users);
      };
      if (connectorId) func();
    }
  }, [initialState, connectorId]);

  const handleInput = (value: any, field: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy[field] = value;
      return copy;
    });
  };

  const typeOptions = useMemo(
    () => [
      { value: 'http', label: 'HTTP' },
      { value: 'sftp', label: 'SFTP' },
      { value: 'portimabrio', label: 'Portima Brio' },
      { value: 'ms365', label: 'Microsoft 365 AD' },
    ],
    []
  );

  const dropdownDefault = useMemo(() => {
    return typeOptions?.find((e) => e.value === initialState?.type);
  }, [initialState, typeOptions]);

  const { state, setState, saving, save, hasChanges } = useChangeTracker<IClientConnector>(
    initialState,
    handleSave
  );
  const handleDelete = async () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          deleteConnector(connectorId).then(() => {
            const filtered = sortedConnectors.filter((e) => e.id !== connectorId);
            if (filtered && filtered.length > 0) {
              navigate(`/admin/connectors/${filtered[0].id}`);
            } else {
              navigate('/admin/connectors');
            }
          });
        }}
        text={t('admin:connectors.deleteDescription')}
      />
    );
  };
  const MS365Form = () => {
    const MS365State = state as IClientConnectorMS365;
    return (
      <>
        <FormSection title={t('admin:connectors.generalInfo')}>
          <FormInputField
            testId={'connector-name-input'}
            value={MS365State?.name}
            type={'text'}
            label={t('admin:connectors.name')}
            description={t('admin:connectors.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            placeholder={'Connector Name'}
          />
          <FormInputField
            testId={'connector-type-input'}
            disabled={connectorId !== 'new'}
            value={typeOptions?.find((e) => e.value === MS365State?.type)}
            label={t('admin:connectors.type')}
            description={t('admin:connectors.typeDescription')}
            type="dropdown"
            dropdownOptions={typeOptions}
            defaultDropdownOption={dropdownDefault}
            onChange={(val) => handleInput(val.value, 'type')}
          />

          <FormInputField
            testId={'connector-ms365-button'}
            value={null}
            label={t('admin:connectors.ms365.title')}
            type={'button'}
            disabled={!!MS365State.tenantId}
            description={t('admin:connectors.ms365.description')}
            buttonOptions={{
              type: 'normal',
              text: MS365State.accessToken ? (
                t('admin:connectors.ms365.connected')
              ) : MS365State.tenantName ? (
                <>
                  <CheckmarkIcon style={{ color: '#0085FF', height: 16 }} />{' '}
                  {t('admin:connectors.ms365.connectedTo')} {MS365State.tenantName}
                </>
              ) : (
                <>
                  <MicrosoftIcon style={{ width: 14 }} /> {t('admin:connectors.ms365.connectedToMS365')}
                </>
              ),
              onClick: openAdminConsentPopup,
            }}
          />
        </FormSection>
        <FormSection
          add={
            microsoftUsers?.length === 0
              ? null
              : {
                  onClick: () =>
                    showDialog(
                      <AdminMS365EmailPicker
                        microsoftUsers={microsoftUsers}
                        setUserMappings={(newState) =>
                          setState((state) => {
                            const clone = cloneDeep(state) as IClientConnectorMS365;
                            if (clone.inflow?.userMapping) {
                              clone.inflow.userMapping = newState.map((newUser) => {
                                const existing = clone.inflow.userMapping.find((u) => u.id === newUser.id);
                                if (existing) {
                                  return existing;
                                } else {
                                  return newUser;
                                }
                              });
                            } else {
                              clone.inflow = {
                                userMapping: newState,
                              };
                            }
                            return clone;
                          })
                        }
                        mappedUsers={MS365State.inflow?.userMapping}
                      />
                    ),
                  label: t('admin:connectors.ms365.editMailboxes'),
                  icon: <EditIcon />,
                }
          }
          noStyle
          hidden={MS365State.tenantName == null}
          title={t('admin:connectors.mailboxes')}
        >
          <div>
            {MS365State.tenantName &&
              MS365State?.inflow?.userMapping &&
              [...MS365State.inflow.userMapping]
                .sort((a, b) => a.id.localeCompare(b.id))
                .map((user) => {
                  return (
                    <AdminMS365UserRow
                      key={user.id}
                      microsoftUsers={microsoftUsers}
                      user={user}
                      setUser={(newState) => {
                        setState((state) => {
                          const clone = cloneDeep(state) as IClientConnectorMS365;
                          const updatedUser = newState;
                          if (clone.inflow?.userMapping) {
                            const index = clone.inflow.userMapping.findIndex((u) => u.id === user.id);
                            clone['inflow'].userMapping[index] = updatedUser;
                          } else {
                            clone['inflow'] = {
                              userMapping: [updatedUser],
                            };
                          }
                          if (Object.entries(compareObjects(state, clone)).length > 0) {
                            return clone;
                          }
                          return state;
                        });
                      }}
                    />
                  );
                })}
          </div>
        </FormSection>
      </>
    );
  };

  const BrioForm = () => {
    const BrioState = state as IClientConnectorBrio;
    const environementOptions = [
      { label: 'Production', value: 'production' },
      { label: 'Acceptance', value: 'acceptance' },
    ];
    // type: 'portimabrio';
    // pi2Key: string;
    // officeId: string;
    // subOfficeId: string;
    // environment: 'production' | 'acceptance';
    return (
      <>
        <FormSection title={t('admin:connectors.generalInfo')}>
          <FormInputField
            testId={'connector-name-input'}
            value={BrioState?.name}
            type={'text'}
            label={t('admin:connectors.name')}
            description={t('admin:connectors.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            placeholder={'Connector Name'}
          />
          <FormInputField
            testId={'connector-type-input'}
            disabled={connectorId !== 'new'}
            value={typeOptions?.find((e) => e.value === BrioState?.type)}
            label={t('admin:connectors.type')}
            description={t('admin:connectors.typeDescription')}
            type="dropdown"
            dropdownOptions={typeOptions}
            defaultDropdownOption={dropdownDefault}
            onChange={(val) => handleInput(val.value, 'type')}
          />
          <FormInputField
            testId={'connector-pi2Key-input'}
            value={BrioState?.pi2Key}
            type={'secret'}
            label={t('admin:connectors.pi2Key')}
            description={t('admin:connectors.pi2KeyDescription')}
            onChange={(val) => handleInput(val, 'pi2Key')}
            placeholder={'Pi2Key'}
          />

          <FormInputField
            testId={'connector-officeId-input'}
            value={BrioState?.officeId}
            type={'text'}
            label={t('admin:connectors.officeId')}
            description={t('admin:connectors.officeIdDescription')}
            onChange={(val) => handleInput(val, 'officeId')}
            placeholder={'Office Id'}
          />
          <FormInputField
            testId={'connector-subOfficeId-input'}
            value={BrioState?.subOfficeId}
            type={'text'}
            label={t('admin:connectors.subOfficeId')}
            description={t('admin:connectors.subOfficeIdDescription')}
            onChange={(val) => handleInput(val, 'subOfficeId')}
            placeholder={'Sub Office Id'}
          />
          <FormInputField
            testId={'connector-environment-input'}
            value={environementOptions.find((e) => e.value === BrioState?.environment)}
            type={'dropdown'}
            label={t('admin:connectors.environment')}
            description={t('admin:connectors.environmentDescription')}
            onChange={(val) => {
              if (val.value !== BrioState?.environment) {
                handleInput(val.value, 'environment');
              }
            }}
            dropdownOptions={environementOptions}
            placeholder={'Environment'}
          />
        </FormSection>
      </>
    );
  };

  const SFTPForm = () => {
    const SFTPState = state as IClientConnectorSFTP;
    return (
      <>
        <FormSection title={t('admin:connectors.generalInfo')}>
          <FormInputField
            required
            testId={'connector-name-input'}
            value={SFTPState?.name}
            type={'text'}
            label={t('admin:connectors.name')}
            description={t('admin:connectors.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            placeholder={'Connector Name'}
          />
          <FormInputField
            testId={'connector-type-input'}
            disabled={connectorId !== 'new'}
            value={typeOptions?.find((e) => e.value === SFTPState?.type)}
            label={t('admin:connectors.type')}
            description={t('admin:connectors.typeDescription')}
            type="dropdown"
            dropdownOptions={typeOptions}
            defaultDropdownOption={dropdownDefault}
            onChange={(val) => handleInput(val.value, 'type')}
          />
          <FormInputField
            required
            testId={'connector-ip-input'}
            value={SFTPState?.host}
            type={'ip'}
            label={t('admin:connectors.sftp.ip')}
            description={t('admin:connectors.sftp.ipDescription')}
            onChange={(val) => handleInput(val, 'host')}
            placeholder={'176.168.1.1'}
          />
          <FormInputField
            required
            testId={'connector-port-input'}
            value={SFTPState?.port}
            type={'number'}
            label={t('admin:connectors.sftp.port')}
            description={t('admin:connectors.sftp.portDescription')}
            onChange={(val) => handleInput(parseInt(val), 'port')}
            placeholder={'22'}
          />
          <FormInputField
            required
            testId={'connector-username-input'}
            value={SFTPState?.username}
            type={'text'}
            label={t('admin:connectors.sftp.username')}
            description={t('admin:connectors.sftp.usernameDescription')}
            onChange={(val) => handleInput(val, 'username')}
            placeholder={'username'}
          />
          <FormInputField
            testId={'connector-password-input'}
            disabled={SFTPState?.privateKey?.length > 0}
            value={SFTPState?.password}
            type={'secret'}
            label={t('admin:connectors.sftp.password')}
            description={t('admin:connectors.sftp.passwordDescription')}
            onChange={(val) => handleInput(val, 'password')}
            placeholder={'password'}
          />
          <FormInputField
            testId={'connector-private-key-input'}
            disabled={SFTPState?.password?.length > 0}
            value={SFTPState?.privateKey}
            type={'secret'}
            label={t('admin:connectors.sftp.privateKey')}
            description={t('admin:connectors.sftp.privateKeyDescription')}
            onChange={(val) => handleInput(val, 'privateKey')}
            placeholder={'your-private-key'}
          />
        </FormSection>
      </>
    );
  };

  const HTTPForm = () => {
    const HTTPState = state as IClientConnectorHTTP;
    return (
      <>
        <FormSection title={t('admin:connectors.generalInfo')}>
          <FormInputField
            testId={'connector-name-input'}
            value={HTTPState?.name}
            type={'text'}
            label={t('admin:connectors.name')}
            description={t('admin:connectors.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            placeholder={'Connector Name'}
          />
          <FormInputField
            testId={'connector-type-input'}
            disabled={connectorId !== 'new'}
            value={typeOptions?.find((e) => e.value === HTTPState?.type)}
            label={t('admin:connectors.type')}
            description={t('admin:connectors.typeDescription')}
            type="dropdown"
            dropdownOptions={typeOptions ?? []}
            defaultDropdownOption={dropdownDefault}
            onChange={(val) => handleInput(val.value, 'type')}
          />
          <FormInputField
            testId={'connector-base-url-input'}
            value={HTTPState?.baseUrl}
            type={'url'}
            required
            label={t('admin:connectors.url')}
            description={t('admin:connectors.urlDescription')}
            onChange={(val) => handleInput(val, 'baseUrl')}
            placeholder={'https://connector-url.io'}
          />
        </FormSection>
        <FormSection title={t('admin:connectors.dataConfig')}>
          <FormRequestField
            testId={'query'}
            editRow={(index, t, v) => editRow(index, t, v, 'params', false)}
            description={t('admin:connectors.queryParamsDescription')}
            label={t('admin:connectors.queryParams')}
            deleteRow={(index) => deleteRow(index, 'params', false)}
            addRow={() => addRow('params', false)}
            items={HTTPState?.params}
          />
          <FormRequestField
            testId={'headers'}
            editRow={(index, t, v) => editRow(index, t, v, 'headers', false)}
            description={t('admin:connectors.headersDescription')}
            label={t('admin:connectors.headers')}
            deleteRow={(index) => deleteRow(index, 'headers', false)}
            addRow={() => addRow('headers', false)}
            items={HTTPState?.headers}
          />
        </FormSection>
        <FormSection title={t('admin:connectors.auth.title')}>
          <FormInputField
            label={t('admin:connectors.auth.enabled')}
            description={t('admin:connectors.auth.enabledDescription')}
            value={HTTPState?.auth?.active}
            type={'toggle'}
            onChange={() =>
              setState((fs) => {
                const copy = cloneDeep(fs) as IClientConnectorHTTP;
                copy.auth.active = !copy.auth.active;
                return copy;
              })
            }
          />
          <FormInputField
            label={t('admin:connectors.auth.url')}
            description={t('admin:connectors.auth.urlDescription')}
            value={HTTPState?.auth?.url}
            type={'url'}
            layout={'horizontal'}
            required
            placeholder={'https://example.com/api/auth'}
            hidden={!HTTPState?.auth?.active}
            onChange={(val) =>
              setState((fs) => {
                const copy = cloneDeep(fs) as IClientConnectorHTTP;
                copy.auth.url = val;
                return copy;
              })
            }
          />
          <FormInputField
            label={t('admin:connectors.auth.responseTokenKey')}
            value={HTTPState?.auth?.tokenKey}
            layout={'horizontal'}
            required
            hidden={!HTTPState?.auth?.active}
            type={'text'}
            description={t('admin:connectors.auth.responseTokenKeyDescription')}
            placeholder={'auth-token'}
            onChange={(val) =>
              setState((fs) => {
                const copy = cloneDeep(fs) as IClientConnectorHTTP;
                copy.auth.tokenKey = val;
                return copy;
              })
            }
          />

          <FormRequestField
            testId={'auth-query'}
            hidden={!HTTPState?.auth?.active}
            editRow={(index, t, v) => editRow(index, t, v, 'params', true)}
            description={t('admin:connectors.auth.queryParamsDescription')}
            label={t('admin:connectors.auth.queryParams')}
            deleteRow={(index) => deleteRow(index, 'params', true)}
            addRow={() => addRow('params', true)}
            items={HTTPState?.auth?.params}
          />
          <FormRequestField
            testId={'auth-headers'}
            hidden={!HTTPState?.auth?.active}
            editRow={(index, t, v) => editRow(index, t, v, 'headers', true)}
            description={t('admin:connectors.auth.headersDescription')}
            label={t('admin:connectors.auth.headers')}
            deleteRow={(index) => deleteRow(index, 'headers', true)}
            addRow={() => addRow('headers', true)}
            items={HTTPState?.auth?.headers}
          />
          <FormRequestField
            testId={'auth-payload'}
            hidden={!HTTPState?.auth?.active}
            editRow={(index, t, v) => editRow(index, t, v, 'payload', true)}
            description={t('admin:connectors.auth.payloadDescription')}
            label={t('admin:connectors.auth.payload')}
            deleteRow={(index) => deleteRow(index, 'payload', true)}
            addRow={() => addRow('payload', true)}
            items={HTTPState?.auth?.payload}
          />
        </FormSection>
      </>
    );
  };
  useConsole(state, 'state');
  if (connectorId !== 'new' && (state.id === '' || state.id === null || state === null))
    return (
      <div className={se.loading}>
        <RaceBy color={'#0085FF'} size={150} />
      </div>
    );
  return (
    <form onSubmit={save} className={se.form_body}>
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        title={connectorId !== 'new' ? initialState?.name : t('admin:connectors.newConnector')}
      />

      <div className={se.sections}>
        {state?.type === 'http' && HTTPForm()}
        {state?.type === 'sftp' && SFTPForm()}
        {state?.type === 'ms365' && MS365Form()}
        {state?.type === 'portimabrio' && BrioForm()}
        <div>
          {connectorId !== 'new' && (
            <>
              <FormSection hidden={connectorId === 'new'} title={t('admin:connectors.dangerZone')}>
                <FormInputField
                  testId={'connector-delete'}
                  type={'button'}
                  buttonOptions={{
                    type: 'error',
                    text: t('admin:connectors.delete'),
                    onClick: handleDelete,
                  }}
                  label={t('admin:connectors.delete')}
                  description={t('admin:connectors.deleteDescription')}
                />
              </FormSection>
            </>
          )}
        </div>
      </div>
    </form>
  );
};
export default AdminConnectorEdit;
