import React, { CSSProperties } from 'react';
import s from './scale-loader.module.scss';

interface Props {
  height?: number;
  width?: number;
  css?: CSSProperties;
}

const ScaleLoader: React.FC<Props> = ({ height, width, css }) => {
  return (
    <div
      className={s.spinner}
      style={
        {
          ...css,
          '--scale-height': `${height ? height : 14}px`,
          '--scale-width': `${width ? width : 5}px`,
        } as React.CSSProperties
      }
    >
      <div className={s.rect1} />
      <div className={s.rect2} />
      <div className={s.rect3} />
      <div className={s.rect4} />
      <div className={s.rect5} />
    </div>
  );
};

export default ScaleLoader;
