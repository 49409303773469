import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IClientInbox } from '../../../shared/helpers/converters/inbox.ts';
import { useModal } from '../../../shared/hooks/useModal';
import useOutsideClick from '../../../shared/hooks/useOutsideClick';
import { patchUser } from '../../../shared/store/adminSlice';
import { useDispatch } from '../../../shared/store/store';
import s from '../../../shared/styles/component/admin/admin-user-dropdown.module.scss';
import AdminMultiSelectDialog from './AdminMultiSelectDialog';

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  inboxes: IClientInbox[];
  userId: string;
}

const AdminUserDropdown: React.FC<Props> = ({ inboxes, selectedIds, setSelectedIds, userId }) => {
  const dispatch = useDispatch();

  const ref = useRef(null);
  const outsideRef = useRef(null);
  useOutsideClick(outsideRef, () => setIsOpen(false));

  const { t } = useTranslation();
  const { showDialog } = useModal();
  const [isOpen, setIsOpen] = useState(false);

  const mapped = useMemo(() => {
    return inboxes.map((inbox) => {
      return { name: inbox.settings.name, id: inbox.id };
    });
  }, [inboxes]);

  const handleChange = (selected: any[]) => {
    dispatch(patchUser(userId, { inboxes: selected.map((e) => e.id) }));
    setSelectedIds(selected);
  };

  return (
    <div data-testid={'admin-user-dropdown'} className={s.dropdown_wrapper} ref={outsideRef}>
      <div
        onClick={() =>
          showDialog(
            <AdminMultiSelectDialog
              title={t('admin:users.inboxes.addTitle')}
              description={t('admin:users.inboxes.addDescription')}
              selectedTypes={selectedIds?.map((e) => ({ id: e }))}
              handleCheckTypes={handleChange}
              detailedList={mapped}
            />
          )
        }
        ref={ref}
        className={s.container}
      >
        <div style={isOpen ? { zIndex: 10 } : {}} className={s.circle}>
          {selectedIds ? selectedIds.length : 0}
        </div>

        <div style={isOpen ? { zIndex: 9 } : {}} className={clsx(s.dropdown)}>
          {t('admin:users.inboxes.title')}
        </div>
      </div>
    </div>
  );
};

export default AdminUserDropdown;
