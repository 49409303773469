import { cloneDeep, isEqual } from 'lodash';
import { WebhookRow } from '../../../shared/helpers/converters/endpoint.ts';

export const flattenObj = (ob) => {
  const result = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i]) && !ob[i]['.psv']) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + '__' + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};
export function combineObjects(objects) {
  if (objects.length === 0) return {};
  if (objects.length === 1) return objects[0];

  const combinedObject = {};
  const referenceObject = objects[0];

  // Iterate over each key in the reference object
  for (const key of Object.keys(referenceObject)) {
    let allMatch = true;

    // Compare the reference value with the corresponding value in each object.
    for (const obj of objects) {
      if (!isEqual(obj[key], referenceObject[key])) {
        allMatch = false;
        break;
      }
    }
    // If all values match for the current key, add it to the combined object.
    if (allMatch) {
      combinedObject[key] = referenceObject[key];
    }
  }

  return combinedObject;
}

export const unFlattenRows = (data) => {
  const result = {};
  for (const i in data) {
    const keys = i.split('__');
    keys.reduce(function (r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : []);
    }, result);
  }
  return result;
};
const mappedValue = (value: string, serverSideValues: any[]) => {
  if (serverSideValues) {
    const res = serverSideValues.find((e) => e.id === value);
    return res?.name ?? value;
  }
  return '';
};

const mapSingleToRow = (item: any, value: any, serverSideValues: any[]) => {
  if (value['.psv']) {
    item['value'] = {
      content: value['.psv'] === '@PB_SECRET' ? 'existingSecret' : '',
      type: value['.psv'],
    };
    item['locked'] = value['.psv'] === mappedValue('@PB_SECRET', serverSideValues);
  } else {
    item['value'] = { content: value, type: 'string' };
  }
  return item;
};

export const mapObjToRow = (obj: Record<string, any>, serverSideValues: any[], unFlatten = true) => {
  let list = [];
  Object.entries(obj).forEach(([k, v]) => {
    if (unFlatten && !v['.psv']) {
      let item: any = { key: k, tempLocked: false };
      if (typeof v === 'string') {
        item = mapSingleToRow(item, v, serverSideValues);
        list.push(item);
      } else {
        const subList = [];
        const flattenedObj = flattenObj(v);
        Object.entries(flattenedObj).forEach(([nk, nv]) => {
          let subItem = { key: `${k}__${nk}`, tempLocked: false };
          subItem = mapSingleToRow(subItem, nv, serverSideValues);
          subList.push(subItem);
        });
        list = [...list, ...subList];
      }
    } else {
      let item: any = { key: k, tempLocked: false };
      item = mapSingleToRow(item, v, serverSideValues);
      list.push(item);
    }
  });

  return list.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
};

export const mapRowsToObj = (rows: WebhookRow[], unFlatten = false) => {
  const mappedParams = {};
  let workableRows = cloneDeep(rows) ?? [];
  workableRows = workableRows.filter((e) => !e.markedForDelete);
  workableRows.forEach((param) => {
    if (param.skip) return;
    let payload;
    if (param.value.type !== 'string') {
      if (param.value.type === '@PB_SECRET' && param.value.content !== 'existingSecret') {
        payload = { '.psv': param.value.type, secret: param.value.content };
      } else {
        payload = { '.psv': param.value.type };
      }
    } else {
      payload = param.value.content;
    }
    if (param.key !== '') {
      if (param.markedForDelete) {
        mappedParams[param.key] = null;
      } else {
        mappedParams[param.key] = payload;
      }
    }
  });
  if (unFlatten) return unFlattenRows(mappedParams);
  return mappedParams;
};
