import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../../shared/assets/svg/arrow-back-icon.svg';
import p from '../../../../shared/styles/component/admin/admin-pages/admin-page.module.scss';

interface Props {
  onClick: () => void;
  label: string;
  navOptions?: {
    prev?: {
      label: string;
      onClick: () => void;
      active?: boolean;
    };
    next?: {
      label: string;
      onClick: () => void;
      active?: boolean;
    };
  };
}

export const FormHeaderNav: React.FC<Props> = ({ onClick, label, navOptions }) => {
  return (
    <div className={p.header_nav}>
      <div className={p.header_nav_item} onClick={onClick}>
        <ArrowLeftIcon /> <span> {label} </span>
      </div>
      {navOptions && (
        <div className={p.header_nav_group}>
          {navOptions.prev && (
            <button
              disabled={!navOptions?.prev?.active}
              onClick={(e) => {
                e.preventDefault();
                navOptions.prev?.onClick();
              }}
              className={p.header_nav_item}
            >
              <ArrowLeftIcon />
              {navOptions?.prev?.label}
            </button>
          )}
          {navOptions.next && (
            <button
              disabled={!navOptions?.next?.active}
              onClick={(e) => {
                e.preventDefault();
                navOptions.next?.onClick();
              }}
              className={p.header_nav_item}
            >
              {navOptions?.next?.label}

              <ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
