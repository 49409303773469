export interface IRawMetadata {
  name: string;
  id?: string;
  is_sensitive: boolean;
  provider_id?: string;
  is_archived?: boolean;
  is_hidden?: boolean;
  use_topology_parts_to_aggregate?: boolean;
  options?: { value: string }[];
}
export interface IClientMetadata {
  name: string;
  id: string;
  isSensitive: boolean;
  providerId?: string;
  isArchived?: boolean;
  isHidden?: boolean;
  useTopologyPartsToAggregate?: boolean;
  options?: { value: string }[];
}

export function metadataRawToClient(rawMetadata: IRawMetadata): IClientMetadata {
  const item: IClientMetadata = {
    name: rawMetadata.name,
    id: rawMetadata.id,
    isSensitive: rawMetadata.is_sensitive,
    useTopologyPartsToAggregate: true,
  };

  if (rawMetadata.provider_id) item.providerId = rawMetadata.provider_id;
  if (rawMetadata.is_archived) item.isArchived = rawMetadata.is_archived;
  if (rawMetadata.is_hidden) item.isHidden = rawMetadata.is_hidden;
  if (rawMetadata.use_topology_parts_to_aggregate != null)
    item.useTopologyPartsToAggregate = rawMetadata.use_topology_parts_to_aggregate;
  if (rawMetadata.options) item.options = rawMetadata.options;

  return item;
}

export function metadataClientToRaw(client: IClientMetadata): IRawMetadata {
  const item: IRawMetadata = {
    name: client.name,
    is_sensitive: client.isSensitive,
    options: client.options,
    use_topology_parts_to_aggregate: client.useTopologyPartsToAggregate,
  };
  item.provider_id = client.providerId;
  if (client.isArchived) item.is_archived = client.isArchived;
  if (client.isHidden) item.is_hidden = client.isHidden;

  return item;
}
