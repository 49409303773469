import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import s from '../../../shared/styles/component/inbox/inbox-sidebar.module.scss';

interface Props {
  path: string;
  icon: ReactElement;
  title: string;
  testId?: string;
  onClick?: () => void;
}
const InboxSidebarNavItem: React.FC<Props> = ({ path, icon, title, onClick, testId }) => {
  return (
    <NavLink
      end
      to={path}
      className={({ isActive }) => clsx(s.item_wrapper, { [s.item__active]: isActive })}
      tabIndex={0}
      data-testid={testId ?? 'sidebar-nav-item'}
      onClick={onClick}
    >
      <div className={s.item}>
        {React.cloneElement(icon, {
          className: clsx(s.item_icon),
        })}
        <div className={s.item_text}>{title}</div>
      </div>
    </NavLink>
  );
};

export default InboxSidebarNavItem;
