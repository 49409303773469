import React, { CSSProperties, useEffect, useState } from 'react';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { ReactComponent as DragHandleDots } from '../../../shared/assets/svg/drag-handle-dots.svg';
import { IClientFieldType } from '../../../shared/helpers/converters/fieldtype.ts';
import { AdminFlatDocTypeCategory } from '../../../shared/models/admin';
import s from '../../../shared/styles/component/admin/admin.module.scss';
import AdminDocTypeCategory from './AdminDocTypeCategory';

interface Props {
  items: AdminFlatDocTypeCategory[];
  handleDeleteCategory: (categoryId: string) => void;
  handleUpdateCategory: (categoryId: string, category: AdminFlatDocTypeCategory) => void;
  entityOptionsList: IClientFieldType[];
  setCategories: (items: AdminFlatDocTypeCategory[]) => void;
}

const AdminSortableCategories: React.FC<Props> = ({
  items,
  setCategories,
  handleDeleteCategory,
  handleUpdateCategory,
  entityOptionsList,
}) => {
  const [workableCategories, setWorkableCategories] = useState(items);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      if (!over) return;
      const oldIndex = workableCategories.findIndex((e) => e.id === active.id);
      const newIndex = workableCategories.findIndex((e) => e.id === over.id);
      const sorted = arrayMove(workableCategories, oldIndex, newIndex);
      setCategories(sorted);
      setWorkableCategories(sorted);
    }
  };

  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    if (items) setWorkableCategories(items);
  }, [items]);

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={workableCategories} strategy={verticalListSortingStrategy}>
        {workableCategories.map((category) => {
          return (
            <AdminSortableCategory
              key={category?.id}
              category={category}
              handleDeleteCategory={handleDeleteCategory}
              handleUpdateCategory={handleUpdateCategory}
              entityOptionsList={entityOptionsList}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

export default AdminSortableCategories;

interface ItemProps {
  category: AdminFlatDocTypeCategory;
  handleDeleteCategory: (categoryId: string) => void;
  handleUpdateCategory: (categoryId: string, category: AdminFlatDocTypeCategory) => void;
  entityOptionsList: IClientFieldType[];
}

const AdminSortableCategory: React.FC<ItemProps> = ({
  category,
  handleDeleteCategory,
  handleUpdateCategory,
  entityOptionsList,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: category.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    background: isDragging ? '#f5f7fa' : '',
    zIndex: isDragging ? 100 : 10,
    transition: transition,
  } as CSSProperties;

  return (
    <div
      data-testid={'doctype-category-section'}
      className={clsx(s.segment_category, { [s.segment_category__dragging]: isDragging })}
      ref={setNodeRef}
      style={style}
    >
      <div
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        {...attributes}
        {...listeners}
      >
        <DragHandleDots className={s.drag_handle} />
      </div>
      <AdminDocTypeCategory
        category={category}
        handleDeleteCategory={handleDeleteCategory}
        handleUpdateCategory={handleUpdateCategory}
        entityOptionsList={entityOptionsList}
      />
    </div>
  );
};
