import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Pulsar } from '@uiball/loaders';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '../../../../../shared/assets/svg/cross-icon.svg';
import { ReactComponent as InfoIcon } from '../../../../../shared/assets/svg/info-icon.svg';
import { ReactComponent as SearchIcon } from '../../../../../shared/assets/svg/search-icon.svg';
import { sleep, uuid4hex } from '../../../../../shared/helpers/helpers';
import { DocumentEntity } from '../../../../../shared/models/document';
import { UrlParams } from '../../../../../shared/models/generic';
import {
  documentSlice,
  MasterDataSearchPayload,
  searchMasterData,
} from '../../../../../shared/store/documentSlice';
import { labelerSlice, unlinkEntities } from '../../../../../shared/store/labelerSlice';
import { useDispatch, useSelector } from '../../../../../shared/store/store';
import s from '../../../../../shared/styles/component/document/document-labeler-sidebar-search.module.scss';
import { DropdownOption } from '../../../../shared/dropdown/StyledSelect';
import Tooltip from '../../../../shared/tooltip/Tooltip';
import SearchRow from './SearchRow';

export type Field = {
  id: string;
  prompt: string;
  reference?: string;
  reference_type?: string;
  tableId?: string;
};
type FieldState = {
  fields?: Field[];
};

interface Props {
  droppedItem?: DocumentEntity;
  clearDroppedItem?: VoidFunction;
  isDragging: boolean;
  hasMasterdataLink?: boolean;
  selectedTableIds?: string[];
}

const Search: React.FC<Props> = ({
  droppedItem,
  clearDroppedItem,
  isDragging,
  hasMasterdataLink,
  selectedTableIds,
}) => {
  const externalSearchQueryItem = useSelector((state) => state.labeler.externalSearchQueryItem);
  const activeDocId = useSelector((state) => state.document.activeDocId);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const masterDataStatus = useSelector((state) => state.document.masterDataStatus);
  const isMasterDataSearchActive = useSelector((state) => state.document.isMasterDataSearchActive);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const masterDataResults = useSelector((state) => state.document.masterDataResults);
  const masterDataAvailableTypes = useSelector((state) => state.document.masterDataAvailableTypes);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const metadataTypes = useSelector((state) => state.settings.metadataTypes);

  const ref = useRef();
  const dispatch = useDispatch();
  const { inboxId }: UrlParams = useParams();
  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable',
  });

  const [fieldState, setFieldState] = useState<FieldState>({
    fields: [{ id: 'first', prompt: '', reference_type: 'global', reference: 'global' }],
  });
  const [analyticsOptions, setAnalyticsOptions] = useState<Record<string, string[]>>({});

  const { t } = useTranslation();

  const handleSearch = (e: React.FormEvent, currentFields?: Field[]) => {
    e?.preventDefault();
    let fields = fieldState.fields;
    if (currentFields) fields = currentFields;
    const payload: MasterDataSearchPayload = { prompt: null, fields: [], table_ids: selectedTableIds };
    const global = fields.find((e) => e.reference === 'global');
    if (global && global.prompt) {
      payload.prompt = global.prompt;
    }
    payload.fields = fields
      .filter((e) => e.reference !== 'global' && e.prompt !== '')
      .map((e) => {
        let clone: Field = { ...e };
        if (analyticsOptions[e.tableId]) {
          clone = { ...e, prompt: `"${e.prompt}"` };
        }

        delete clone.id;
        return clone;
      });
    if (payload.fields.length === 0 && !payload.prompt) return;
    dispatch(searchMasterData(payload, inboxId));
  };
  useEffect(() => {
    if (externalSearchQueryItem) {
      let list = [];
      list = fieldState.fields;
      const lastItem = list[list.length - 1];
      lastItem.prompt = externalSearchQueryItem.value;
      setFieldState({ fields: list });
      dispatch(labelerSlice.actions.setExternalSearchQueryItem(null));
      sleep(200).then(() => handleSearch(null, list));
    }
  }, [dispatch, externalSearchQueryItem, fieldState.fields, handleSearch]);

  const canAddQuery = useMemo(() => {
    return masterDataAvailableTypes.length !== 0;
  }, [masterDataAvailableTypes]);

  const handleChange = (field: Field) => {
    const clone = [...fieldState.fields];
    const existing = clone.findIndex((e) => e.id === field.id);
    clone[existing] = field;
    setFieldState({ fields: clone });
  };

  const handleAddQuery = () => {
    setFieldState({
      fields: [
        ...fieldState.fields,
        {
          id: uuid4hex(),
          prompt: '',
          reference_type: '',
          reference: '',
        },
      ],
    });
  };

  const handleRemoveQuery = (id: string) => {
    const clone = [...fieldState.fields];
    const existing = clone.findIndex((e) => e.id === id);
    clone.splice(existing, 1);
    setFieldState({ fields: clone });
  };

  useEffect(() => {
    setFieldState({
      fields: [{ id: 'first', prompt: '', reference_type: 'global', reference: 'global' }],
    });
  }, [activeDocId]);

  const hasExternalTable = useMemo(() => {
    if (masterDataMappings) return Object.values(masterDataMappings)?.some((el) => el.type === 'external');
    else return false;
  }, [masterDataMappings]);

  useEffect(() => {
    let filtered;
    let list: DropdownOption[] = [
      { label: t('document:masterdata.global'), value: { id: 'global', type: 'global' } },
    ];
    if (!masterDataMappings) return;
    const analyticsList = {};
    const analyticsFull = Object.values(masterDataMappings).reduce((res, el) => {
      if (el.analytics) {
        res.push(el.analytics);
      }
      return res;
    }, []);

    if (analyticsFull.length > 0) {
      const analytics = analyticsFull.flat();
      const analyticsLength = {};
      analytics.forEach((item) => {
        if (analyticsList[item.id]) {
          analyticsLength[item.id] = analyticsLength[item.id] + 1;
          analyticsList[item.id] = [...analyticsList[item.id], ...item.uniqueValues];
        } else {
          analyticsLength[item.id] = 1;
          analyticsList[item.id] = item.uniqueValues;
        }
      });
      const cleanedMap = {};
      Object.entries(analyticsList).forEach(([k, v]) => {
        if (analyticsLength[k] === Object.values(masterDataMappings).length) {
          cleanedMap[k] = v;
        }
      });
      setAnalyticsOptions(cleanedMap);
    }
    const merged = Object.values(masterDataMappings)
      .map((v) => {
        return v.mapping;
      })
      .flat();

    const spread = [...new Map([].concat(...merged).map((v) => [v.reference, v])).values()].filter(
      (e) => e.isSearchable !== false
    );

    if (entityTypes) {
      filtered = entityTypes.filter((e) =>
        spread.find((t) => t.reference === e.id && t.referenceType === 'entity_types' && !e.isArchived)
      );

      list = [
        ...list,
        ...filtered.map((e) => {
          return {
            label: e.name,
            value: { id: e.id, type: 'entity_types' },
          } as DropdownOption;
        }),
      ];
    }

    if (metadataTypes) {
      filtered = metadataTypes.filter((e) =>
        spread.find((t) => t.reference === e.id && t.referenceType === 'metadata_keys' && !e.isArchived)
      );
      list = [
        ...list,
        ...filtered.map((e) => {
          return {
            label: e.name,
            value: { id: e.id, type: 'metadata_keys' },
          } as DropdownOption;
        }),
      ];
    }
    if (fieldState.fields) {
      list = list.filter((li) => {
        return !fieldState.fields.find((e) => e.reference === li.value.id);
      });
    }
    dispatch(documentSlice.actions.setMasterDataAvailableTypes(list));
  }, [t, fieldState, metadataTypes, masterDataMappings, entityTypes, dispatch]);

  useEffect(() => {
    if (droppedItem) {
      let list: DropdownOption[] = [
        { label: t('document:masterdata.global'), value: { id: 'global', type: 'global' } },
      ];
      if (!masterDataMappings) return;
      const merged = Object.values(masterDataMappings)
        .map((v) => {
          return v.mapping;
        })
        .flat();
      const spread = [].concat(...merged);

      if (entityTypes) {
        const filtered = entityTypes.filter(
          (e) =>
            spread.find((t) => t.reference === e.id && t.referenceType === 'entity_types') && !e.isArchived
        );

        list = [
          ...list,
          ...filtered.map((e) => {
            return {
              label: e.name,
              value: { id: e.id, type: 'entity_types' },
            } as DropdownOption;
          }),
        ];
      }
      if (
        !fieldState.fields.find((li) => li.reference === droppedItem.type) &&
        canAddQuery &&
        list.find((li) => li.value.id === droppedItem.type)
      ) {
        setFieldState({
          fields: [
            ...fieldState.fields,
            {
              id: uuid4hex(),
              prompt: droppedItem.value.toString(),
              reference_type: 'entity_types',
              reference: droppedItem.type,
            },
          ],
        });
      }
      clearDroppedItem();
    }
  }, [t, canAddQuery, clearDroppedItem, droppedItem, entityTypes, fieldState, masterDataMappings]);

  const handleClearMasterdataInfo = () => {
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
    const filtered = documentEntities.filter((e) => e.source === 'masterdata');
    // const filteredMeta = activeDocument.metadata.provider.filter((e) => e.value.source === 'masterdata');
    dispatch(unlinkEntities(inboxId, filtered));
    // handleDeleteMetadata(filteredMeta);
  };

  useEffect(() => {
    if (isDragging) {
      dispatch(documentSlice.actions.setIsMasterDataSearchActive(true));
    }
  }, [dispatch, isDragging]);
  return (
    <div ref={setNodeRef}>
      {hasMasterdataLink && (
        <div className={s.container}>
          <div
            data-testid={'masterdata-clear'}
            onClick={handleClearMasterdataInfo}
            className={clsx(s.button)}
          >
            <span>{t('document:masterdata.clearAll')}</span>
            <Tooltip position={'bottom'} content={'This will remove all imported fields and metadata.'}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      {!hasMasterdataLink && !hasExternalTable && (
        <form ref={ref} onSubmit={handleSearch} className={s.container}>
          {fieldState.fields.map((field) => {
            return (
              <SearchRow
                onFocus={() => dispatch(documentSlice.actions.setIsMasterDataSearchActive(true))}
                key={field.id}
                fields={fieldState.fields}
                field={field}
                handleRemove={() => handleRemoveQuery(field.id)}
                handleChange={(f) => handleChange(f)}
                handleSearch={handleSearch}
                analyticsOptions={analyticsOptions}
              />
            );
          })}
          {masterDataStatus === 'no-results' ? (
            <div className={clsx(s.bottom, s.bottom__expanded)}>
              <div className={s.no_results}>{t('document:masterdata.noResults')}</div>
            </div>
          ) : (
            <div className={clsx(s.bottom, { [s.bottom__expanded]: isMasterDataSearchActive })}>
              {isDragging ? (
                <div
                  className={clsx(s.dropzone_wrapper, {
                    [s.dropzone_wrapper__active]: true,
                  })}
                >
                  <div
                    className={clsx(s.dropzone)}
                    style={{
                      background: isOver ? '#0085FF26' : 'white',
                      color: '#0085FF',
                    }}
                  >
                    Drop entity to add to search query
                  </div>
                </div>
              ) : (
                <>
                  {canAddQuery ? (
                    <div className={s.add_query} onClick={handleAddQuery}>
                      + {t('document:masterdata.addQuery')}
                    </div>
                  ) : (
                    <div className={s.add_query} />
                  )}
                  <div className={s.bottom_buttons}>
                    {masterDataResults && (
                      <button disabled type={'button'} className={s.search_button}>
                        <span>
                          {masterDataResults?.length} {t('document:masterdata.results')}
                        </span>
                      </button>
                    )}

                    <button
                      type={'button'}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(documentSlice.actions.setIsMasterDataSearchActive(false));
                        dispatch(documentSlice.actions.setMasterDataResults(null));
                        setFieldState({
                          fields: [
                            {
                              id: 'first',
                              prompt: '',
                              reference_type: 'global',
                              reference: 'global',
                            },
                          ],
                        });
                      }}
                      className={s.search_button}
                    >
                      <CrossIcon />
                      <span>{t('document:masterdata.clear')}</span>
                    </button>
                    <button
                      data-testid={'masterdata-search-button'}
                      onClick={handleSearch}
                      className={s.search_button}
                    >
                      {masterDataStatus === 'searching' ? (
                        <Pulsar color={'#0085FF'} size={17} />
                      ) : (
                        <SearchIcon />
                      )}
                      <span>{t('document:masterdata.search')}</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default Search;
