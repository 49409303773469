import React, { useMemo, useState } from 'react';
import { DotPulse } from '@uiball/loaders';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../../../shared/assets/svg/arrow-left-icon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../shared/assets/svg/check-circle.svg';
import { ReactComponent as TrashIcon } from '../../../../shared/assets/svg/trash-icon-alt.svg';
import { sleep } from '../../../../shared/helpers/helpers';
import { useModal } from '../../../../shared/hooks/useModal';
import { ExtendedTagType } from '../../../../shared/models/document';
import { UrlParams } from '../../../../shared/models/generic';
import documentSlice, {
  finalDocInStructureSelector,
  isInteractiveSelector,
} from '../../../../shared/store/documentSlice';
import { deleteDocument, patchDocument } from '../../../../shared/store/inboxSlice';
import { labelerSlice } from '../../../../shared/store/labelerSlice';
import { useDispatch, useSelector } from '../../../../shared/store/store';
import s from '../../../../shared/styles/component/document/document-labeler-sidebar-footer.module.scss';
import Button from '../../../shared/button/Button';
import ConfirmationDialog from '../../../shared/confirmation-dialog/ConfirmationDialog';
import DocumentTagDropdown from '../../header/DocumentTagDropdown';

interface Props {
  handlePrevious: () => void;
  handleNext: () => void;
}

const DocumentLabelerSideButtons: React.FC<Props> = ({ handlePrevious, handleNext }) => {
  const { docId, inboxId }: UrlParams = useParams();
  const navigate = useNavigate();
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const historical = location.pathname.includes('historical');

  const finalDocInStructure = useSelector(finalDocInStructureSelector);
  const autoAdvance = useSelector((state) => state.inbox.currentInbox.settings)?.autoAdvance ?? true;
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const prevDocumentId = useSelector((state) => state.document.prevDocumentId);
  const nextDocumentId = useSelector((state) => state.document.nextDocumentId);
  const selectedDocTypeOption = useSelector((state) => state.document.selectedDocTypeOption);
  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);
  const tagTypes = useSelector((state) => state.settings.tagTypes);
  const { email: userEmail, isHidden } = useSelector((state) => state.user.userAccount);

  const interactiveSel = useSelector(isInteractiveSelector);
  const isInteractive = useMemo(() => {
    if (historical) return true;
    return interactiveSel;
  }, [historical, interactiveSel]);

  const [approving, setApproving] = useState(false);

  const handleApprove = async () => {
    await dispatch(
      patchDocument(
        activeDocument.id,
        inboxId,
        {
          action: { actor_email: userEmail, type: 'approve' },
        },
        activeDocument.parentDocId ? docId != null : null
      )
    ).then((res: AxiosResponse) => {
      if (res?.request?.status === 204 && finalDocInStructure)
        if (nextDocumentId && autoAdvance) {
          dispatch(documentSlice.actions.setCopyStructure(null));
          dispatch(documentSlice.actions.setActiveDocument(null));
          handleNext();
        } else {
          dispatch(documentSlice.actions.setActiveDocument(null));
          dispatch(documentSlice.actions.setCopyStructure(null));
          navigate(`/inbox/${inboxId}`);
        }
    });
  };

  const handleValidation = async () => {
    if (!approving) {
      setApproving(true);
      await handleApprove();
      sleep(500).then(() => setApproving(false));
    }
  };

  const handleDeletion = () => {
    const text = t('document:deletion.original');

    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(deleteDocument(activeDocument.id, inboxId, false)).then(() => {
            if (finalDocInStructure)
              if (nextDocumentId) {
                dispatch(documentSlice.actions.setCopyStructure(null));
                dispatch(documentSlice.actions.setActiveDocument(null));
                handleNext();
              } else {
                dispatch(documentSlice.actions.setActiveDocument(null));
                dispatch(documentSlice.actions.setCopyStructure(null));
                navigate(`/inbox/${inboxId}`);
              }
          });
        }}
        text={text}
        title={t('document:deletion.deleteOriginal')}
      />
    );
  };

  const tagTypeColor = useMemo(() => {
    if (labelingMode && tagTypes) {
      const activeTagType = tagTypes.find((e) => e.id === activeDocument?.tagTypeId);
      if (activeTagType) {
        return activeTagType.color;
      }
      return '#313D4F';
    }
    return null;
  }, [activeDocument, labelingMode, tagTypes]);

  const allCopiesApproved = useMemo(() => {
    if (activeDocument) {
      if (activeDocument.nMutations) {
        return activeDocument?.nActiveMutations === 0;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [activeDocument]);

  const hasUnmetApprovalChecks = useMemo(() => {
    if (!activeDocument?.approvalChecks) return false;
    let unmet = false;
    activeDocument.approvalChecks.forEach((at) => {
      if (at.status === 'failed') unmet = true;
    });

    return unmet;
  }, [activeDocument]);

  const filteredTagTypes = useMemo(() => {
    if (labelingMode && documentEntities && tagTypes) {
      const unCheckedEnt = documentEntities.filter((e) => !e.isChecked).length;
      const clone = [...tagTypes] as ExtendedTagType[];
      const doneTypeIndex = tagTypes.findIndex((e) => e.id === 'DONE');
      if (unCheckedEnt !== 0) {
        clone[doneTypeIndex] = {
          ...clone[doneTypeIndex],
          isDisabled: true,
          disabledReason: t('document:labeling.labelAll'),
        };
      }
      if (hasUnmetApprovalChecks) {
        clone[doneTypeIndex] = {
          ...clone[doneTypeIndex],
          isDisabled: true,
        };
      }
      return clone;
    } else {
      return tagTypes;
    }
  }, [labelingMode, documentEntities, tagTypes, hasUnmetApprovalChecks, t]);

  const handleSelectTag = (tagId: string) => {
    dispatch(patchDocument(docId, inboxId, { tag_type_id: tagId }));
  };

  const handleChangeDocType = () => {
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
    dispatch(documentSlice.actions.setIsProcessing(true));
    dispatch(
      patchDocument(
        activeDocument.id,
        inboxId,
        {
          doc_type_id: selectedDocTypeOption.docTypeId,
          doc_subtype_id: selectedDocTypeOption.subTypeId ? selectedDocTypeOption.subTypeId : null,
        },
        false
      )
    );
  };
  const isWorkflowRunning = useMemo(() => {
    return activeDocument?.latestWorkflowRun?.status !== 'FINISHED';
  }, [activeDocument]);

  const buttonText = useMemo(() => {
    if (hasUnmetApprovalChecks) {
      return t('document:approveApprovalChecks');
    }

    if (!approving) {
      if (activeDocument) {
        if (activeDocument.nMutations > 0) {
          if (!allCopiesApproved) {
            return t('document:finishCopies');
          }
          return t('document:approveOriginal');
        } else if (activeDocument.parentDocId) {
          return t('document:approveCopy');
        } else {
          return t('document:approve');
        }
      } else {
        return t('document:approve');
      }
    } else {
      return t('document:approving') + '...';
    }
  }, [hasUnmetApprovalChecks, approving, t, activeDocument, allCopiesApproved]);

  return (
    <div className={s.buttons}>
      <button
        aria-label="nav-prev-doc"
        data-testid={'doc-prev'}
        style={{ background: tagTypeColor ?? null }}
        disabled={!prevDocumentId || !isInteractive}
        onClick={() => handlePrevious()}
        className={clsx(s.button_main, s.button_nav, s.button_nav__right, {
          [s.button__delete]: activeDocument?.docTypeId === '@PB_DELETE' && !selectedDocTypeOption,
        })}
      >
        <ArrowLeftIcon />
      </button>

      {historical && (
        <Button
          className={clsx(s.button_main, s.button_main__approve)}
          text={
            activeDocument
              ? t(`document:actions.${activeDocument?.action.type}`) +
                ' - ' +
                activeDocument?.action.actorEmail
              : ''
          }
        />
      )}

      {labelingMode && !historical && (
        <>
          <div className={s.button_main}>
            <DocumentTagDropdown
              activeTagId={activeDocument?.tagTypeId}
              className={s.button_tags}
              handleSelectTag={handleSelectTag}
              tagTypes={filteredTagTypes}
              position={'top'}
            />
          </div>
        </>
      )}
      {!labelingMode && !historical && (
        <>
          {activeDocument?.action || approving ? (
            <Button
              aria-label="approved-doc"
              disabled
              className={clsx(s.button_main)}
              text={t('document:approved')}
            />
          ) : (
            <>
              {activeDocument?.docTypeId === '@PB_DELETE' ? (
                selectedDocTypeOption ? (
                  <Button
                    disabled={!activeDocument?.processed || !isInteractive || isHidden}
                    aria-label="change-doc-type"
                    onClick={handleChangeDocType}
                    className={clsx(s.button_main, s.button_main__approve, {})}
                    text={t('document:changeType')}
                  />
                ) : (
                  <Button
                    disabled={!activeDocument?.processed || !isInteractive || isHidden}
                    aria-label="delete-doc"
                    onClick={handleDeletion}
                    className={clsx(s.button_main, s.button_main__delete)}
                    text={t('document:delete')}
                    iconLeft
                    iconElement={!approving ? <TrashIcon /> : null}
                  />
                )
              ) : (
                <Button
                  disabled={
                    !activeDocument?.processed ||
                    !isInteractive ||
                    !allCopiesApproved ||
                    hasUnmetApprovalChecks ||
                    isWorkflowRunning ||
                    isHidden
                  }
                  aria-label="approve-doc"
                  data-testid={'doc-approve'}
                  onClick={handleValidation}
                  className={clsx(s.button_main, s.button_main__validate)}
                  text={isWorkflowRunning ? <DotPulse size={25} speed={0.6} color="white" /> : buttonText}
                  iconLeft
                  iconElement={isWorkflowRunning ? null : !approving ? <CheckCircleIcon /> : null}
                />
              )}
            </>
          )}
        </>
      )}
      <button
        aria-label="nav-next-doc"
        data-testid={'doc-next'}
        style={{ background: tagTypeColor ?? null }}
        disabled={!nextDocumentId || !isInteractive}
        onClick={() => handleNext()}
        className={clsx(s.button_main, s.button_nav, s.button_nav__left, {
          [s.button__delete]: activeDocument?.docTypeId === '@PB_DELETE' && !selectedDocTypeOption,
        })}
      >
        <ArrowLeftIcon />
      </button>
    </div>
  );
};

export default DocumentLabelerSideButtons;
