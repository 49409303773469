import React from 'react';
import { ReactComponent as CheckmarkAltIcon } from '../../../shared/assets/svg/checkmark-alt-icon.svg';
import s from './checkbox.module.scss';

const Checkbox: React.FC<
  React.HTMLProps<HTMLInputElement> & { indeterminate?: boolean; testId?: string }
> = ({ checked, onClick, indeterminate, testId, ...rest }) => {
  return (
    <button type={'button'} className={s.container}>
      <input
        ref={(input) => {
          if (input && indeterminate != null) {
            input.indeterminate = indeterminate;
          }
        }}
        data-testid="checkbox-input"
        type="checkbox"
        className={s.hidden}
        onChange={() => null}
        checked={checked ?? false}
        {...rest}
      />
      <div
        data-testid={testId ?? 'checkbox'}
        onClick={(e) => {
          if (rest.disabled) return;
          e.stopPropagation();
          onClick(e as any);
        }}
        className={s.checkbox}
      />
      <CheckmarkAltIcon className={s.checkmark} />
      <div className={s.indeterminate} />
    </button>
  );
};

export default Checkbox;
