import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from '../../../shared/assets/svg/checkmark-icon.svg';
import { ReactComponent as EditIcon } from '../../../shared/assets/svg/edit-icon.svg';
import { ReactComponent as PlusIcon } from '../../../shared/assets/svg/plus-icon.svg';
import { ReactComponent as TrashIcon } from '../../../shared/assets/svg/trash-icon-alt.svg';
import { IClientFieldType } from '../../../shared/helpers/converters/fieldtype.ts';
import { useModal } from '../../../shared/hooks/useModal';
import { AdminFlatDocTypeCategory } from '../../../shared/models/admin';
import { DocTypeCategory } from '../../../shared/models/document';
import { useSelector } from '../../../shared/store/store';
import s from '../../../shared/styles/component/admin/admin-category.module.scss';
import si from '../../../shared/styles/component/admin/admin-section.module.scss';
import sr from '../../../shared/styles/component/admin/admin-item-row.module.scss';
import AdminMultiSelectDialog, { MultiSelectDetailsType } from './AdminMultiSelectDialog';
import AdminSortableList from './AdminSortableList';

interface Props {
  category: AdminFlatDocTypeCategory;
  handleDeleteCategory: (categoryId: string) => void;
  handleUpdateCategory: (categoryId: string, category: AdminFlatDocTypeCategory) => void;
  entityOptionsList: IClientFieldType[];
}

const AdminDocTypeCategory: React.FC<Props> = ({
  category,
  handleDeleteCategory,
  handleUpdateCategory,
  entityOptionsList,
}) => {
  const inboxEntityTypes = useSelector((state) => state.admin.inboxEntityTypes);

  const { showDialog } = useModal();
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState(category.name);

  const mappedCategory = useMemo(() => {
    if (inboxEntityTypes) {
      const types = category.entityTypes
        .map((et) => {
          return inboxEntityTypes.find((iet) => iet.id === et);
        })
        .filter((e) => e != null);

      return { ...category, entityTypes: types } as DocTypeCategory;
    }
  }, [category, inboxEntityTypes]);

  const handleRemoveEntityType = (entityTypeId: string) => {
    const editableCategory = { ...category };
    editableCategory.entityTypes = [...category.entityTypes].filter((e) => e !== entityTypeId);
    handleUpdateCategory(category.id, editableCategory);
  };
  const handleUpdateEntityList = (entityTypes) => {
    const editableCategory = { ...category };
    editableCategory.entityTypes = entityTypes.map((e) => {
      return e.id;
    });
    handleUpdateCategory(category.id, editableCategory);
  };

  const handleSetEntityList = (updatedList: string[]) => {
    const editableCategory = { ...category, entityTypes: updatedList };
    handleUpdateCategory(category.id, editableCategory);
  };

  const handleChangeName = () => {
    const editableCategory = { ...category };
    if (editableCategory.id === '_temp_') {
      editableCategory.id = categoryName
        .toUpperCase()
        .replace(/[^a-zA-Z 0-9_]+/g, '')
        .replace(' ', '_');
    }
    editableCategory.name = categoryName;
    handleUpdateCategory(category.id, editableCategory);
    setIsEditingName(false);
  };
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    if (category.id === '_temp_') {
      setIsEditingName(true);
    }
  }, [category]);

  return (
    <div className={s.container}>
      <div className={s.input_group}>
        {isEditingName ? (
          <>
            <div className={si.input_wrapper}>
              <input
                data-testid={'doctype-category-input'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleChangeName();
                  }
                }}
                autoFocus
                className={clsx(si.input, si.input__short)}
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
            <div className={s.icon} onClick={handleChangeName}>
              <CheckmarkIcon />
            </div>
          </>
        ) : (
          <>
            <span data-testid={'doctype-category-name'} className={s.name}>
              {categoryName}
            </span>
            <div className={clsx(s.icon__edit, s.icon)} onClick={() => setIsEditingName(true)}>
              <EditIcon />
            </div>
          </>
        )}
      </div>

      <div className={s.types}>
        {!(category.entityTypes.length >= entityOptionsList.length) && (
          <button
            data-testid={'doctype-category-field-add'}
            type="button"
            className={si.item_field}
            onClick={() =>
              showDialog(
                <AdminMultiSelectDialog
                  handleCheckTypes={(changes) => handleSetEntityList(changes.map((e) => e.id))}
                  detailedList={entityOptionsList as MultiSelectDetailsType[]}
                  selectedTypes={category.entityTypes.map((e) => ({ id: e }))}
                  title={t('admin:docType.categoriesEdit.addFieldTitle')}
                  description={t('admin:docType.categoriesEdit.addFieldDescription')}
                />
              )
            }
          >
            <PlusIcon className={si.field_add} />
          </button>
        )}

        <AdminSortableList
          small
          items={mappedCategory.entityTypes}
          setItems={handleUpdateEntityList}
          handleRemoveType={handleRemoveEntityType}
        />
      </div>
      <div className={clsx(sr.action, sr.trash)}>
        <TrashIcon onClick={() => handleDeleteCategory(category.id)} />
      </div>
    </div>
  );
};

export default AdminDocTypeCategory;
