import clsx from 'clsx';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { sleep } from '../../../../shared/helpers/helpers.ts';
import {
  adminSlice,
  getAdminActionTypesForInbox,
  getAdminApprovalChecksForInbox,
  getAdminDocTypesForInbox,
  getAdminEntityTypesForInbox,
  getAdminMetadataTypesForInbox,
  getAdminTagTypesForInbox,
  getMasterdataTablesForInbox,
} from '../../../../shared/store/adminSlice.ts';
import { unsubAdmin } from '../../../../shared/store/subsSlice.ts';
import s from '../../../../shared/styles/component/admin/admin.module.scss';
import { useDispatch, useSelector } from '../../../../shared/store/store';

const AdminInboxes: React.FC = () => {
  const inboxes = useSelector((state) => state.admin.inboxes);
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = useMemo(() => {
    const parts = location.pathname.split('/');
    const inboxIdIndex = parts.indexOf(inboxId);

    let currentTab = 'settings';
    if (inboxIdIndex !== -1 && inboxIdIndex + 1 < parts.length) {
      currentTab = parts[inboxIdIndex + 1];
    }
    return currentTab;
  }, [location, inboxId]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inboxId && inboxes && inboxes.length > 0) {
      navigate(`${inboxes[0].id}/settings`);
    }
  }, [inboxId, inboxes, navigate]);

  const inboxRef = useRef();

  useEffect(() => {
    if (inboxId) {
      dispatch(getAdminEntityTypesForInbox(inboxId));
      dispatch(getAdminDocTypesForInbox(inboxId));
      dispatch(getAdminMetadataTypesForInbox(inboxId));
      dispatch(getAdminActionTypesForInbox(inboxId));
      dispatch(getAdminTagTypesForInbox(inboxId));
      dispatch(getMasterdataTablesForInbox(inboxId));
      dispatch(getAdminApprovalChecksForInbox(inboxId));
    }
    return () => {
      dispatch(unsubAdmin());
      dispatch(adminSlice.actions.setInboxMasterdataTables(null));
    };
  }, [inboxId, dispatch]);

  useEffect(() => {
    if (inboxes.length > 0 && inboxId !== 'new') {
      if (inboxRef.current) {
        const activeInboxItem = inboxRef.current as HTMLDivElement;
        sleep(1).then(() => {
          activeInboxItem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        });
      }
    }
  }, [inboxes, inboxId]);

  return (
    <div className={s.subcontent}>
      <div className={s.subsidebar}>
        <div className={s.header}>{t('admin:inboxes.title')}</div>
        <NavLink to={'new/settings'} data-testid={'inbox-add'} className={s.add}>
          + {t('admin:inboxes.createInbox')}
        </NavLink>
        <nav className={clsx(s.section, s.section__top)}>
          {!inboxes ||
            (inboxes.length === 0 && (
              <Skeleton
                count={5}
                height={20}
                width={'calc(100% - 32px '}
                style={{ marginBottom: 28, marginLeft: 16 }}
              />
            ))}
          {inboxes.map((inbox) => (
            <NavLink
              data-testid={'inbox-label'}
              ref={inboxId === inbox.id ? inboxRef : null}
              key={inbox.id}
              to={`${inbox.id}/${currentTab}`}
              className={clsx(s.item, { [s.active]: inboxId === inbox.id })}
            >
              {inbox.settings.name}
            </NavLink>
          ))}
        </nav>
        {inboxId !== 'new' && (
          <nav className={s.section}>
            <NavLink
              data-testid={'inbox-nav-settings'}
              to={`${inboxId}/settings`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {t('admin:inboxes.inboxSettings')}
            </NavLink>
            <NavLink
              data-testid={'inbox-nav-doctypes'}
              to={`${inboxId}/doctypes`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {t('admin:inboxes.docTypes')}
            </NavLink>
            <NavLink
              data-testid={'inbox-nav-fields'}
              to={`${inboxId}/fields`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {t('admin:inboxes.fieldTypes')}
            </NavLink>
            <NavLink
              data-testid={'inbox-nav-metadata'}
              to={`${inboxId}/metadata`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {t('admin:inboxes.metadataTypes')}
            </NavLink>
            <NavLink
              data-testid={'inbox-nav-bounce'}
              to={`${inboxId}/bounce-fields`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {t('admin:inboxes.actionTypes')}
            </NavLink>
            <NavLink
              data-testid={'inbox-nav-tags'}
              to={`${inboxId}/tags`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {t('admin:inboxes.tagTypes')}
            </NavLink>
            <NavLink
              data-testid={'inbox-nav-masterdata'}
              to={`${inboxId}/masterdata`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              Masterdata
            </NavLink>
          </nav>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default AdminInboxes;
