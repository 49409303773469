import React from 'react';
import { useSelector } from '../../../shared/store/store.ts';
import s from '../../../shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as DividerVertical } from '../../../shared/assets/svg/divider-vertical.svg';
import { ReactComponent as TrashIcon } from '../../../shared/assets/svg/trash-icon-alt.svg';
import Checkbox from '../../shared/checkbox/Checkbox.tsx';
interface Props {
  tableEntity: { id: string; mandatory?: boolean };
  handleDelete: (id: string) => void;
  handleEdit: (updatedItem: { id: string; mandatory?: boolean }) => void;
  mandatory?: boolean;
}

const AdminTableItemRow: React.FC<Props> = ({ tableEntity, handleEdit, handleDelete, mandatory = true }) => {
  const inboxFieldTypes = useSelector((state) => state.admin.inboxEntityTypes);
  const name = inboxFieldTypes.find((e) => e.id === tableEntity.id)?.name || '';

  return (
    <div data-testid={'sortable-field'} className={s.field_type_row}>
      <div className={s.left}>
        <span>{name}</span>
      </div>

      <div className={s.field_type_row_actions}>
        {mandatory && (
          <>
            <Checkbox
              checked={tableEntity.mandatory}
              onClick={() => handleEdit({ id: tableEntity.id, mandatory: !tableEntity.mandatory })}
            />
            <DividerVertical />
          </>
        )}

        <button
          data-testid={'admin-table-item-row-delete'}
          className={s.trash}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete(tableEntity.id);
          }}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default AdminTableItemRow;
