import React from 'react';
import { RaceBy } from '@uiball/loaders';
import { ReactComponent as PaperboxLogo } from '../../../shared/assets/svg/paperbox-logo-small.svg';

const PageLoader: React.FC = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
          background: 'white',
          boxShadow: 'rgba(0, 0, 0, 0.10) 0 3px 8px',
          borderRadius: 10,
        }}
      >
        <PaperboxLogo style={{ width: 75, height: 'auto' }} />
      </div>
      <RaceBy size={100} lineWeight={5} color={'#0085FF'} />
    </>
  );
};

export default PageLoader;
