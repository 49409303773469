import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { off } from 'firebase/database';
import { AppThunk } from './store';

interface SubsState {
  documentSub?: VoidFunction;
  copySub?: VoidFunction;
  prevSiblingIdSub?: VoidFunction;
  nextSiblingIdSub?: VoidFunction;
  documentListSub?: VoidFunction;
  pendingInboxDocsSub?: VoidFunction;
  activeInboxSub?: VoidFunction;
  metadataSub?: VoidFunction;
  approvalChecksSub?: VoidFunction;
  docTypesSub?: VoidFunction;
  entityTypesSub?: VoidFunction;
  tagTypesSub?: VoidFunction;
  actionTypeSub?: VoidFunction;
  adminEntitySub?: VoidFunction;
  adminMasterdataTablesSub?: VoidFunction;
  adminMasterdataSubs?: Record<string, VoidFunction>;
  adminMasterdataVersionSubs?: Record<string, VoidFunction>;
  masterdataVersionSubs?: Record<string, VoidFunction>;
  adminDocTypesSub?: VoidFunction;
  adminActionTypesSub?: VoidFunction;
  adminMetadataSub?: VoidFunction;
  adminTagTypesSub?: VoidFunction;
  countsSub?: any;
  userActivitySub?: any;
}

const initialState: SubsState = {
  adminMasterdataSubs: {},
  adminMasterdataVersionSubs: {},
  masterdataVersionSubs: {},
};

export const subsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setActiveInboxSub: (state, action: PayloadAction<VoidFunction>) => {
      state.activeInboxSub = action.payload;
    },
    setAdminEntitySub: (state, action: PayloadAction<VoidFunction>) => {
      state.adminEntitySub = action.payload;
    },
    setAdminMasterdataSubs: (state, action: PayloadAction<Record<string, VoidFunction>>) => {
      state.adminMasterdataSubs = action.payload;
    },
    setAdminMasterdataTablesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.adminMasterdataTablesSub = action.payload;
    },
    setAdminMasterdataVersionSub: (state, action: PayloadAction<Record<string, VoidFunction>>) => {
      state.adminMasterdataVersionSubs = action.payload;
    },
    setMasterdataVersionSubs: (state, action: PayloadAction<Record<string, VoidFunction>>) => {
      state.masterdataVersionSubs = action.payload;
    },
    setAdminDocTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.adminDocTypesSub = action.payload;
    },
    setAdminActionTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.adminActionTypesSub = action.payload;
    },
    setAdminTagTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.adminTagTypesSub = action.payload;
    },
    setAdminMetadataSub: (state, action: PayloadAction<VoidFunction>) => {
      state.adminMetadataSub = action.payload;
    },
    setDocumentSub: (state, action: PayloadAction<VoidFunction>) => {
      state.documentSub = action.payload;
    },
    setCopySub: (state, action: PayloadAction<VoidFunction>) => {
      state.copySub = action.payload;
    },
    setPrevSiblingIdSub: (state, action: PayloadAction<VoidFunction>) => {
      state.prevSiblingIdSub = action.payload;
    },
    setNextSiblingIdSub: (state, action: PayloadAction<VoidFunction>) => {
      state.nextSiblingIdSub = action.payload;
    },
    setDocumentListSub: (state, action: PayloadAction<VoidFunction>) => {
      state.documentListSub = action.payload;
    },
    setTagTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.tagTypesSub = action.payload;
    },
    setPendingInboxDocsSub: (state, action: PayloadAction<VoidFunction>) => {
      state.pendingInboxDocsSub = action.payload;
    },

    setDocTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.docTypesSub = action.payload;
    },
    setEntityTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.entityTypesSub = action.payload;
    },
    setActionTypesSub: (state, action: PayloadAction<VoidFunction>) => {
      state.actionTypeSub = action.payload;
    },
    setMetadataSub: (state, action: PayloadAction<VoidFunction>) => {
      state.metadataSub = action.payload;
    },
    setApprovalChecksSub: (state, action: PayloadAction<VoidFunction>) => {
      state.approvalChecksSub = action.payload;
    },
    setCountsSub: (state, action: PayloadAction<any>) => {
      state.countsSub = action.payload;
    },
    setUserActivitySub: (state, action: PayloadAction<any>) => {
      state.userActivitySub = action.payload;
    },
  },
});

export const unsubAll = (): AppThunk => async (_, getState) => {
  const {
    docTypesSub,
    documentListSub,
    documentSub,
    nextSiblingIdSub,
    pendingInboxDocsSub,
    prevSiblingIdSub,
    entityTypesSub,
    countsSub,
  } = getState().subs;

  if (docTypesSub) docTypesSub();
  if (documentListSub) documentListSub();
  if (documentSub) documentSub();
  if (pendingInboxDocsSub) pendingInboxDocsSub();
  if (nextSiblingIdSub) nextSiblingIdSub();
  if (prevSiblingIdSub) prevSiblingIdSub();
  if (entityTypesSub) entityTypesSub();
  if (countsSub) off(countsSub);
};
export const unsubMasterdata = (): AppThunk => async (_, getState) => {
  const { masterdataVersionSubs } = getState().subs;
  if (masterdataVersionSubs) {
    Object.values(masterdataVersionSubs).forEach((tableSub) => {
      tableSub();
    });
  }
};

export const unsubAdminMasterdata = (): AppThunk => async (_, getState) => {
  const { adminMasterdataSubs, adminMasterdataVersionSubs } = getState().subs;
  if (adminMasterdataSubs) {
    Object.values(adminMasterdataSubs).forEach((tableSub) => {
      tableSub();
    });
  }
  if (adminMasterdataVersionSubs) {
    Object.values(adminMasterdataVersionSubs).forEach((versionSub) => {
      versionSub();
    });
  }
};

export const unsubAdmin = (): AppThunk => async (_, getState) => {
  const {
    adminTagTypesSub,
    adminActionTypesSub,
    adminDocTypesSub,
    adminMasterdataSubs,
    adminMasterdataTablesSub,
    adminEntitySub,
    adminMasterdataVersionSubs,
    adminMetadataSub,
  } = getState().subs;

  console.log('Unsubscribing from all admin firestore subscriptions');
  if (adminActionTypesSub) adminActionTypesSub();
  if (adminTagTypesSub) adminTagTypesSub();
  if (adminDocTypesSub) adminDocTypesSub();
  if (adminEntitySub) adminEntitySub();
  if (adminMetadataSub) adminMetadataSub();
  if (adminMasterdataTablesSub) adminMasterdataTablesSub();
  if (adminMasterdataSubs) {
    Object.values(adminMasterdataSubs).forEach((tableSub) => {
      tableSub();
    });
  }
  if (adminMasterdataVersionSubs) {
    Object.values(adminMasterdataVersionSubs).forEach((versionSub) => {
      versionSub();
    });
  }
};
