import clsx from 'clsx';
import React, { useEffect } from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ConnectorsIcon } from '../shared/assets/svg/connector.svg';
import { ReactComponent as InboxIcon } from '../shared/assets/svg/inbox-icon.svg';
import { ReactComponent as SettingsIcon } from '../shared/assets/svg/settings.svg';
import { ReactComponent as UsersIcon } from '../shared/assets/svg/users-icon.svg';
import { ReactComponent as WebhooksIcon } from '../shared/assets/svg/webhook.svg';
import { ReactComponent as HomeIcon } from '../shared/assets/svg/home.svg';
import {
  adminSlice,
  getConnectors,
  getEndpoints,
  getInboxes,
  getTenantConfig,
  getWebhooks,
  getWebhookServerSideValues,
} from '../shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '../shared/store/store.ts';
import { unsubAdminMasterdata, unsubAll } from '../shared/store/subsSlice.ts';
import s from '../shared/styles/component/admin/admin.module.scss';

interface Props {}

const AdminContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const originalInboxId = useSelector((state) => state.admin.originalInboxId);

  useEffect(() => {
    dispatch(getConnectors());
    dispatch(getWebhooks());
    dispatch(getEndpoints());
    dispatch(getTenantConfig());
    dispatch(getInboxes());
    dispatch(getWebhookServerSideValues());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(unsubAdminMasterdata());
      dispatch(unsubAll());
      dispatch(adminSlice.actions.setOriginalInboxId(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (inboxId) {
      // This is just used for the import/export functionality
      dispatch(adminSlice.actions.setActiveInboxId(inboxId));
    }
  }, [dispatch, inboxId]);
  useEffect(() => {
    if (inboxId && !originalInboxId) {
      dispatch(adminSlice.actions.setOriginalInboxId(inboxId));
    }
  }, [dispatch, inboxId, originalInboxId]);

  return (
    <div className={s.container}>
      <button
        data-testid={'admin-close'}
        className={s.close}
        onClick={() => navigate(`/inbox/${originalInboxId}`)}
      >
        Close
      </button>
      <div className={s.sidebar}>
        <div onClick={() => navigate(`/inbox/${originalInboxId}`)} className={s.sidebar_header}>
          <HomeIcon />
        </div>
        <NavLink
          to={'settings'}
          data-testid={'admin-sidebar-tenant'}
          className={({ isActive }) =>
            clsx(s.sidebar_item, {
              [s.sidebar_item__active]: isActive,
            })
          }
        >
          <SettingsIcon />
        </NavLink>
        <NavLink
          to={'users'}
          data-testid={'admin-sidebar-users'}
          className={({ isActive }) =>
            clsx(s.sidebar_item, {
              [s.sidebar_item__active]: isActive,
            })
          }
        >
          <UsersIcon />
        </NavLink>
        <NavLink
          to={'inboxes'}
          data-testid={'admin-sidebar-inboxes'}
          className={({ isActive }) =>
            clsx(s.sidebar_item, {
              [s.sidebar_item__active]: isActive,
            })
          }
        >
          <InboxIcon />
        </NavLink>

        <NavLink
          to={'connectors'}
          data-testid={'admin-sidebar-connectors'}
          className={({ isActive }) =>
            clsx(s.sidebar_item, {
              [s.sidebar_item__active]: isActive,
            })
          }
        >
          <ConnectorsIcon />
        </NavLink>
        <NavLink
          to={'webhooks'}
          data-testid={'admin-sidebar-webhooks'}
          className={({ isActive }) =>
            clsx(s.sidebar_item, {
              [s.sidebar_item__active]: isActive,
            })
          }
        >
          <WebhooksIcon />
        </NavLink>
      </div>
      <div className={s.body}>
        <div className={s.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminContainer;
