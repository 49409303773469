import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../../../shared/assets/svg/search-icon.svg';
import { ReactComponent as ToolRectangleIcon } from '../../../../shared/assets/svg/tool-rectangle-icon.svg';
import { labelerSlice } from '../../../../shared/store/labelerSlice.ts';
import { useDispatch, useSelector } from '../../../../shared/store/store';
import s from '../../../../shared/styles/component/document/document-labeler-tools.module.scss';
import Tooltip from '../../../shared/tooltip/Tooltip';
import DocumentLabelerInfoPanel from '../DocumentLabelerInfoPanel';
import DocumentLabelerTool from './DocumentLabelerTool';

interface Props {
  isLoading: boolean;
}

const DocumentLabelerToolbar: React.FC<Props> = ({ isLoading }) => {
  const activeMode = useSelector((state) => state.labeler.mode);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tooltipText = {
    search: t('document:toolbar.search'),
    label: t('document:toolbar.label'),
  };
  const changeMode = (mode: 'label' | 'search') => {
    dispatch(labelerSlice.actions.setActiveMode(mode));
    if (mode === 'search') dispatch(labelerSlice.actions.setActiveTool('default'));
  };
  const hasExternalTable = useMemo(() => {
    if (masterDataMappings) return Object.values(masterDataMappings)?.some((el) => el.type === 'external');
    else return false;
  }, [masterDataMappings]);

  return (
    <div className={s.bar} style={{ opacity: isLoading ? 0 : 1 }} data-tour="toolbar">
      <span>{t('document:toolbar.tool')} :</span>
      <div className={s.tools} data-tour="tools">
        {!labelingMode && masterDataMappings && !hasExternalTable && (
          <div className={s.tool_wrapper} data-testid={'labeler-tool'}>
            <Tooltip tooltipStyle={{ maxWidth: 300 }} content={tooltipText.search} position={'bottom'}>
              <button
                onClick={() => changeMode('search')}
                className={clsx(s.tool_selector, { [s.tool_selector__active]: activeMode === 'search' })}
              >
                <SearchIcon />
              </button>
            </Tooltip>
          </div>
        )}

        <div className={s.tool_wrapper} data-testid={'labeler-tool'}>
          <Tooltip tooltipStyle={{ maxWidth: 300 }} content={tooltipText.label} position={'bottom'}>
            <button
              onClick={() => changeMode('label')}
              className={clsx(s.tool_selector, { [s.tool_selector__active]: activeMode === 'label' })}
            >
              <ToolRectangleIcon />
            </button>
          </Tooltip>
        </div>
      </div>

      {!isLoading && activeMode !== 'complex' && <DocumentLabelerTool />}
      <DocumentLabelerInfoPanel />
    </div>
  );
};

export default DocumentLabelerToolbar;
