import clsx from 'clsx';
import React from 'react';
import { ReactComponent as PlusIcon } from '../../../../shared/assets/svg/plus-icon.svg';
import se from '../../../../shared/styles/component/admin/admin-section.module.scss';
import sr from '../../../../shared/styles/component/admin/admin-item-row.module.scss';
import { ReactComponent as TrashIcon } from '../../../../shared/assets/svg/trash-icon-alt.svg';
import AdminMultiSelectDialog from '../AdminMultiSelectDialog.tsx';

interface Props {
  hidden?: boolean;
  title?: string;
  description?: string;
  value: any;
  onChange: any;
  options: { id: string; name: string; children?: any }[];
  showDialog: any;
}

const FormMultiSelectField: React.FC<Props> = ({
  hidden,
  title,
  description,
  value,
  onChange,
  options,
  showDialog,
}) => {
  if (hidden) return null;
  return (
    <div className={se.item}>
      <div className={se.item_text}>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className={se.item_action}>
        <div className={se.item_fields}>
          {value && options && value?.length !== options?.length && (
            <button
              type="button"
              className={se.item_field}
              onClick={() =>
                showDialog(
                  <AdminMultiSelectDialog
                    title={title}
                    description={description}
                    selectedTypes={value ?? []}
                    handleCheckTypes={(e) => onChange(e)}
                    detailedList={options}
                  />
                )
              }
            >
              <PlusIcon className={se.field_add} />
            </button>
          )}
          {value?.map((opt) => {
            const details = options.find((o) => o.id === opt.id);
            const items = [];
            if (details?.children) {
              if (!opt.children) {
                items.push({ name: details?.name + ' / All Tables ', id: details.id });
              }
              opt?.children?.forEach((optChild) => {
                const childDetails = details.children.find((detailed) => detailed.id === optChild);
                items.push({
                  name: details?.name + ' / ' + childDetails?.name,
                  id: childDetails.id,
                  parentId: opt.id,
                });
              });
            } else {
              items.push({ name: details?.name, id: details?.id });
            }
            return items.map((item) => {
              return (
                <div
                  key={item.name}
                  data-testid="sortable-item"
                  className={clsx(sr.wrapper, sr.wrapper__no_anim, { [sr.wrapper__small]: true })}
                >
                  <div className={sr.container}>
                    <span className={sr.title}>{item.name}</span>
                  </div>
                  <button
                    className={clsx(sr.action, sr.trash)}
                    data-testid={'sortable-item-delete'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (item.parentId) {
                        const valueItemIndex = value.findIndex((i) => i.id === item.parentId);
                        let valueItem = value[valueItemIndex];
                        const newChildren = valueItem?.children.filter((e) => e !== item.id);
                        console.log(newChildren);
                        if (newChildren.length === 0) {
                          console.log(item);
                          console.log(value);
                          onChange(value.filter((i) => i.id !== item.parentId));
                        } else {
                          valueItem = { ...valueItem, children: newChildren };
                          value[valueItemIndex] = valueItem;
                          onChange(value);
                        }
                      } else {
                        onChange(value.filter((i) => i.id !== item.id));
                      }
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default FormMultiSelectField;
