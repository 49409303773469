import React, { useEffect } from 'react';

interface Props {}

const AuthM365Admin: React.FC<Props> = () => {
  useEffect(() => {
    const hashParams = {};
    let e;
    const r = /([^&;=]+)=?([^&;]*)/g;
    const q = window.location.hash.substring(1);

    while ((e = r.exec(q))) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    if (hashParams['state'].split('|')[1]) {
      const targetSubdomainUrl = `${hashParams['state'].split('|')[1]}?${window.location.search}${
        window.location.hash
      }`;
      window.location.replace(targetSubdomainUrl);
    }
  }, []);
  return null;
};

export default AuthM365Admin;
