import React, { useEffect, useRef, useState } from 'react';
import { DotPulse } from '@uiball/loaders';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from '../../../shared/assets/svg/checkmark-icon.svg';
import { ReactComponent as PlusIcon } from '../../../shared/assets/svg/plus-icon.svg';
import { sleep } from '../../../shared/helpers/helpers';
import { createNewUser } from '../../../shared/store/adminSlice';
import { useDispatch, useSelector } from '../../../shared/store/store';
import s from '../../../shared/styles/component/admin/admin-pages/admin-page.module.scss';
import se from '../../../shared/styles/component/admin/admin-section.module.scss';

interface Props {}
type LoadingState = 'default' | 'submitting' | 'error' | 'done';

const AdminNewUserRow: React.FC<Props> = () => {
  const tenantSettings = useSelector((state) => state.tenant.details.settings);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState<LoadingState>('default');
  const input = useRef(null);
  const { t } = useTranslation();

  const handleCreateUser = (e) => {
    e.preventDefault();

    let allowed = true;

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      allowed = true;
    } else {
      setEmail('');
      input.current.focus();
      setError('Please enter a valid email');
      return;
    }

    for (const i in tenantSettings.allowedDomains) {
      if (email.endsWith(`@${tenantSettings.allowedDomains[i]}`)) {
        allowed = true;
        break;
      } else {
        allowed = false;
      }
    }
    if (!allowed) {
      setEmail('');
      input.current.focus();
      setError('Email not allowed');
    }

    if (allowed) {
      setLoadingState('submitting');
      dispatch(createNewUser(email))
        .then((res) => {
          if (res.status === 409) {
            setError('User already exists');
            setLoadingState('error');
          } else {
            setError('');
            setLoadingState('done');
            sleep(3000).then(() => {
              setEmail('');
              setLoadingState('default');
            });
          }
        })
        .catch((err) => {
          if (err.request.status === 409) {
            setError('User already exists');
            setLoadingState('error');
          }
        });
    }
  };

  useEffect(() => {
    setLoadingState('default');
    setError(null);
  }, []);

  return (
    <form onSubmit={handleCreateUser} className={s.body_header_action}>
      <span className={s.body_header_action_error}>{error}</span>
      <div className={se.input_wrapper}>
        {loadingState === 'done' && (
          <div className={clsx(se.input, se.input__top)}>
            <CheckmarkIcon className={se.check} />{' '}
            <span>
              {t('admin:users.emailSent')} {email}
            </span>
          </div>
        )}
        {loadingState !== 'done' && (
          <input
            data-testid={'admin-user-input'}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            ref={input}
            type="email"
            placeholder={'user@email.com'}
            className={clsx(se.input, se.input__top)}
          />
        )}
      </div>
      <button data-testid={'admin-user-add'} className={se.top_button}>
        {loadingState === 'done' ? (
          <>
            <CheckmarkIcon className={se.check} /> <span>{t('admin:users.success')}</span>
          </>
        ) : loadingState === 'submitting' ? (
          <>
            <DotPulse color={'#0085FF'} size={3} /> <span>{t('admin:users.creatingUser')}</span>
          </>
        ) : (
          <>
            <PlusIcon /> <span>{t('admin:users.createUser')}</span>
          </>
        )}
      </button>
    </form>
  );
};

export default AdminNewUserRow;
