import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  bounceFieldClientToRaw,
  IClientBounceField,
} from '../../../../../shared/helpers/converters/bouncefield.ts';
import useChangeTracker from '../../../../../shared/hooks/useChangeTracker.tsx';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/svg/plus-icon.svg';

import { useModal } from '../../../../../shared/hooks/useModal.tsx';
import { ActionTypeOption } from '../../../../../shared/models/document.ts';
import {
  adminSlice,
  deleteActionType,
  deleteActionTypeOption,
  patchActionType,
  postActionType,
  postActionTypeOption,
} from '../../../../../shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '../../../../../shared/store/store.ts';
import s from '../../../../../shared/styles/component/admin/admin-section.module.scss';
import AdminItemRow from '../../../components/AdminItemRow.tsx';
import FormBodyHeader from '../../../components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '../../../components/form/FormHeaderNav.tsx';
import FormInputField from '../../../components/form/FormInputField.tsx';
import FormSection from '../../../components/form/FormSection.tsx';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/ConfirmationDialog.tsx';

const actionTypeTypes = [
  { value: 'boolean', label: 'Boolean' },
  { value: 'text', label: 'Text' },
  { value: 'choice', label: 'Options' },
  { value: 'multi-choice', label: 'Multiple Options' },
];

const AdminInboxesBounceFieldEdit: React.FC = () => {
  const inboxBounceFields = useSelector((state) => state.admin.inboxActionTypes);
  const newTempBounceOptions = useSelector((state) => state.admin.newTempBounceOptions);
  const newTempBounceField = useSelector((state) => state.admin.newTempBounceField);
  const { bounceFieldId, inboxId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const navigate = useNavigate();

  const activeBounceField = useMemo(() => {
    return inboxBounceFields?.find((id) => id.id === bounceFieldId);
  }, [inboxBounceFields, bounceFieldId]);

  const handleSave = async () => {
    const mappedState = { ...state };
    if (!state.providerId || state.providerId === '') {
      mappedState.providerId = null;
    }
    const data = bounceFieldClientToRaw(mappedState);

    delete data.options;
    if (bounceFieldId === 'new') {
      return postActionType(inboxId, data).then((res: AxiosResponse) => {
        const newId = res.data['id'];
        const promiseList = [];
        Object.values(activeOptions).forEach(async (option) => {
          const clone = { ...option };
          delete clone.id;
          promiseList.push(postActionTypeOption(inboxId, newId, clone));
        });
        Promise.all(promiseList).then(() => {
          navigate(`/admin/inboxes/${inboxId}/bounce-fields`);
        });
      });
    } else {
      return dispatch(patchActionType(inboxId, bounceFieldId, data)).then(() => {
        navigate(`/admin/inboxes/${inboxId}/bounce-fields`);
      });
    }
  };

  const handleDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(deleteActionType(bounceFieldId));
          navigate(`/admin/inboxes/${inboxId}/bounce-fields`);
        }}
        text={t('admin:inboxes.sections.actionTypeDelete')}
      />
    );
  };

  const handleDeleteOption = (setting: ActionTypeOption) => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => dispatch(deleteActionTypeOption(inboxId, bounceFieldId, setting.id))}
        text={t('admin:inboxes.sections.actionTypeOptionDelete')}
      />
    );
  };

  const initialState = useMemo(() => {
    if (activeBounceField) return activeBounceField;
    return {
      name: '',
      type: 'text',
      id: '',
    } as IClientBounceField;
  }, [activeBounceField]);

  const { state, handleInput, setState, saving, save, hasChanges, error } =
    useChangeTracker<IClientBounceField>(initialState, handleSave);

  useEffect(() => {
    if (newTempBounceField && newTempBounceField !== state) {
      setState(newTempBounceField);
      dispatch(adminSlice.actions.setNewTempBounceField(null));
    }
  }, [newTempBounceField, state, setState, dispatch]);

  const handleNavBack = () => {
    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(`/admin/inboxes/${inboxId}/bounce-fields`);
          }}
          cancelAction={() => navigate(`/admin/inboxes/${inboxId}/bounce-fields`)}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={t('admin:unsavedChanges.description')}
        />
      );
    } else {
      navigate(`/admin/inboxes/${inboxId}/bounce-fields`);
    }
  };

  const activeOptions = useMemo(() => {
    if (newTempBounceOptions && bounceFieldId === 'new') return newTempBounceOptions;
    if (bounceFieldId && inboxBounceFields) {
      const bounceField = inboxBounceFields.find((e) => e.id === bounceFieldId);
      if (bounceField) {
        return bounceField.options;
      }
    }
    return [];
  }, [newTempBounceOptions, bounceFieldId, inboxBounceFields]);

  const nextBounceField = useMemo(() => {
    if (bounceFieldId && inboxBounceFields) {
      const sorted = [...inboxBounceFields].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === bounceFieldId);
      return sorted[index + 1];
    }
    return null;
  }, [bounceFieldId, inboxBounceFields]);

  const prevBounceField = useMemo(() => {
    if (bounceFieldId && inboxBounceFields) {
      const sorted = [...inboxBounceFields].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === bounceFieldId);
      return sorted[index - 1];
    }
    return null;
  }, [bounceFieldId, inboxBounceFields]);

  const handleNavNextBounceField = () => {
    if (nextBounceField) {
      navigate(`/admin/inboxes/${inboxId}/bounce-fields/${nextBounceField.id}`);
    }
  };

  const handleNavPrevBounceField = () => {
    if (prevBounceField) {
      navigate(`/admin/inboxes/${inboxId}/bounce-fields/${prevBounceField.id}`);
    }
  };

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormHeaderNav
        onClick={handleNavBack}
        label={t('admin:page.backToOverview')}
        navOptions={
          bounceFieldId === 'new'
            ? {}
            : {
                prev: {
                  label: t('admin:page.previous'),
                  onClick: handleNavPrevBounceField,
                  active: !!prevBounceField,
                },
                next: {
                  label: t('admin:page.next'),
                  onClick: handleNavNextBounceField,
                  active: !!nextBounceField,
                },
              }
        }
      />
      <FormBodyHeader
        title={activeBounceField ? activeBounceField.name : t('admin:actionType.newType')}
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={error ? t(`admin:errors.${error}`) : ''}
      />
      <div className={s.sections} id={'sections'}>
        <FormSection title={t('admin:actionType.config')}>
          <FormInputField
            type={'text'}
            label={t('admin:actionType.name')}
            description={t('admin:actionType.nameDescription')}
            value={state?.name}
            onChange={(val) => handleInput(val, 'name')}
          />
          <FormInputField
            disabled
            hidden={bounceFieldId === 'new'}
            isCopyField
            type={'text'}
            value={state?.id ?? ''}
            label={t('admin:actionType.id')}
            description={t('admin:actionType.idDescription')}
          />
          <FormInputField
            type={'text'}
            description={t('admin:actionType.providerIdDescription')}
            label={t('admin:actionType.providerId')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
          />
          <FormInputField
            type={'toggle'}
            label={t('admin:actionType.sensitive')}
            description={t('admin:actionType.sensitiveDescription')}
            value={state?.isSensitive}
            onChange={(val) => handleInput(val, 'isSensitive')}
          />
        </FormSection>
        <FormSection title={t('admin:actionType.values')}>
          <FormInputField
            type={'dropdown'}
            label={t('admin:actionType.valueType')}
            description={t('admin:actionType.valueTypeDescription')}
            value={actionTypeTypes.find((ett) => ett.value === state?.type)}
            onChange={(val) => handleInput(val.value, 'type')}
            dropdownOptions={actionTypeTypes}
          />

          {(state?.type === 'choice' || state?.type === 'multi-choice') && (
            <>
              <FormInputField
                label={t('admin:actionType.valueOptions')}
                description={t('admin:actionType.valueOptionsDescription')}
                type={'button'}
                buttonOptions={{
                  type: 'normal',
                  text: (
                    <>
                      <PlusIcon /> {t('admin:inboxes.sections.addNew')}
                    </>
                  ),
                  onClick: () => {
                    dispatch(adminSlice.actions.setNewTempBounceField(state));
                    navigate(`options/new`);
                  },
                }}
              >
                <div className={clsx(s.row_list)}>
                  {activeOptions?.map((option) => {
                    return (
                      <AdminItemRow
                        handleNav={() => {
                          dispatch(adminSlice.actions.setNewTempBounceField(state));
                          navigate(`options/${option.id}`);
                        }}
                        actionTypeOption={option}
                        handleDelete={() => handleDeleteOption(option)}
                        key={option.id}
                        title={option.name}
                      />
                    );
                  })}
                </div>
              </FormInputField>
            </>
          )}
        </FormSection>
        <FormSection hidden={bounceFieldId === 'new'} title={t('admin:actionType.dangerZone')}>
          <FormInputField
            type={'button'}
            buttonOptions={{
              type: 'error',
              text: t('admin:actionType.deleteType'),
              onClick: handleDelete,
            }}
            label={t('admin:actionType.deleteType')}
            description={t('admin:actionType.deleteTypeDescription')}
          />
        </FormSection>
      </div>
    </form>
  );
};
export default AdminInboxesBounceFieldEdit;
