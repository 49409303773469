import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IClientFieldType } from '../../../../../shared/helpers/converters/fieldtype.ts';
import { extendedSearch, globalFuseOptions } from '../../../../../shared/helpers/helpers.ts';
import { useModal } from '../../../../../shared/hooks/useModal.tsx';
import { deleteEntityType } from '../../../../../shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '../../../../../shared/store/store.ts';
import p from '../../../../../shared/styles/component/admin/admin-pages/admin-page.module.scss';
import s from '../../../../../shared/styles/component/admin/admin-section.module.scss';
import AdminItemRow from '../../../components/AdminItemRow.tsx';
import FormSection from '../../../components/form/FormSection.tsx';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/ConfirmationDialog.tsx';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};

const AdminInboxesFields: React.FC = () => {
  const inboxFieldTypes = useSelector((state) => state.admin.inboxEntityTypes) ?? [];

  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<IClientFieldType[]>(null);
  const [selectedFieldTypes, setSelectedFieldTypes] = useState<string[]>([]);
  const fuse = new Fuse(fuseData, fuseOptions);

  const handleMatches = (input) => {
    setSearchResults(extendedSearch(input, fuse));
  };

  const handleInput = (value) => {
    if (value === '') {
      setSearchResults(inboxFieldTypes);
    } else {
      handleMatches(value);
    }
  };
  const handleDelete = (setting: IClientFieldType) => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => dispatch(deleteEntityType(setting.id))}
        text={t('admin:inboxes.sections.fieldTypeDelete')}
      />
    );
  };

  const handleMultiDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          selectedFieldTypes.forEach((tagId) => {
            dispatch(deleteEntityType(tagId));
          });
          setSelectedFieldTypes([]);
        }}
        text={t('admin:inboxes.sections.fieldTypeMultiDelete')}
      />
    );
  };

  useEffect(() => {
    if (inboxFieldTypes) {
      setSearchResults(inboxFieldTypes);
      setFuseData(inboxFieldTypes);
    }
  }, [inboxFieldTypes]);

  return (
    <div className={clsx(s.form_body, s.form_body_scroll)}>
      <div className={p.body_header}>
        <h2>{t('admin:inboxes.fieldTypes')}</h2>
      </div>
      <p className={p.body_description}>{t('admin:inboxes.fieldTypesDescription')}</p>

      <FormSection
        noStyle
        scroll
        title={t('admin:inboxes.fieldTypes')}
        add={{
          testId: 'fieldtype-add',
          onClick: () => navigate('new'),
          label: t('admin:inboxes.sections.addNew'),
        }}
        search={{
          onChange: (e) => handleInput(e),
          placeholder: t('admin:inboxes.sections.fieldTypeSearch'),
        }}
        select={{
          handleDelete: handleMultiDelete,
          allValues: inboxFieldTypes.map((e) => e.id),
          selectedValues: selectedFieldTypes,
          setSelectedValues: setSelectedFieldTypes,
        }}
      >
        <div className={clsx(s.row_list)}>
          {searchResults &&
            searchResults.map((setting, index) => {
              const isChecked = selectedFieldTypes.findIndex((e) => e === setting.id) !== -1;
              return (
                <AdminItemRow
                  animationSettings={{
                    enabled: true,
                    delay: 50 + index * 25,
                  }}
                  isChecked={isChecked}
                  setIsChecked={(value) => {
                    if (value) {
                      setSelectedFieldTypes([...selectedFieldTypes, setting.id]);
                    } else {
                      setSelectedFieldTypes(selectedFieldTypes.filter((id) => id !== setting.id));
                    }
                  }}
                  handleDelete={() => handleDelete(setting)}
                  handleNav={() => {
                    navigate(setting.id);
                  }}
                  key={'fieldType' + setting.id}
                  entityType={setting}
                  title={setting.name}
                />
              );
            })}
          {searchResults?.length === 0 && (
            <div className={s.no_results}>{t('admin:inboxes.sections.noFieldTypeFound')}</div>
          )}
        </div>
      </FormSection>
    </div>
  );
};
export default AdminInboxesFields;
