import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IClientTenantSettings } from '../../../../shared/helpers/converters/tenant.ts';
import { getUserToken } from '../../../../shared/helpers/helpers.ts';
import useChangeTracker from '../../../../shared/hooks/useChangeTracker.tsx';
import { getTenantConfig, patchTenantSettings } from '../../../../shared/store/adminSlice.ts';
import { api } from '../../../../shared/store/setup/firebase-setup.ts';
import { useDispatch, useSelector } from '../../../../shared/store/store.ts';
import { getTenant } from '../../../../shared/store/tenantSlice.ts';
import s from '../../../../shared/styles/component/admin/admin-section.module.scss';
import FormBodyHeader from '../../components/form/FormBodyHeader.tsx';
import FormInputField from '../../components/form/FormInputField.tsx';
import FormSection from '../../components/form/FormSection.tsx';

const languages = [
  { value: 'en', label: 'English' },
  { value: 'nl', label: 'Nederlands' },
];

const AdminSettings: React.FC = () => {
  const tenantDetails = useSelector((state) => state.admin.tenantDetails);
  const tenantId = useSelector((state) => state.tenant.tenantId);

  const dispatch = useDispatch();

  const timeZones = useMemo(() => {
    return Intl['supportedValuesOf']('timeZone').map((st) => ({
      value: st.replace('_', ' '),
      label: st.replace('_', ' '),
    }));
  }, []);

  const [APIkey, setAPIkey] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  const { t } = useTranslation();

  const initialState = useMemo(() => {
    if (tenantDetails) return tenantDetails.settings;
    return {
      allowedDomains: [],
      allowedIPs: [],
      language: 'en',
      name: '',
      dashboardRange: 30,
      timezone: 'Europe/Brussels',
    } as IClientTenantSettings;
  }, [tenantDetails]);

  const handleSave = async () => {
    return dispatch(
      patchTenantSettings({
        name: state.name,
        language: state.language,
        allowedDomains: state.allowedDomains,
        timezone: state.timezone,
        allowedIPs: tenantDetails.settings.allowedIPs,
        inviteOnly: tenantDetails.settings.inviteOnly,
      })
    ).then(() => {
      dispatch(getTenantConfig());
      dispatch(getTenant());
    });
  };
  const { state, save, saving, hasChanges, handleInput } = useChangeTracker<IClientTenantSettings>(
    initialState,
    handleSave
  );

  return (
    <form className={s.form_body} onSubmit={save}>
      <FormBodyHeader title={t('admin:tenant.title')} saving={saving} hasChanges={hasChanges} />

      <div className={s.sections}>
        <FormSection title={t('admin:tenant.general')}>
          <FormInputField
            type={'text'}
            label={t('admin:tenant.name')}
            description={t('admin:tenant.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            value={state.name}
          />
          <FormInputField
            isCopyField
            value={tenantId}
            type={'text'}
            label={t('admin:tenant.id')}
            description={t('admin:tenant.idDescription')}
          />
          <FormInputField
            type={'list'}
            label={t('admin:tenant.domains')}
            description={t('admin:tenant.domainsDescription')}
            value={state.allowedDomains}
            listInputOptions={{
              onChangeList: (val) => handleInput(val, 'allowedDomains'),
            }}
          />
          <FormInputField
            type={'fetch-secret'}
            label={t('admin:tenant.apiKey')}
            description={
              <Trans i18nKey="admin:tenant.apiKeyDescription">
                <a
                  href="https://docs.paperbox.ai/integration/security/api-authentication"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See the documentation for more info.
                </a>
                <br />
              </Trans>
            }
            fetchSecretInputOptions={{
              fetchSecret: async () => {
                const b = await getUserToken();
                const res = await api.get(
                  `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/tenant/credentials/api_key`,
                  {
                    headers: {
                      Authorization: `Bearer ${b}`,
                    },
                  }
                );
                setAPIkey(res.data.api_key);
              },
            }}
            disabled
            isCopyField={APIkey !== ''}
            placeholder={t('admin:tenant.fetchApiKey')}
            // onChange={(val) => handleInput(val, 'name')}
            value={APIkey}
          />
          <FormInputField
            type={'fetch-secret'}
            label={t('admin:tenant.privateKey')}
            description={
              <Trans i18nKey="admin:tenant.privateKeyDescription">
                <a
                  href="https://docs.paperbox.ai/integration/security/api-authentication"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See the documentation for more info.
                </a>
                <br />
              </Trans>
            }
            fetchSecretInputOptions={{
              fetchSecret: async () => {
                const b = await getUserToken();
                const res = await api.get(
                  `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/tenant/credentials/private_key`,
                  {
                    headers: {
                      Authorization: `Bearer ${b}`,
                    },
                  }
                );
                setPrivateKey(res.data.private_key);
              },
            }}
            disabled
            isCopyField={privateKey !== ''}
            placeholder={t('admin:tenant.fetchPrivateKey')}
            value={privateKey}
          />
        </FormSection>
        <FormSection title={t('admin:tenant.locale')}>
          <FormInputField
            type={'dropdown'}
            dropdownOptions={timeZones}
            label={t('admin:tenant.timeZone')}
            description={t('admin:tenant.timeZoneDescription')}
            value={{ value: state.timezone, label: state.timezone }}
            onChange={(val) => handleInput(val.value, 'timezone')}
          />
          <FormInputField
            type={'dropdown'}
            dropdownOptions={languages}
            label={t('admin:tenant.language')}
            description={t('admin:tenant.languageDescription')}
            value={languages.find((e) => e.value === state.language)}
            onChange={(val) => handleInput(val.value, 'language')}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminSettings;
