import { mapRowsToObj } from '../../../components/admin/helpers/helpers.ts';
import { WebhookServerSideValue } from '../../models/admin.ts';
import { createWebhookField, uuid4hex } from '../helpers.ts';
import { WebhookRow } from './endpoint.ts';

// HTTP specific
interface IRawHTTPConnector {
  id?: string;
  name: string;
  type: 'http';
  base_url: string;
  variables?: string[];
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params: Record<string, any>;
  headers: Record<string, any>;
  auth?: {
    endpoint: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    params: Record<string, any>;
    headers: Record<string, any>;
    payload: Record<string, any>;
    access_token_key: string;
  };
}

// SFTP specific
interface IRawSFTPConnector {
  id?: string;
  name: string;
  type: 'sftp';
  host: string;
  port: number; // default is 22
  username: string;
  password?: { '.psv': '@PB_SECRET'; secret?: string };
  private_key?: { '.psv': '@PB_SECRET'; secret?: string };
  location?: string;
  filename?: string;
  payload?: Record<string, any>;
}
// MS365 specific
interface IRawMS365Connector {
  id?: string;
  name: string;
  type: 'ms365';
  tenant_id?: string;
  tenant_name?: string;
  access_token?: string;
  inflow?: any;
}
// Brio specific
interface IRawBrioConnector {
  id?: string;
  name: string;
  type: 'portimabrio';
  pi2_key?: { '.psv': '@PB_SECRET'; secret?: string };

  // pi2_key: string;
  office_id: string;
  sub_office_id: string;
  environment: 'production' | 'acceptance';
}

// Combined type for all possible connectors
export type IRawConnector = IRawSFTPConnector | IRawHTTPConnector | IRawMS365Connector | IRawBrioConnector;
export interface IClientBaseConnector {
  id: string;
  name: string;
}

export interface IClientConnectorHTTP extends IClientBaseConnector {
  type: 'http';
  baseUrl: string;
  params?: WebhookRow[];
  headers?: WebhookRow[];
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  auth?: {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    url?: string;
    active: boolean;
    params?: WebhookRow[];
    payload?: WebhookRow[];
    headers?: WebhookRow[];
    tokenKey?: string;
  };
}

export interface IClientConnectorBrio extends IClientBaseConnector {
  type: 'portimabrio';
  pi2Key: string;
  officeId: string;
  subOfficeId: string;
  environment: 'production' | 'acceptance';
}

export interface IClientConnectorSFTP extends IClientBaseConnector {
  type: 'sftp';
  host: string;
  path: string;
  port: number;
  privateKey?: string;
  username: string;
  password: string;
  payload: WebhookRow[];
}
export interface IClientConnectorMS365 extends IClientBaseConnector {
  type: 'ms365';
  tenantId?: string;
  tenantName?: string;
  accessToken?: string;
  inflow?: { userMapping: MS365InflowUserMappingItem[] };
}
export interface MS365InflowUserMappingItem {
  id: string;
  onApproveFolder?: string;
  onBounceFolder?: string;
  onDeleteFolder?: string;
  folderMapping: {
    id: string;
    inboxId: string;
    pathId: string;
    tagTypeId: string;
    docTypeId: string;
    creationDate: Date;
  }[];
}
export type IClientConnector =
  | IClientConnectorHTTP
  | IClientConnectorSFTP
  | IClientConnectorMS365
  | IClientConnectorBrio;
export const createEmptyRow = (): WebhookRow => ({
  markedForDelete: false,
  skip: false,
  key: '',
  tempLocked: false,
  locked: false,
  value: { content: '', type: 'string' },
});
export const connectorRawToClient = (
  rawConnector: IRawConnector,
  serverSideValues: WebhookServerSideValue[]
): IClientConnector => {
  if (!rawConnector) return {} as any;
  if (rawConnector.type === 'http') {
    const httpItem: IClientConnectorHTTP = {
      id: rawConnector.id,
      name: rawConnector.name,
      baseUrl: rawConnector.base_url,

      type: 'http',
      params: createWebhookField(rawConnector, 'params', serverSideValues),
      headers: createWebhookField(rawConnector, 'headers', serverSideValues),
      method: rawConnector.method,
      auth: {
        active: false,
        method: 'POST',
        url: '',
        params: [createEmptyRow()],
        payload: [createEmptyRow()],
        headers: [createEmptyRow()],
      },
    };
    if (rawConnector.auth) {
      httpItem.auth = {
        method: rawConnector.auth.method,
        active: true,
        url: rawConnector.auth.endpoint,
        tokenKey: rawConnector.auth.access_token_key,
        payload: createWebhookField(rawConnector?.auth, 'payload', serverSideValues, true),
        params: createWebhookField(rawConnector?.auth, 'params', serverSideValues, true),
        headers: createWebhookField(rawConnector?.auth, 'headers', serverSideValues, true),
      };
    }
    return httpItem;
  } else if (rawConnector.type === 'sftp') {
    return {
      id: rawConnector.id,
      name: rawConnector.name,
      host: rawConnector.host,
      path: rawConnector.location ?? '',
      port: rawConnector.port ?? 22,
      privateKey: rawConnector.private_key ? 'existingValue' : '',
      username: rawConnector.username,
      password: rawConnector.password ? 'existingValue' : '',
      type: 'sftp',
    } as IClientConnectorSFTP;
  } else if (rawConnector.type === 'ms365') {
    const ms365Item: IClientConnectorMS365 = {
      id: rawConnector.id,
      name: rawConnector.name,
      type: 'ms365',
      tenantId: rawConnector.tenant_id,
      tenantName: rawConnector.tenant_name,
    };

    if (rawConnector.inflow) {
      ms365Item.inflow = {
        userMapping: Object.entries(rawConnector.inflow.user_mapping).map(([key, value]) => {
          return {
            id: key,
            folderMapping: Object.entries(value['folder_mapping']).map(([key, value]) => {
              return {
                id: uuid4hex(),
                inboxId: value['inbox_id'],
                pathId: key,
                tagTypeId: value['tag_type_id'],
                docTypeId: value['doc_type_id'],
                creationDate: new Date(value['creation_date']),
              };
            }),
            onApproveFolder: value['on_approve_folder'],
            onBounceFolder: value['on_bounce_folder'],
            onDeleteFolder: value['on_delete_folder'],
          };
        }),
      };
    }
    return ms365Item;
  } else if (rawConnector.type === 'portimabrio') {
    const brioItem: IClientConnectorBrio = {
      id: rawConnector.id,
      name: rawConnector.name,
      type: 'portimabrio',
      pi2Key: rawConnector.pi2_key ? 'existingValue' : '',
      officeId: rawConnector.office_id,
      subOfficeId: rawConnector.sub_office_id,
      environment: rawConnector.environment,
    };
    return brioItem;
  }
};

export const connectorClientToRaw = (clientConnector: IClientConnector): IRawConnector => {
  if (clientConnector.type === 'http') {
    const httpItem: IRawHTTPConnector = {
      name: clientConnector.name,
      base_url: clientConnector.baseUrl,
      type: 'http',
      params: mapRowsToObj(clientConnector.params),
      headers: mapRowsToObj(clientConnector.headers),
      method: clientConnector.method,
    };
    if (clientConnector.auth && clientConnector.auth.active) {
      httpItem.auth = {
        method: clientConnector.auth.method,
        endpoint: clientConnector.auth.url,
        access_token_key: clientConnector.auth.tokenKey,
        params: mapRowsToObj(clientConnector.auth.params),
        payload: mapRowsToObj(clientConnector.auth.payload),
        headers: mapRowsToObj(clientConnector.auth.headers),
      };
    }
    return httpItem;
  } else if (clientConnector.type === 'sftp') {
    const sftpItem: IRawSFTPConnector = {
      name: clientConnector.name,
      host: clientConnector.host,
      location: clientConnector.path,
      port: clientConnector.port,
      username: clientConnector.username,
      type: 'sftp',
    };
    if (clientConnector.password) {
      sftpItem.password = {
        '.psv': '@PB_SECRET',
      };
      if (clientConnector.password !== 'existingValue') {
        sftpItem.password.secret = clientConnector.password;
      }
    }
    if (clientConnector.privateKey) {
      sftpItem.private_key = {
        '.psv': '@PB_SECRET',
      };
      if (clientConnector.privateKey !== 'existingValue') {
        sftpItem.private_key.secret = clientConnector.privateKey;
      }
    }

    return sftpItem;
  } else if (clientConnector.type === 'ms365') {
    try {
      const ms365Item: IRawMS365Connector = {
        name: clientConnector.name,
        type: 'ms365',
      };

      if (clientConnector.accessToken) {
        ms365Item.access_token = clientConnector.accessToken;
      }
      if (clientConnector.inflow) {
        ms365Item.inflow = {
          user_mapping: clientConnector.inflow.userMapping.reduce((acc, item) => {
            acc[item.id] = {
              on_approve_folder: item.onApproveFolder,
              on_bounce_folder: item.onBounceFolder,
              on_delete_folder: item.onDeleteFolder,
              folder_mapping: item.folderMapping.reduce((acc, folder) => {
                acc[folder.pathId] = {
                  inbox_id: folder.inboxId,
                  doc_type_id: folder.docTypeId,
                  tag_type_id: folder.tagTypeId,
                  creation_date: folder.creationDate ? folder.creationDate.toISOString() : null,
                };
                return acc;
              }, {}),
            };
            return acc;
          }, {}),
        };
      }
      return ms365Item;
    } catch (e) {
      console.log(e);
    }
  } else if (clientConnector.type === 'portimabrio') {
    const brioItem: IRawBrioConnector = {
      environment: clientConnector.environment,
      office_id: clientConnector.officeId,
      sub_office_id: clientConnector.subOfficeId,

      name: clientConnector.name,
      type: 'portimabrio',
    };

    if (clientConnector.pi2Key) {
      brioItem.pi2_key = {
        '.psv': '@PB_SECRET',
      };
      if (clientConnector.pi2Key !== 'existingValue') {
        brioItem.pi2_key.secret = clientConnector.pi2Key;
      }
    }

    return brioItem;
  }
};
