import { useEffect, useState } from 'react';
import './shared/styles/base.scss';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { getAuth } from 'firebase/auth';
import { IntercomProvider } from 'react-use-intercom';
import NotificationBar from './components/shared/notification-bar/NotificationBar.tsx';
import SuspenseLoader from './components/shared/suspense-loader/SuspenseLoader';
import { ReactComponent as PaperboxLogo } from './shared/assets/svg/paperbox-logo.svg';
import { getCurrentEnvCode } from './shared/helpers/helpers';
import { IntercomBootProvider } from './shared/hooks/intercomContext.tsx';
import AppRouter from './shared/router/Router';
import { firebaseApp } from './shared/store/setup/firebase-setup';
import { useDispatch, useSelector } from './shared/store/store';
import { getTenantLoginInfo } from './shared/store/tenantSlice';
import 'react-loading-skeleton/dist/skeleton.css';
import s from './shared/styles/component/auth/auth.module.scss';
import 'rsuite/dist/rsuite-no-reset.min.css';

function App() {
  const dispatch = useDispatch();
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const isTenantInvalid = useSelector((state) => state.tenant.isTenantInvalid);
  const [isRouterMode, setIsRouterMode] = useState(false);

  useEffect(() => {
    let [path] = window.location.hostname.split('.');
    if (path === getCurrentEnvCode()) {
      setIsRouterMode(true);
    } else {
      if (path === 'localhost') {
        path = import.meta.env.VITE_TEST
          ? 'tst-testing'
          : import.meta.env.VITE_PAPERBOX_ENVIRONMENT === 'develop'
          ? 'app'
          : 'app';
      }
      getTenantLoginInfo(path, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    if (tenantId) {
      getAuth(firebaseApp).tenantId = tenantId;
    }
  }, [dispatch, tenantId]);

  if (isRouterMode) {
    return (
      <div style={{ overflow: 'clip' }} className="App" id={'routerMode'}>
        <AppRouter redirectMode={true} />
      </div>
    );
  }
  if (isTenantInvalid) {
    return (
      <div className={s.container}>
        <div className={clsx(s.card, s.card__center)}>
          <PaperboxLogo className={s.logo} />
          <h2 style={{ color: '#969696' }}>
            This tenant does not exist, please check if the URL you have entered is correct.
          </h2>
        </div>
      </div>
    );
  }
  if (!tenantId) {
    return <SuspenseLoader name={'app-loader'} fullPage />;
  }

  return (
    <div style={{ overflow: 'clip' }} className="App" id={'fullReturn'}>
      <IntercomProvider appId={'cscgqx37'}>
        <IntercomBootProvider>
          <NotificationBar />
          <AppRouter />
        </IntercomBootProvider>
      </IntercomProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
