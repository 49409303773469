export interface IClientBounceField {
  id: string;
  name: string;
  providerId?: string;
  isSensitive?: boolean;
  isArchived?: boolean;
  type?: ActionTypeType;
  options?: IClientBounceFieldOption[];
}
export type ActionTypeType = 'boolean' | 'text' | 'choice' | 'multi-choice';

export interface IClientBounceFieldOption {
  id: string;
  name: string;
  providerId?: string;
  isArchived?: boolean;
}

export interface IRawBounceField {
  id?: string;
  provider_id?: string;
  name: string;
  is_sensitive?: boolean;
  is_archived?: boolean;
  type?: ActionTypeType;
  options?: Record<string, { name: string; provider_id?: string; is_archived?: boolean }>;
}

export const bounceFieldRawToClient = (raw: IRawBounceField): IClientBounceField => {
  const item: IClientBounceField = {
    id: raw.id,
    providerId: raw.provider_id,
    name: raw.name,
    isSensitive: raw.is_sensitive,
    isArchived: raw.is_archived,
    type: raw.type,
    options: [],
  };
  if (raw.options) {
    const options = Object.entries(raw.options)
      .map(([key, value]) => {
        return {
          id: key,
          name: value.name,
          providerId: value.provider_id,
          isArchived: value.is_archived,
        };
      })
      .filter((option) => !option.isArchived)
      .sort((a, b) => a.name.localeCompare(b.name));
    item.options = options;
  }
  return item;
};
export const bounceFieldClientToRaw = (item: IClientBounceField): IRawBounceField => {
  const raw: IRawBounceField = {
    provider_id: item.providerId,
    name: item.name,
    is_sensitive: item.isSensitive ?? false,
    is_archived: item.isArchived,
    type: item.type,
  };
  if (item.options) {
    raw.options = {};
    item.options.forEach((option) => {
      raw.options[option.id] = {
        name: option.name,
        provider_id: option.providerId,
      };
    });
  }
  return raw;
};
