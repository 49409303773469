import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import s from './tooltip.module.scss';

interface TooltipProps {
  content: string | React.ReactNode;
  lightTheme?: boolean;
  children: React.ReactElement;
  position?: 'top' | 'right' | 'bottom' | 'left' | 'pointer';
  alignment?: 'start' | 'center' | 'end';
  delay?: number;
  tooltipStyle?: React.CSSProperties;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  position = 'right',
  alignment = 'center',
  delay = 100,
  tooltipStyle = {},
  lightTheme = true,
}) => {
  const [show, setShow] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const parentRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [transform, setTransform] = useState('');

  const updateTooltipPosition = useCallback(
    (event?: MouseEvent) => {
      if (!parentRef.current || !tooltipRef.current) return;

      const rect = parentRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect(); // Get the actual tooltip dimensions

      let top: number, left: number;
      let translateX = '0%',
        translateY = '0%';

      if (position === 'pointer' && event) {
        top = event.clientY;
        left = event.clientX;
        translateY = 'calc(-100% - 10px)';
        translateX = 'calc(-10px)';
      } else {
        switch (position) {
          case 'top':
            top = rect.top - tooltipRect.height - 10;
            left = rect.left + rect.width / 2 - tooltipRect.width / 2;
            break;
          case 'bottom':
            top = rect.bottom + 10;
            left = rect.left + rect.width / 2 - tooltipRect.width / 2;
            break;
          case 'left':
            top = rect.top + rect.height / 2 - tooltipRect.height / 2;
            left = rect.left - tooltipRect.width - 10;
            break;
          case 'right':
          default:
            top = rect.top + rect.height / 2 - tooltipRect.height / 2;
            left = rect.right + 10;
            break;
        }

        // Adjusting alignment
        switch (alignment) {
          case 'start':
            if (position === 'top' || position === 'bottom') {
              left = rect.left;
            } else if (position === 'left' || position === 'right') {
              top = rect.top;
            }
            break;
          case 'end':
            if (position === 'top' || position === 'bottom') {
              left = rect.right - tooltipRect.width;
            } else if (position === 'left' || position === 'right') {
              top = rect.bottom - tooltipRect.height;
            }
            break;
        }
      }

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (left + tooltipRect.width > viewportWidth) {
        left = viewportWidth - tooltipRect.width - 10;
      } else if (left < 0) {
        left = 10;
      }

      if (top + tooltipRect.height > viewportHeight) {
        top = viewportHeight - tooltipRect.height - 10;
      } else if (top < 0) {
        top = 10;
      }

      setTransform(`translate(${translateX}, ${translateY})`);
      setTooltipPosition({ top, left });
    },
    [position, alignment]
  );

  const showTooltip = (event?: React.MouseEvent) => {
    timeoutRef.current = setTimeout(() => {
      setShow(true);
      if (event && position === 'pointer') {
        updateTooltipPosition(event.nativeEvent);
      } else {
        updateTooltipPosition();
      }
    }, delay);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShow(false);
  };

  useEffect(() => {
    if (show && tooltipRef.current) {
      updateTooltipPosition();
    }
  }, [show, updateTooltipPosition]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMouseMove = (event: React.MouseEvent) => {
    if (position === 'pointer') {
      updateTooltipPosition(event.nativeEvent);
    }
  };

  return React.cloneElement(
    children,
    {
      ref: parentRef,
      onMouseEnter: showTooltip,
      onMouseLeave: hideTooltip,
      ...(position === 'pointer' && { onMouseMove: handleMouseMove }),
    },
    <>
      {children.props.children}
      {show &&
        createPortal(
          <div
            ref={tooltipRef} // Assign ref to the tooltip
            className={s.tooltip_wrapper}
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              transform,
              zIndex: 10000,
            }}
          >
            <div
              className={s.tooltip_tip}
              style={{
                background: lightTheme ? '#fff' : '#0F172A',
                color: lightTheme ? '#000' : '#fff',
                ...tooltipStyle,
              }}
            >
              {content}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
