import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from '../../../src/shared/styles/component/two-factor/two-factor.module.scss';
import { usePrevious } from '../../shared/helpers/helpers';
import { errorTextMap } from '../reset/PasswordReset';

interface Props {
  setValue: (string) => void;
  error?: string;
}

const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const inputs = 6;

export const TwoFactorCodeInput: React.FC<Props> = ({ setValue, error }) => {
  const [focusIndex, setFocusIndex] = useState(-1);
  const [codeArray, setCodeArray] = useState<string[]>(new Array(inputs));

  const [localError, setLocalError] = useState(error);

  useEffect(() => {
    if (error) {
      setLocalError(error);
      setCodeArray(new Array(inputs));
    }
  }, [error]);

  useEffect(() => {
    if (codeArray) {
      if (codeArray.join('').length === inputs) {
        setFocusIndex(-1);
      }
      setValue(codeArray.join(''));
    }
  }, [codeArray, setValue]);

  const focusNextInput = () => {
    if (focusIndex < inputs - 1 && focusIndex >= 0) {
      setFocusIndex(focusIndex + 1);
    }
  };
  const handleInput = (value: string) => {
    const tempArr = [...codeArray];
    tempArr[focusIndex] = value;
    setCodeArray(tempArr);
    if (value !== undefined) focusNextInput();
  };
  const handleOnKeyDown = (e) => {
    switch (e.keyCode) {
      case BACKSPACE:
        focusPrevInput();
        e.preventDefault();
        break;
      case DELETE:
        focusNextInput();
        e.preventDefault();
        break;
      case LEFT_ARROW:
        focusPrevInput();
        e.preventDefault();
        break;
      case RIGHT_ARROW:
        focusNextInput();
        e.preventDefault();
        break;
    }
  };

  const focusPrevInput = () => {
    if (focusIndex <= inputs && focusIndex > 0) {
      setFocusIndex(focusIndex - 1);
    }
  };
  return (
    <div className={s.code_container}>
      <div className={s.code_inputs}>
        {Array.from(new Array(inputs), (_, index) => {
          return (
            <TwoFactorInputField
              key={index}
              setFocus={() => setFocusIndex(index)}
              onChange={handleInput}
              onKeyDown={handleOnKeyDown}
              focus={index === focusIndex}
              onBlur={() => null}
              error={localError}
              setError={setLocalError}
            />
          );
        })}
      </div>
      {error && (
        <span className={s.error}>{errorTextMap[error] ?? 'Wrong code, please try again.'}</span>
      )}
    </div>
  );
};

interface ChildProps {
  focus: boolean;
  onChange: (value: string) => void;
  onBlur: () => void;
  setFocus: () => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  error: string;
  setError: (error: string) => void;
}
const TwoFactorInputField: React.FC<ChildProps> = ({
  focus,
  onChange,
  onKeyDown,
  setFocus,
  onBlur,
  error,
  setError,
}) => {
  const prevFocusState = usePrevious(focus);
  const inputRef = useRef(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (error) {
      setValue('');
    }
  }, [error]);

  const isInputValueValid = (value) => {
    const isTypeValid = !isNaN(parseInt(value, 10));

    return isTypeValid && value.trim().length === 1;
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === BACKSPACE || e.keyCode === DELETE) {
      setValue('');
      onChange(undefined);
      e.preventDefault();
    }

    onKeyDown(e);
  };

  const handleChange = (e) => {
    if (isInputValueValid(e.target.value)) {
      setValue(e.target.value);
      onChange(e.target.value);
    }
  };
  useEffect(() => {
    if (prevFocusState !== focus && inputRef && focus) {
      inputRef.current.focus();
    }
    if (prevFocusState !== focus && inputRef && !focus) {
      inputRef.current.blur();
    }
  }, [focus, prevFocusState]);

  return (
    <div>
      <input
        className={clsx(
          s.code_input,
          { [s.code_input__active]: focus },
          { [s.code_input__error]: error }
        )}
        autoComplete={'false'}
        ref={inputRef}
        value={value}
        type="tel"
        maxLength={1}
        required
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={setFocus}
        onBlur={onBlur}
        onFocus={() => {
          if (error) setError(null);
        }}
      />
    </div>
  );
};
